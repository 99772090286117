//====================================================================================================
// Page Layout Elements
//====================================================================================================
.content > section {
  min-height: 1px;
}

.page-fill {
  @include make-flex(1 0 auto);
}

.page-fill--grey {
  background-color: $color-grey--light;
}

// This class should exist at the root level of page content excluding navigation bars
.content {
  @include make-flexbox();
  @include make-flex-direction(column);
  min-height: 100%;
  overflow: hidden;
}

// Section
// Sections are simple dividing elements to divide a page into the different colour sections that exist
// on some designs
section:not(.section--grey) {
  display: block;

  .feature-card:not(.feature-card__background--theme):not(.feature-card__background--theme-50) {
    background-color: $color-grey--light;

    &__background--white {
      background-color: $color-white;
    }

    .view-more.active:after {
      background: linear-gradient(rgba($color-grey--light, 0) 90%, $color-grey--light);
    }
  }

  &.section--bley-gradient {
    background-image: linear-gradient(to bottom, #ffffff, $color-bley--light);
  }

  &.section--grey-gradient {
    background-image: linear-gradient(to bottom, $color-grey--light, $color-white 25%);
  }
}

.section--bley {
  background-color: $color-bley--light;
}

.section--white {
  background-color: $color-white !important;
}

.section--black {
  background-color: $color-black-1000 !important;
}

.section--certified {
  background-color: $color-certified-blue;
}

section:nth-of-type(even):not(.section--grey-darker):not(.section--theme-color--darker):not(.section--theme-color):not(.section--grey-gradient):not(.section--certified):not(.section--bley-gradient):not(.section--white), .section--grey {
  background-color: $color-grey--light;

  &.form--slant {
    background-color: $color-white;

    .background {
      background: -webkit-linear-gradient(rgba(224, 229, 232, 0.25), white); /* Safari 5.1-6.0 */
      background: -o-linear-gradient(rgba(224, 229, 232, 0.25), white); /* Opera 11.1-12.0 */
      background: -moz-linear-gradient(rgba(224, 229, 232, 0.25), white); /* Firefox 3.6-15 */
      background: linear-gradient(rgba(224, 229, 232, 0.25), white); /* Standard syntax */
    }
  }

  .view-more.active:after {
    background: linear-gradient(rgba($color-grey--light, 0) 90%, $color-grey--light);
  }

  .feature-card:not(.feature-card__background--theme) {
    background-color: $color-white;

    .card--map {
      background-color: $color-grey--light;
    }

    .view-more.active:after {
      background: linear-gradient(rgba(255, 255, 255, 0) 90%, $color-white);
    }
  }

  .icon--circle:not(.icon--circle-certified) {
    background-color: white;
  }
}

.section--grey-darker {
  background: $color-black-500;

  .feature-card {
    background-color: $color-white;

    .view-more.active:after {
      background: linear-gradient(rgba(255, 255, 255, 0) 90%, $color-white);
    }
  }
}

.section--theme-color--darker {
  background: $color-theme--darker;

  .feature-card {
    background-color: $color-white;

    .view-more.active:after {
      background: linear-gradient(rgba(255, 255, 255, 0) 90%, $color-white);
    }
  }

  h3, .text {
    color: $color-white;
  }
}

.section--theme-color {
  background: darken($color-theme, 2.5%);

  .feature-card {
    background-color: $color-white;

    .view-more.active:after {
      background: linear-gradient(rgba(255, 255, 255, 0) 90%, $color-white);
    }
  }
}

.section {
  &--slant {
    -webkit-clip-path: polygon(0 17%, 100% 0, 100% 83%, 0 100%);
    clip-path: polygon(0 17%, 100% 0, 100% 83%, 0 100%);

    & > .container {
      padding: 8rem 0;
    }
  }

  &--top-slant {
    -webkit-clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 100%);
  }
}

.section__heading {
  text-align: center;
}

section > .background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &--half {
    background-color: rgba(224, 229, 232, 0.25);
    height: 50%;
  }
}


.richtext {
  &:not(:first-child) {
    margin-top: 1rem;
  }

  .header__sub-title {
    color: $card-header-color;
  }

  // Add spacing to paragraph content
  ul, ol, p {
    &:not(:first-child) {
      margin: 1rem 0;
    }

    p {
      margin-left: -0.75rem;
    }
  }

  p {
    padding: 0;
  }

  ul {
    list-style-type: none;
    padding: 0 0 0 0.75rem;

    & > li {
      position: relative;

      p {
        margin: 0;
        display: inline-block;
      }

      margin: 0.5rem;
    }

    & > li:before {
      content: "\25A0";
      color: $color-theme;
      font-size: 16px;
      position: absolute;
      top: -2px;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  ol {
    list-style-type: decimal;
    padding: 0 0 0 0.75rem;

    & > li {
      margin: 0.25rem 0.5rem;
      padding: 0 0 0 0.5rem;
    }
  }

  a:not(.button) {
    @extend .link;
  }

  &.fineprint, &.fineprint--grey {
    a {
      font-size: 0.694rem;
      line-height: 1.45;
    }

    ul {
      & > li:before {
        top: -6px;
      }
    }
  }

  .highlight__disclaimer {
    @extend .fineprint--grey;
  }

  table {
    thead {
      tr {
        background-color: $color-black;
        color: $color-white;
      }
    }

    tr {
      background-color: $color-white;

      &:nth-child(2n) {
        background-color: $color-grey--light;
      }
    }

    th {
      padding: 0.5rem;
      text-align: left;
      @include make-f-body--small();
      color: unset;
    }

    td {
      padding: 0.5rem 1rem;
    }
  }

  .text-tiny {
    font-size: 0.75em;
  }

  .text-small {
    font-size: 0.875em;
  }

  .text-big {
    font-size: 1.125em;
  }

  .text-bigger {
    font-size: 1.375em;
  }
}


// Container
// General container which keeps the content centered on the page
.container {
  margin: auto;
  width: 100%;
  max-width: $screen-x-large;
}

//background-image container
.container__background-image {
  width: 100%;
  min-height: 508px;
  height: calc(100vh - 56px);
  background-color: $color-theme;
  background-size: cover;
  background-position: center center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color-theme--darker, 0.33);
  }

  .container--404 {
    position: relative;
    z-index: 3;
  }
}

@media (max-width: 1000px) {
  .container__background-image {
    height: calc(100vh - 80px);
  }
}

// Content Block
// When using a content block, it must contain at least 1 section within it and all sections must have at least 1 column
// inside that. We do this to achieve consistent spacing around the app
//
// Usage example:
// <div class="content-block">
// </div>
.content-block {
  padding-top: 2.5rem;

  &:last-child {
    padding-bottom: 5rem;
  }
}

.content-block__section {
  padding-top: 2.5rem;

  &::after {
    @include make-clearfix;
  }

  &--flex {
    @extend .content-block__section;
    @include make-flexbox();
    @include make-flex-wrap(wrap);
    @include make-flex-direction(row);
    margin: auto;
    width: 100%;
    max-width: $screen-x-large;
  }
}

// Text Block
// Text blocks are used to wrap any large bodies of text. Text blocks have their own spacings which are separate from
// content blocks which are affected by spacings from the column layout
.text-block:not(:first-of-type) {
  padding-top: 5rem;
}

.text-block__section:not(:first-of-type) {
  padding-top: 2.5rem;
}

.disclaimer {
  padding: 2.5rem 1rem;

  &--white {
    background-color: $color-white;
  }

  &--no-background {
    background-color: unset;
  }
}

.empty-state {
  background: $color-grey--light;
  text-align: center;
  border-radius: 4px;
  padding: 1.5rem 0;
}

//media queries
@media (max-width: $screen-medium) {

  .section--slant {
    -webkit-clip-path: polygon(0 6%, 100% 0, 100% 94%, 0 100%);
    clip-path: polygon(0 6%, 100% 0, 100% 94%, 0 100%);

    & > .container {
      padding: 4rem 0;
    }
  }

  //fixes padding and margins on iPhone X   (iOS 11.2+)
  @supports (margin: env(safe-area-inset-left)) {

    section > *:not(.carousel), .footer, #inventory_container, .container > .header__content, header + .subheader, .modal__footer, .modal__content {
      padding-left: env(safe-area-inset-left) !important;
      padding-right: env(safe-area-inset-right) !important;
    }
    .nav__header, .nav__item, .filters--modal-with-button > * .carousel__overlay {
      padding-left: calc(1.5rem + env(safe-area-inset-left)) !important;
      padding-right: calc(1.5rem + env(safe-area-inset-right)) !important;
    }

    .nav__item > svg, .filter__menu-item > svg {
      margin-left: env(safe-area-inset-left) !important;
      margin-right: env(safe-area-inset-right) !important;
    }

    .mobile-contact-bar {
      padding-bottom: calc(70px + env(safe-area-inset-bottom)) !important;

      &.slideOutDown {
        transform: translate3d(0, calc(70px + env(safe-area-inset-bottom)), 0) !important;
      }

      &.slideInUp {
        transform: translate3d(0, calc(0 + env(safe-area-inset-bottom)), 0) !important;
      }
    }

    .subheader.u-only-mobile {
      margin-bottom: env(safe-area-inset-bottom) !important;

      &.slideOutDown {
        transform: translate3d(0, calc(70px + env(safe-area-inset-bottom)), 0) !important;
        padding-bottom: env(safe-area-inset-bottom) !important;
      }

      &.slideInUp {
        transform: translate3d(0, env(safe-area-inset-bottom), 0) !important;
      }
    }

    .inventory__button-fixed {
      margin-bottom: calc(1rem + 1.5 * env(safe-area-inset-bottom)) !important;
    }

    .button--mobile {
      margin-bottom: env(safe-area-inset-bottom) !important;
    }

    .button[scrolldowntohide] {

      &.slideOutDown {
        transform: translate3d(0, 0, 0) !important;
      }

      &.slideInUp {
        margin-bottom: env(safe-area-inset-bottom) !important;
        transform: translate3d(0, -3rem, 0) !important;
      }
    }
  }
}

@media (max-width: $screen-small) {
  .content-block {
    padding-top: 1.25rem;

    &:last-child {
      padding-bottom: 2.5rem;
    }
  }

  .content-block__section {
    padding-top: 1.25rem;
  }
}