.tabs {
  margin: 1.25rem 0 0;
  padding: 0;
  width: 100%;

  .tab--content {
    margin: 0.75rem -1rem 0;
    position: relative;

    .tab {
      display: inline-block;
      margin: 0 1rem;
      padding-bottom: 0.75rem;
      color: $color-grey--dark;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        color: $color-theme;
      }
    }
  }

  &--label {
    color: $color-grey--darker;
    @include make-f-heading(6);
  }

  &--underline {
    border-bottom: 2px solid $color-theme;
    margin: 0 1rem;
    transition: width 0.33s cubic-bezier(0.3, 0, .2, 1), transform 0.33s cubic-bezier(0.3, 0, .2, 1);
    width: 0;
    will-change: width, transform;

    &.secondary {
      border-bottom-color: $color-button;
    }

    &.thick {
      border-bottom-width: 4px;
    }

    &.round{
      border-radius: 100px;
    }
  }
}

.tab__panel {
  display: none;

  &.active {
    display: block;
  }
}

[tab] {
  cursor: pointer;
}

[fade] {
  transition: opacity 0.2s cubic-bezier(0.3, 0, .2, 1);
  will-change: opacity;
  opacity: 0;
}

[tab-id], [tab-section] {
  &:not(.active) {
    display: none;
  }
}