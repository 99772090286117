// Navigation Common Styles ////////////////////////////////////////////////////////////////////////////////////////////
#nav-spacing {
  display: table;
  width: 100%;
}

.nav-spacing {
  height: $navigation-height;

  &-logos {
    height: $logos-bar-height;
  }

  &-contact {
    height: $contact-bar-height;
  }
}

.nav {
  user-select: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s;
}

.nav__border {
  position: relative;
  @if $nav-br-color != $nav-bg-color {
    border-bottom: 1px solid $nav-br-color;
  }
  z-index: 3;
}

.nav__dealership {
  position: absolute;
}

.nav__heading {
  display: block;
  vertical-align: middle;
  text-transform: capitalize;
  line-height: 56px;
}

.nav__contact {
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid $contact-nav-br-color;

  &--items {
    @include make-inline-flex();
    @include make-align-items(center);
    float: right;

    &:after {
      @include make-clearfix;
    }
  }

  &--icon {
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 20px;
    margin-right: 0.25rem;
    fill: $contact-nav-color;
    margin-left: -0.25rem;
  }

  &--label {
    color: $contact-nav-color;

    &.label--theme {
      color: $color-theme;
    }

    & + & {
      display: inline-block;
      padding-left: 0.25rem;
      width: calc(100% - 24px);
      margin-right: -5px;
    }
  }

  &--address {
    float: left;
    text-decoration: none;
  }

  &--contact {
    float: right;
    margin-left: 1rem;
    color: $contact-nav-color;
  }

  .nav__container > div > .nav__title .logo {
    height: 3rem;
  }
}

.nav__logos {
  padding-top: 0.5rem;
}

.nav__background {
  position: relative;
  background: $nav-bg-color;
  z-index: 1;

  &--theme {
    background-color: $contact-nav-bg-color;
  }
}

.nav__container {
  display: table;
  padding: 0 2rem;
  table-layout: fixed;
  width: 100%;
}

.nav__contact > .nav__container {
  .nav__title {
    padding: 1rem 0 0 0;
  }
}

.nav__tint {
  opacity: 0;
  transform: translateZ(0);
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s linear 0.2s;
}

.nav__tint.active {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  transition-delay: 0s;
}

.logo-title {
  margin-bottom: 0.5rem;
}

.nav__title {
  display: table-cell;
  vertical-align: middle;

  .logo {
    display: block;
    transition: transform 0.2s ease;
    height: 48px;
    cursor: pointer;

    &--theme {
      @if $nav-logo != "" {
        background-image: url($nav-logo);
        background-repeat: no-repeat;
        background-size: contain;
      }
      width: 500px;
    }

    .u-stack--right & {
      float: right;
    }

    // Shrink position for the smaller logos | 40/56 = 0.7142857143 | (1 - 0.7142857143) / 2 = 14.285714285
    &.shrink-left {
      transform: translate(-14.285714285%, 8px) scale(0.7142857143);
    }

    &.shrink-right {
      transform: translate(14.285714285%, 8px) scale(0.7142857143);
    }

    &-dealership {
      height: 56px;
    }

    &-multi {
      &.shrink-left {
        transform: translate(0, 0) scale(1);
      }
    }

    .no-link {
      cursor: default;
    }

  }

  &.logo-multi {
    transition: transform 0.2s ease;

    // Shrink position for the smaller logos | 40/56 = 0.7142857143 | (1 - 0.7142857143) / 2 = 14.285714285
    &.shrink-left {
      transform: translate(-14.285714285%, 8px) scale(0.7142857143);
    }
  }
}

.nav__item {
  user-select: none;
  color: $nav-color;
  cursor: pointer;
  text-decoration: none;

  svg {
    transition: transform 0.3s, fill 0.15s;
    display: inline-block;
    fill: $nav-color;
    height: 10px;
    vertical-align: middle;
    width: 10px;
    margin-top: -2px;
  }

  &--button {
    border: 1px solid rgba($nav-color, 0.5);
    margin-left: 0.5rem;

    &:hover {
      border: 1px solid transparent;
    }
  }

  &.active {
    background: $nav-hover-bg-color;
    color: $nav-hover-color;

    svg {
      fill: $nav-hover-color;
      display: inherit;
      transform: rotateX(180deg) translateZ(0);
      transition-timing-function: ease-in-out;
    }
  }
}

.nav__sub-item {
  display: block;
  position: relative;
}

.nav__list {
  text-align: left;
}

.nav__list-title {
  @include make-f-heading(6);
  color: $sub-nav-title-color;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.nav__list-item {
  @include make-f-label($sub-nav-desc-color, $fs-s);
  border-radius: 4px;
  display: block;
  padding: 0.5rem;
  text-decoration: none;

  &:hover {
    background-color: $sub-nav-hover-bg-color;
    color: $sub-nav-hover-color;
  }
}


// button emphasis
.nav__item--button-emphasis {
  @media (min-width: $screen-medium) {
    border: 1px solid rgba($color-button, 0.5);
    float: right;
    margin-left: 0.5rem;
    background-color: $color-button;
    color: $color-button-text;

    &:hover {
      border: 1px solid rgba($color-button, 0.5);
      background-color: $nav-hover-color !important;
      color: $nav-hover-bg-color !important;

      svg {
        fill: $nav-hover-bg-color!important;
      }
    }

    svg {
      fill: $nav-hover-color;
    }

  }

  @media (max-width: $screen-medium) {
    color: $nav-hover-color !important;
    background-color: $nav-hover-bg-color;
  }
}

// Fixes bug on safari where the arrow svg disappears when the nav is active
div.nav__container:not(:root:root) {
  will-change: transform;
}

// Desktop Navigation //////////////////////////////////////////////////////////////////////////////////////////////////
.nav--desktop {
  display: table;
  width: 100%;

  .nav__title {
    height: $navigation-height;
  }

  .nav__items {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    z-index: 9;
    width: 100%;
    padding: 0.75rem 0;
  }

  .nav__item {
    border-radius: 2px;
    display: inline-block;
    @include make-font($ff-label);
    font-size: $fs-s;
    padding: 0.25rem 0.6rem;
    text-align: center;
    vertical-align: middle;
    transition: background-color, color;
    transition-duration: 0.15s;

    &:hover {
      background: $nav-hover-bg-color;
      color: $nav-hover-color;
      cursor: pointer;

      svg {
        fill: $nav-hover-color;
      }
    }
  }

  .nav__item.active .nav__sub-items {
    opacity: 1;
    transform: translateZ(0);
    pointer-events: all;
    visibility: visible;
    transition-delay: 0s;
  }

  .nav__sub-items {
    background: $sub-nav-bg-color;
    border-top: 1px solid $nav-active-br-color;
    cursor: default;
    @include make-flex-direction(column);
    left: 0;
    padding: 1.5rem 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0s linear 0.2s;
  }

  .nav__sub-items .nav__container {
    @include make-flexbox;
    @include make-flex-wrap(wrap);
    height: 100%;
  }

  .nav__sub-item {
    border-radius: 4px;
    cursor: pointer;
    float: left;
    padding: 1rem;
    text-align: left;
    text-decoration: none;
    transition: background-color 0.15s;

    // -1px for an IE spacing bug
    &.col--4 {
      width: calc(((100% / 12) * 4) - 1px);
    }

    img + .nav__sub-item-title {
      margin-top: .5rem;
      text-align: center;

      & + .nav__sub-item-description{
        text-align: center;
      }
    }

    // Child Elements
    .nav__sub-item-title {
      color: $sub-nav-title-color;
      @include make-font($ff-heading);
      font-size: $fs-s;
      display: block;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.header-sub {
        text-transform: none;
        letter-spacing: normal;
      }
    }

    .nav__sub-item-description {
      @include make-f-body--small($sub-nav-desc-color);
      display: block;
      letter-spacing: 0;
      text-transform: none;
    }

    // States
    &:hover {
      background: $sub-nav-hover-bg-color;
    }
  }
}

.nav--desktop.nav--dealership {
  .nav__container {
    min-height: 36px;
    padding: 0 2rem 0 1.5rem;

    .nav__items {
      text-align: left;
    }
  }
}

// Mobile Navigation ///////////////////////////////////////////////////////////////////////////////////////////////////
.nav--mobile {
  display: none;

  .nav__container {
    padding: 0;
  }

  .nav__header {
    @if $nav-mobile-bg-color != "" {
      background-color: $nav-mobile-bg-color;
    } @else {
      background-color: $nav-bg-color;
    }
    border-bottom: none;
    padding: 0 1.5rem;
    position: relative;
    width: 100%;
    z-index: 1;

    &--theme {
      background-color: $contact-nav-bg-color;
    }
  }

  .nav__title {
    height: 64px;
  }

  .nav__toggle {
    @include make-f-heading(6);
    user-select: none;
    background: $nav-toggle-bg-color;
    border-radius: 2px;
    color: $nav-toggle-color;
    cursor: pointer;
    line-height: 32px;
    padding: 0 1rem;
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);

    // Child Elements
    svg {
      display: none;
      fill: $nav-toggle-color;
      float: left;
      height: 32px;
      transform: rotateZ(45deg);
      width: 24px;
    }

    // States
    &.active {
      padding: 0 0.5rem;

      *:first-child {
        display: none;
      }

      *:last-child {
        display: block;
      }
    }
  }

  .nav__toggle-dealership {
    @include make-f-heading(5);
    user-select: none;
    background: $nav-toggle-bg-color;
    color: $nav-mobile-color;
    cursor: pointer;
    line-height: 52px;
    height: 52px;
    position: relative;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    padding: 0 1.5rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-top: 1px solid $sub-nav-br-color;
    right: 0;
    top: 0;
    transform: translateY(0);

    // Child Elements
    svg {
      display: block;
      fill: $nav-mobile-color;
      height: 100%;
      width: 24px;
      float: right;
      transition: transform 0.3s;
      transform: rotateZ(0deg);
      position: relative;
      left: 2px;

    }

    // States
    &.active {
      padding: 0 1.5rem;

      svg {
        transform: rotateZ(135deg);
      }

      *:first-child, *:last-child {
        display: initial;
      }
    }
  }

  .nav__items {
    transition: height 0.3s;
    background: $sub-nav-bg-color;
    display: none;
    height: 0;
    left: 0;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 64px;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;

    &-dealership {
      padding-top: 115px;
    }
  }

  .nav__item {
    @include make-f-heading(5);
    color: $sub-nav-title-color;
    clear: both;
    display: block;
    height: auto;
    margin: 0;
    padding: 1.5rem;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    -webkit-transform: translate3d(0, 0, 0);
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $sub-nav-br-color;
    }

    &:last-child {
      padding-bottom: 10rem;
    }

    // Child Elements
    svg {
      fill: $sub-nav-title-color;
      margin: auto;
      position: absolute;
      top: calc(1.5rem + 5px);
      right: 1.5rem;
    }

    // States
    &.active {
      background: $color-white;

      .nav__item-title {
        color: $sub-nav-mobile-active-title-color;
      }

      svg {
        fill: $sub-nav-mobile-active-title-color;
      }
    }
  }

  .nav__item.active .nav__sub-items {
    visibility: visible;
  }

  .nav__list-item {

    &:hover {
      background-color: transparent;
    }
  }

  .nav__sub-items {
    transition: height 0.3s;
    display: none;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .nav__sub-items .nav__container {
    display: block;
    flex-wrap: nowrap;
    padding: 1rem 0 0;
  }

  .nav__sub-item {
    color: $sub-nav-title-color;
    cursor: pointer;
    float: left;
    padding: 1rem 0;
    text-decoration: none;
    width: 100%;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-black-50;
    }

    // Child Elements
    .nav__sub-item-title {
      @include make-f-label($sub-nav-item-title-color);
      letter-spacing: 0;
      text-transform: none;
    }

    .nav__sub-item-description {
      @include make-f-body--regular($color-grey--darker);
      letter-spacing: 0;
      text-transform: none;
    }

    // States
    &:hover {
      .nav__sub-item-title {
        color: $sub-nav-mobile-hover-title-color;
      }
    }
  }
}

// Inventory Navigation ////////////////////////////////////////////////////////////////////////////////////////////////
.nav__list {
  .col--10 & {
    & > *:not(:first-child) {
      float: left;
      width: calc(100% / 5);
    }
  }

  .col--12 & {
    & > *:not(:first-child) {
      float: left;
      width: calc(100% / 4);
    }
  }
}

.nav__brand-item {
  padding: 0.5rem;
  position: relative;
  transition: background-color 0.15s;

  &:hover {
    background: $sub-nav-hover-bg-color;
    border-radius: 2px;
    cursor: pointer;
  }
}

.nav__brand-item > a {
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.nav__brand-label {
  @include make-f-label($sub-nav-desc-color, $fs-s);
  transition: color 0.15s;
}

.nav__sub-item:hover .nav__brand-label,
.nav__brand-item:hover .nav__brand-label {
  color: $sub-nav-hover-color;
}

// Sub-Nav Styles //////////////////////////////////////////////////////////////////////////////////////////////////////

.sub-nav-links {
  min-height: 0;
}

.sub-nav {
  height: 3rem;
  width: 100%;
  border-bottom: solid 1px $color-black-50;
  background-color: $color-white;
  z-index: 2;
  transform: translate3d(0, 0, 0);

  &.transition {
    transition: transform 0.5s;
  }
}

.sub-nav__container {
  padding: 0 1rem;

  span {
    vertical-align: middle;
    line-height: 3rem;
    padding: 0 1rem;
    cursor: pointer;
    display: inline-block;
  }
}

// Media Queries
// Small Screens and Mobile Devices
@media (max-width: $screen-medium) {
  .nav__list-item {
    color: $color-theme;
    cursor: pointer;
    float: left;
    padding: 1rem 0;
    text-decoration: none;
    width: 100%;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-black-50;
    }

    // Child Elements
    .nav__sub-item-title {
      @include make-f-label($sub-nav-mobile-active-title-color);
      letter-spacing: 0;
      text-transform: none;
    }

    .nav__sub-item-description {
      @include make-f-body--regular($color-grey--darker);
      letter-spacing: 0;
      text-transform: none;
    }

    // States
    &:hover {
      *:first-child {
        color: $color-blue;
      }
    }
  }

  .nav-spacing-contact {
    height: 0;
  }

  .nav__dealership {
    position: fixed;
  }

  .nav-spacing {
    height: 64px;

    &--affiliate {
      height: 116px;
    }
  }

  .nav--desktop {
    display: none;
  }

  .nav--mobile {
    display: block;
  }

  .nav__tint, .nav__tint.active {
    display: none;
  }

  .nav__item {
    svg {
      width: 20px;
      height: 20px;
      top: 1.5rem !important;
    }
  }

  .sub-nav__container {
    text-align: center;

    * {
      padding: 0 8px;
    }
  }

  .nav__title {
    svg {
      padding-right: 1rem;
    }

    .logo {
      height: 40px;

      &-small {
        height: 30px;
        align-self: center;
      }

      &--theme {
        width: 150px;
        @if $nav-logo-mobile != '' {
          background-image: url($nav-logo-mobile);
        }
      }
    }
  }

  .logo-title {
    margin-bottom: 0;
  }

  .nav__heading {
    font-size: 1.5rem;
    line-height: 64px;
  }

  .nav__sub-item-image-container {
    @include make-flexbox(!important);
    @include make-align-items(center);
    .nav__sub-item-image {
      width: 40%;
      padding-right: 0.5rem;
    }
  }
}

@media (max-width: $screen-small) {
  .nav__title .logo-dealership {
    max-width: 130px;
  }

  .sub-nav__container {
    span {
      padding: 0 0.4rem;
    }
  }
}