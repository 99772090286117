// Form
//====================================================================================================
.form {
  width: 100%;
  position: relative;
  text-align: left;

  &--grey {
    background-color: $color-grey--light;
  }

  &--float {
    background-color: $color-white;
    border-radius: 4px;
    @include make-overflow;
  }

  &--offers {
    position: absolute;
    right: 2.5rem;
    top: 17%;
    width: calc((100% / 12) * 3);
  }

  &--pending,
  &--success,
  &--failed {
    .form__loading-states {
      @include make-flexbox();
      @include make-align-items(center);
      @include make-flex(1);

      @include make-justify-content(center);
      @include make-flex-direction(column);
    }
  }

  &--pending {
    .form__pending {
      display: block;
    }
  }

  &--success {
    .form__success {
      display: block;
    }

    .form__loading-states {
      background-color: #FFFFFF;
      overflow: hidden;
    }

    form {
      visibility: hidden;
    }
  }

  &--failed {
    .form__failed {
      display: block;
    }

    .form__loading-states {
      background-color: #FFFFFF;
      overflow: hidden;
    }

    form {
      visibility: hidden;
    }
  }

  .fieldset {
    border: none;
    padding: 0.5rem 0 0.5rem;
    margin: 0;

    legend {
      @include make-f-heading(4);
      text-transform: uppercase;
      margin: 1rem 0 0.5rem;
      padding: 0;
      @include make-clearfix;
      color: $header-color;
    }

    label:not(.label__radio) {
      @include make-font($ff-label);
      margin: 0.5rem 0;

      &.label--warning {
        @extend .label--warning;
        margin: 0.5rem 0 0;
      }

      &.label--hidden {
        margin: 0;
        height: 0;
        opacity: 0;
      }
    }
  }

  &--slant {
    position: relative;
    padding-top: 5rem;
    background-color: $color-grey--light;

    .container {
      position: relative;
      z-index: 1;
    }

    .background {
      background: -webkit-linear-gradient(white, rgba(224, 229, 232, 0.25)); /* Safari 5.1-6.0 */
      background: -o-linear-gradient(white, rgba(224, 229, 232, 0.25)); /* Opera 11.1-12.0 */
      background: -moz-linear-gradient(white, rgba(224, 229, 232, 0.25)); /* Firefox 3.6-15 */
      background: linear-gradient(white, rgba(224, 229, 232, 0.25)); /* Standard syntax */
      display: block;
      height: 100%;
      position: absolute;
      top: 40%;
      transform-origin: 0 100%;
      transform: rotate(-5deg) skew(-5deg);
      width: 105%;
      z-index: 0;

      &--top {
        top: 6vw;
      }
    }
  }

  &--theme {
    background-color: $color-theme;

    input {
      border: none;
    }

    .form__submit {
      padding: 0.5rem 0 1.5rem;
    }
  }
}

.form--shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.modal .form {
  position: inherit;
}

.form__divider {
  border-color: $color-black-50;
  margin: 1.5rem 0 0;
}

.form__submit {
  padding: 1.5rem 0;
}

.form__header {
  background-color: $form-header-color;
  border-radius: 2px 2px 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  padding: 1.5rem;

  &--theme-color {
    background-color: $color-theme;
  }

  * {
    color: $color-white;
  }
}

.form__section {
  padding: 1.5rem 0;

  &--no-padding-top {
    padding-top: 0;
  }
}

.form__row {
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 8px;
  }
}

.form__textarea-expand {
  @include make-flexbox();
  margin-top: 1rem;

  .form__title {
    line-height: 24px;
    vertical-align: middle;
    padding: 0;
  }
}

.form__signature {
  background-color: $color-white;
  padding: 1rem;
  margin: 0 auto 2rem;
  max-width: 800px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.signature__container {
  padding: 2rem;
}

.signature__area {
  margin-bottom: 2rem;
  border-radius: 2px;
  background-color: #ffffff;
}

.star-rating {
  @include make-flexbox;
  @include make-flex-wrap;
  @include make-align-items(center);

  &__container {
    margin-right: 0.5rem;
    width: 50%;

    .rating__input {
      display: none;
    }

    .rating {
      display: block;
      float: right;
      padding-top: 0;
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
      }
    }

    &:not(:hover) input.invalid + label {
      .icon-star {
        fill: rgba(245, 78, 3, 0.1);
        stroke: #f54e03;
        stroke-width: 2px;
      }
    }

    .icon-star {
      stroke: none;
      fill: $color-grey;
      pointer-events: none;
    }

    input:hover + label {
      .icon-star {
        fill: $color-gold;
        stroke: none;
        stroke-width: 2px;
      }
    }

    > label:hover ~ label,
    &:not(:hover) > input:checked ~ label {
      .icon-star {
        stroke: none;
        fill: $color-gold;
      }
    }

    label {
      display: inline-block;
      padding: 0;
      width: 20%;
      // Adding fixed height here prevents IE from breaking
      height: 44px;

      .icon-star {
        height: 100%;
        width: 100%;
      }
    }
  }

  .rating__label {
    @include make-f-label($color-grey--dark, $fs-s);
    background: $color-grey--light;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    display: inline-block;
  }
}

//====================================================================================================
// Form States
//====================================================================================================

.form__loading-states {
  z-index: 5;
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  background: rgba(255, 255, 255, 0.6);

  svg.spinner {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 48px;
    height: 48px;
    fill: $color-theme;
  }

}

.modal .form__loading-states {
  padding-top: 75px;
}

.form__header + .form__loading-states {
  height: calc(100% - 75px);
}

.form__pending {
  display: none;
  border-radius: 2px;
}

.form__success,
.form__failed {
  padding: 1rem;
  display: none;
  text-align: center;
  background-color: #FFF;
  border-radius: 2px;
  width: 100%;

  .filter__title {
    @include make-font($ff-label);
    font-size: 1.25rem;
    color: $color-theme;
  }

  .icon-check--circle {
    margin: 0 auto;
  }
}

//====================================================================================================
// Form Variants
//====================================================================================================
.form--large {
  .form__header {
    padding: 1.5rem 2.5rem;
  }

  .form__content {
    padding: 1rem;
  }

  .form__success,
  .form__failed {
    padding: 2.5rem;
  }
}

//makes the success and error state icons smaller to fit the small form size
.form--offers {
  .icon-check--circle {
    width: 80px;
    height: 80px;
    padding: 1.5rem;
  }

  .pat-title-text {
    padding-top: 1.5rem;
  }
}

//====================================================================================================
// Form Validation
//====================================================================================================
form.submitted {
  input,
  select,
  textarea {
    &.invalid:not(.dirty) {
      background-color: rgba(245, 78, 3, 0.1);
      border: solid 1px #f54e03;

      & + .label__radio {
        background-color: rgba(245, 78, 3, 0.1);
        border: solid 1px #f54e03;
      }

      & + .uploader-label > .button {
        border: solid 1px #f54e03;
        background-color: rgba(245, 78, 3, 0.1);
      }
    }
  }
}

//====================================================================================================
// Media Queries
//====================================================================================================

@media (max-width: $screen-large) {
  .form--slant {
    padding-top: 3rem;

    .background {
      top: 5%;
      width: 100%;
      height: 50%;
    }
  }

}

@media (max-width: $screen-medium) {
  .form--slant {
    padding-top: 3rem;

    .background {
      top: 5%;
      width: 100%;
      height: 50%;

    }
  }

  .form--offers {
    position: relative;
    width: calc(100% - 1rem);
    margin-left: 3rem;
    top: initial;
    float: none;
  }

  .form {
    fieldset {
      > .star-rating {
        display: block;
        margin-bottom: 1rem;
      }
    }
  }

  .star-rating {
    display: block;

    &__container {
      width: 100%;
      max-width: 198px;
    }

    &__label {
      display: block;
    }
  }
}

@media (max-width: $screen-small) {
  .form--offers {
    width: 100%;
    margin: 0.5rem 0;
    right: initial;
    top: initial;
  }

  .form--large {
    .form__header {
      padding: 1.5rem;
    }

    .form__content {
      padding: 1.5rem;
    }

    .form__success,
    .form__failed {
      padding: 1.5rem;
    }

    .button {
      padding: 0;
    }
  }

  .form__radio {
    label {
      .filter__title {
        font-size: 0.875rem;
      }
    }
  }
}