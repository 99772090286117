.badge {
  @include make-inline-flex();
  @include make-align-items(center);
  @include make-f-label($color-white, $fs-s);
  background: rgba(128, 147, 159, 0.75);
  border-radius: 8px;
  padding: 0.25rem 0.6rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  height: 28px;

  &--green {
    background: rgba(126, 211, 33, 0.75);
  }

  &--gradient {
    background: linear-gradient(227deg, #004e72, #00263e);
  }

  &--certified {
    background: linear-gradient(256deg, rgba(#2196f3, 0.75), rgba(#045ef3, 0.75));
  }
}


.badge__container {
  @include make-flexbox();
  margin: 1rem 1rem 1rem 0;
  float: left;
}

.badge--theme {
  border-radius: 100%;
  display: inline-block;
  min-width: 40px;
  position: relative;
  background-image: linear-gradient(to bottom, lighten($color-theme, 10%), darken($color-theme, 15%));
  margin-right: 1rem;
  height: 40px;

  .icon {
    position: absolute;
    top: 50%;
    height: auto;
    left: 50%;
    vertical-align: middle;
    transform: translate(-50%, -50%);
    width: 75%;
    padding: 0.5rem;

    &--small {
      width: 60%;
      padding-bottom: 0.7rem;
    }

    &--large {
      width: 100%;
    }
  }

  .video {
    position: absolute;
    top: 50%;
    height: auto;
    left: 50%;
    vertical-align: middle;
    transform: translate(-50%, -50%);
    width: 75%;
    padding: 0;
    border-radius: 4px;
    background: transparent;
  }

  .text, .number {
    position: absolute;
    top: 50%;
    height: auto;
    left: 50%;
    vertical-align: middle;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  .number span {
    font-size: 1.25rem;
  }
}

@media (max-width: $screen-medium) {
  .badge--large {
    min-width: 88px;
    height: 88px;
  }
}