input {
  @include make-f-body--regular();
  height: 2.75rem;
  width: 100%;
  border-radius: 2px;
  background-color: $color-white;
  border: solid 1px $color-black-100;
  padding: 0 0.75rem;
  box-shadow: none;
  -webkit-appearance: none;

  &:focus {
    border: solid 1px $form-input-border-color;
  }
}

input[type="file"] {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.input-group {
  border: solid 1px $color-black-100;
  position: relative;
  display: table;
  border-collapse: separate;
  border-radius: 4px 0 0 4px;
  overflow: hidden;

  &:focus, &:hover, &:active {
    border: solid 1px $color-blue;
  }

  input {
    border: none;
  }

  &-addon {
    padding: 0 0.5rem;
    text-align: center;
    line-height: 44px;
    background-color: $color-white;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
  }

  &.prefix {
    .input-group-addon {
      border-right: none;
    }

    input {
      padding-left: 0;
    }
  }

  &.suffix {
    .input-group-addon {
      border-left: none;
    }

    input {
      padding-right: 0;
    }
  }
}

.checkbox__container {
  height: 100%;
  @include make-inline-flex();
  @include make-align-items(center);

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .checkbox {
    position: relative;
    background-color: transparent;
    border: solid 1px $color-black-100;
    border-radius: 22px;
    padding: 11px;
    display: block;
    vertical-align: middle;
    float: left;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;

    .icon {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 1rem;
      height: 1rem;
    }
  }

  .checkbox__label {
    position: relative;
    cursor: pointer;
    display: block;
    float: right;
    padding-left: 0.5rem;
  }

  input:checked + .checkbox {
    background-color: $color-theme;
    border-color: $color-theme;
  }

  &:hover input:not(:checked) + .checkbox {
    border-color: $color-blue;
  }
}

textarea {
  @include make-f-body--regular();
  background-color: $color-white;
  border: solid 1px $color-black-100;
  border-radius: 2px;
  padding: 0.75rem;
  width: 100%;
  -webkit-appearance: none;
}

select {
  @include make-f-body--regular();
  background-color: $color-white;
  border: solid 1px $color-black-100;
  border-radius: 2px;
  padding: 0.75rem;
  width: 100%;
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }

  &::-ms-expand {
    display: none;
  }

  &.select--inline {
    border: none;
    width: auto;
    background: none;
    padding: 0;
  }
}