//====================================================================================================
// Patterns
//====================================================================================================

// Title Text Pattern:
// This pattern applies a 16px margin underneath the title to space it from the following
// body of text or element
//
// Usage:
// <div class="pattern">
//  <*>Title</*>
//
//  (Whatever)
// <div class="">
.pat-title-text > *:first-child {
  margin-bottom: 1rem;
}

.pat-title-text--small > *:first-child {
  margin-bottom: 0.7rem;
}

// This pattern will appropriately position an image and title within an element
.pat-image-wrapper {
  position: relative;

  .pat-image-wrapper__title {
    @include make-flexbox();
    @include make-justify-content(center);
    @include make-align-items(center);
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;

    > * {
      padding: 0 2rem;
      color: $color-white;
    }
  }

  img {
    width: 100%;
    float: left;
    height: 300px;
  }
}

// Pattern to display groups of cards with a title
.pat-card-group {
  & > *:first-child {
    padding-bottom: 0.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
  margin-bottom: 1.5rem;

}

// This will flex inline an element and vertically center its children
.pat-vertical-center {
  @include make-inline-flex();
  @include make-align-items(center);
}

.pat-brands {
  .pat-brands__brand {
    @include make-align-items(center);
    display: inline-block;
    margin: 1.65rem 1rem;
    height: 100%;
    width: calc((100% / 8) * 1);

    &-medium {
      width: calc((100% / 7) * 1);
      padding: 0 0.635rem;
    }

    &-medium-wider {
      width: calc((100% / 6) * 1);
      padding: 0 1.5rem;
    }

    &-large {
      width: calc((100% / 5) * 1);
      padding: 0 2.683rem;
    }
  }
}

.pat-brands--row {
  position: relative;
  text-align: center;

  .pat-brands__brand {
    display: inline-block;
    height: 100%;
    padding: 1rem 5%;
  }
}

.pat-brands--inline {
  position: relative;
  text-align: left;

  .pat-brands__brand {
    display: inline-block;
    height: 100%;
    padding: 0;
    max-width: 64px;
  }
}

.pat-medium-columns {
  text-align: center;

  .pat-col {
    @include make-align-items(center);
    display: inline-block;
    padding: 1rem;
    height: 100%;
    width: calc(20% - 5px);
    text-decoration: none;
  }
}

//
.pat-input-group {
  *:not(:first-child) {
    float: left;
    margin-top: 0.5rem;
  }

  &:after {
    @include make-clearfix;
  }

  input {
    margin-bottom: 0.5rem;
  }
}

//
.pat-image-label {
  display: table;
  white-space: nowrap;

  * {
    display: inline-block;
    vertical-align: middle;
  }

  *:first-child {
    width: 25%;
  }

  *:nth-child(2) {
    margin-left: 0.5rem;
    width: calc(75% - 0.5rem);
  }
}

//
.pat-element-pair {
  & > * {
    float: left;
    width: calc(50% - 0.5rem);
  }

  & > *:last-child {
    margin-left: 1rem;
  }
}

// For an inline icon with content indented
.pat-icon-with-content {
  @include make-flex();
  position: relative;

  .icon-title--small {
    margin-bottom: 0.5rem;
  }

  .icon-content__icon {
    width: 2rem;
    display: block;
    float: left;
    margin-right: 1rem;
  }

  .icon-content__container {
    width: calc(100% - 3rem);
    margin-left: 3rem;
    margin-bottom: 1.5rem;
  }
}

//====================================================================================================
// Media Queries
//====================================================================================================

@media (max-width: $screen-large) {
  .pat-brands {
    .pat-brands__brand {
      width: calc((100% / 12) * 2);

      &-medium, &-medium-wider, &-large {
        padding: 0 !important;
      }
    }
  }

  .pat-brands--row {
    .pat-brands__brand {
      padding: 1rem 3%;
    }
  }
}

@media (max-width: $screen-medium) {
  // Pattern that floats an image in a column to the right to the top of the grid on mobile views
  .pat-right-on-top {
    @include make-flexbox();
    @include make-flex-direction(row);
    @include make-flex-wrap(wrap);

    > :nth-child(2) {
      @include make-flex-order(-1);
      margin-top: 0 !important;
      margin-bottom: 2rem;
    }
  }

  .pat-brands {
    .pat-brands__brand {
      width: calc((100% / 12) * 2);
    }
  }

  .pat-medium-columns {
    text-align: center;

    .pat-col {
      @include make-align-items(center);
      display: inline-block;
      padding: 1rem;
      height: 100%;
      width: calc(33% - 4px);
    }
  }

  .pat-brands--row {
    .pat-brands__brand {
      padding: 1rem 1%;
    }
  }

  .pat-icon-with-content {
    display: block;
    position: relative;
    text-align: center;

    .icon-content__icon {
      width: 2rem;
      display: block;
      float: none;
      margin: auto;
    }

    .icon-content__container {
      width: 100%;
      max-width: 480px;
      margin: 1rem auto 2rem;
      float: none;
    }

    &:last-child .icon-content__container {
      margin-bottom: 0;
    }
  }
}

@media (max-width: $screen-small) {
  .pat-image-wrapper {
    img {
      height: 380px;
    }
  }

  .pat-brands {
    .pat-brands__brand {
      width: calc((100% / 9) * 2);
    }
  }

  .pat-medium-columns {
    text-align: center;

    .pat-col {
      @include make-align-items(center);
      display: inline-block;
      padding: 1rem;
      height: 100%;
      width: calc(50% - 3px);
    }
  }

  .pat-card-group {
    .pat-card-group__cards {
      @include make-flex-direction(column);
    }
  }

  .pat-card-group__title {
    .accordion & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}