.mosaic {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba($color-grey, 0.05);

  .mosaic__tile {
    background-size: cover;
    background-position: center;
    float: left;
    width: 50%;
    padding-top: #{(56.25% / 2)};

    &--medium {
      width: #{(50%*2/3)};
      padding-top: #{(56.25% / 4)};
    }

    &--small {
      width: #{(50%/3)};
      padding-top: #{(56.25% / 4)};
    }
  }
}

@media (max-width: $screen-medium) {
  .mosaic {
    .mosaic__tile {
      background-size: cover;
      background-position: center;
      float: left;
      width: 100%;
      padding-top: #{(56.25%)};

      &--medium {
        width: #{(100%/2)};
        padding-top: #{(56.25% / 2)};
      }

      &--small {
        width: #{(100%/3)};
        padding-top: #{(56.25% / 3)};
      }
    }
  }
}