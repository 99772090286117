.view-more {
  overflow: hidden;
  margin-bottom: 0.25rem;
  position: relative;
  transition: max-height 0.3s;
  will-change: max-height;

  &.active:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0) 80%, $color-white);
  }
}

.view-more-button {
  display: none;
}