@-moz-keyframes slideOutDownFade {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }
}

@keyframes slideOutDownFade {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }
}

@-moz-keyframes slideInUpFade {
  from {
    transform: translate3d(0, 10%, 0);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUpFade {
  from {
    transform: translate3d(0, 10%, 0);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-moz-keyframes slideYPos {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideYPos {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slideYNeg {
  from {
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideYNeg {
  from {
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slideXPos {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideXPos {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slideXNeg {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideXNeg {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate {
  animation-duration: $animation-speed;
  animation-direction: normal;
  animation-fill-mode: forwards;

  &.slide-in-top {
    transform: translate3d(0, -100%, 0);

    &.enter {
      animation-name: slideYNeg;
    }
  }

  &.slide-in-bottom {
    transform: translate3d(0, 100%, 0);

    &.enter {
      animation-name: slideYPos;
    }
  }

  &.slide-in-left {
    transform: translate3d(-100%, 0, 0);

    &.enter {
      animation-name: slideXNeg;
    }
  }

  &.slide-in-right {
    transform: translate3d(100%, 0, 0);

    &.enter {
      animation-name: slideXPos;
    }
  }

  &.slide-out-top {
    transform: translate3d(0, 0, 0);

    &.enter {
      animation-name: slideYPos;
      animation-direction: reverse;
    }
  }

  &.slide-out-bottom {
    transform: translate3d(0, 0, 0);

    &.enter {
      animation-name: slideYPos;
      animation-direction: reverse;
    }
  }

  &.slide-out-left {
    transform: translate3d(0, 0, 0);

    &.enter {
      animation-name: slideXNeg;
      animation-direction: reverse;
    }
  }

  &.slide-out-right {
    transform: translate3d(0, 0, 0);

    &.enter {
      animation-name: slideXPos;
      animation-direction: reverse;
    }
  }
}

.no-slide {
  animation: none !important;
  transform: initial !important;
}
