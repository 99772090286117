.fade-out-slide-left {
  animation: fade-out-slide-left 0.5s forwards;
}

.fade-in-slide-left {
  animation: fade-in-slide-left 0.5s forwards;
  pointer-events: auto;
}

@keyframes fade-out-slide-left {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-8px, 0, 0);
  }
}

@keyframes fade-in-slide-left {
  from {
    transform: translate3d(8px, 0, 0);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: $screen-medium) {
  [scrolluptohide] {
    transform: translate3d(0, 0, 0);

    &.slideInDown {
      transform: translate3d(0, 0, 0);
    }

    &.slideOutUp {
      transform: translate3d(0, -$navigation-height, 0);
    }
  }

  [scrolldowntohide] {
    transform: translate3d(0, 0, 0);
    transition: 0.5s;

    &.slideOutDown {
      transform: translate3d(0px, 71px, 0px);
    }

    &.slideInUp {
      transform: translate3d(0px, 0px, 0px);
    }
  }
}