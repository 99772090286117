//====================================================================================================
// List
//====================================================================================================
.list {

}

.list__label {
  @include make-f-label;
  display: block;
  margin-bottom: 1rem;
}

.list__items {
  display: table;
  padding-left: 0;
  margin-top: 0.25rem;

  li {
    display: inline-table;
    width: 100%;
  }

  li:before {
    content: "\25A0";
    width: 10px;
    color: $color-theme;
    font-size: 16px;
    position: relative;
    top: -3px;
    display: table-cell;
    padding: 0 0.5rem;
  }

  &.inline {

    li:before {
      display: inline;
    }

  }

  &--two-columns {
    li {
      width: 50%;
      float: left;
    }
  }

  &.secondary-colour{
    li:before {
      color: $color-button;
    }
  }

  &.white{
    li:before {
      color: $color-white;
    }
  }
}

.list__items--numbered {
  list-style-type: decimal;
  padding-left: 1.25rem;

  > .list__item {
    padding-left: 1rem;
  }
}

.list__item {
  padding: 0.35rem 0;
  :not(:first-of-type) {
    margin-top: 0.35rem;
  }
}

.list__item--text {
  display: inline-block;
  vertical-align: text-top;
}

.list__header {
  height: 100%;
  float: left;
  padding-right: 1rem;

  & > .header--theme-color {
    margin-bottom: 0.5rem;
  }
}

.list__content {
  height: 100%;
  float: right;
  border-bottom: 1px solid $color-black-50;
}

#overviewScrollTo {
  & .list__content {
    padding-bottom: 1.5rem;
  }

  & .list__row:last-child .list__content {
    padding-bottom: 0;
  }
}

#dealershipDetails {
  & .list__content {
    padding-bottom: 1.5rem;
  }

  & .list__row:last-child .list__content {
    padding-bottom: 0;
  }
}

.list__row {
  display: inline-block;
  width: 100%;
  margin-top: 1.5rem;
  &:first-child {
    margin-top: 0.25rem;
  }

  &.list__row--no-margin {
    margin: 0;
  }

  span + span {
    float: right;
  }

  &:last-child > .list__content {
    border: none;
  }

  hr:last-child {
    padding: 1rem 0;
  }

  &--hidden {
    visibility: hidden;
    overflow: hidden;
    max-height: 0;
    transition: 0.3s;
    margin-top: 0;
    display: block;
  }
}

@media (max-width: $screen-medium) {
  #overviewScrollTo {
    .list__row {
      > .list__content {
        padding-bottom: 2rem;
      }
    }
    & .list__row:last-child .list__content {
      padding-bottom: 0;
    }
  }

  .list__header {
    padding: 0;

    h5 {

      font-size: 1.1875rem;

      &.no__title {
        padding-top: 0;
      }
    }
  }

  .list__row {
    &:last-child > .list__content {
      padding-bottom: 0;
    }
  }
  .list__items {
    &--two-columns {
      li {
        width: 100%;
        float: none;
      }
    }
  }
  .list__item--text {
    width: 100%;
    float: none;
  }
  .list__header {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .list__content {
    width: 100%;
  }
}
