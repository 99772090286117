img, video, iframe {
  // Fixes alignment and padding for adjacent elements
  vertical-align: middle;
  border: none;
  width: 100%;
}

.iframe {
  height: 500px;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
}

.video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 100%;
  background: $color-black-500;
  transition: background 0.3s;
  object-fit: cover;

  @include make-fullscreen {
    object-fit: contain;
  }

  &--21-9 {
    padding-bottom: 42.1875%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  &--full {
    width: 100%;
    padding: 0;
  }

  &--cover {
    height: 100%;
    object-fit: cover;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page__banner {
  display: block;
  height: 100%;
  background-color: $color-grey--light;

  img {
    height: 100%;
    object-fit: cover;
    position: relative;
    object-position: center;
  }
}

.map {
  //border-radius: 4px;
  //overflow: hidden;
  //z-index: 1;
  margin-bottom: 1rem;
}

.dealership-map, #dealership-map {

  .map-reset-button, #map-reset-button {
    display: block;
    position: relative;
    text-transform: none;
    appearance: none;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    color: #191919;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    background: none;
    background-color: rgb(255, 255, 255);
    border: 0;
    border-radius: 2px;
    margin: 10px 10px 0 0;
    padding: 0 10px;
    top: 0;
    right: 0;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);

    &:hover {
      color: #000;
    }
  }

  .infoWindow {
    margin-right: 0.5rem;

    &-title {
      color: #000;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    &-body {
      span {
        display: block;
        color: #5b5b5b;
        font-weight: 400;
        margin-bottom: 0.75rem;
      }

      a {
        cursor: pointer;
        color: #1a73e8;
        font-family: inherit;
        font-size: inherit;
        font-weight: 400;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .gm-ui-hover-effect {
    top: 3px !important;
    right: 0 !important;
  }
}

.dealership-map.map,
#dealership-map.map {
  height: 100%;
  position: relative;
  width: calc(100% - 4rem);
  margin: 0 auto;
  border: 4px solid $color-white;
  top: 0;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  min-height: 600px;

  &.map--homepage {
    width: 100%;
    margin: 0 0 1.5rem;
  }
}

#dealership-map.full-map {
  height: 100%;
  position: absolute;
  width: calc(100% - 4rem);
  margin: 0 auto;
  border: 4px solid $color-white;
  top: 0;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  min-height: 600px;
}

#dealership-map.dealership-map--large {
  height: 500px;
  top: -6rem;
  z-index: 0;
  position: absolute;
  border: 4px solid $color-white;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
}

#dealership-map.full-section {
  height: 100%;
  width: 100%;
  margin: 0;
  border: 0;
  top: 0;
  border-radius: 0;
  box-shadow: none;
  min-height: 600px;
}

.dealership-map__spacer {
  height: calc(500px - 6rem);
  position: relative;
}

.img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;

  & + label {
    margin-top: 0.5rem;
  }

  &--16-9 {
    padding-top: 56.25%;
  }

  &--1-1 {
    padding-top: 100%;
  }

  &--5-9 {
    padding-top: 180%;
  }

  &--1-08 {
    padding-top: 125%;
  }

  &--5-1 {
    padding-top: 20%;
  }

  &--2-1 {
    padding-top: 50%;
  }

  &--5-2 {
    padding-top: 40%;
  }

  &--2-3 {
    padding-top: 116%;
  }

  &--4-3 {
    padding-top: 75%;
  }

  &--3-2 {
    padding-top: 66%;
  }

  &--7-2 {
    padding-top: 28.57142857%;
  }

  &--contain {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.img--clip {

  &-circle {
    -webkit-mask: radial-gradient(circle closest-side, #fff 99%, transparent 100%);
    mask: radial-gradient(circle closest-side, #fff 99%, transparent 100%);
  }
}

.bg {

  &-theme {
    background-color: rgba($color-theme, 0.75);
  }

  &-theme-darker {
    background-color: rgba($color-theme--darker, 1);
  }

  &-theme-darker-opacity {
    background-color: rgba($color-theme--darker, 0.89);
  }

  &-black {
    background-color: rgba($color-black, 1);
  }

  &-black-opacity {
    background-color: rgba($color-black, 0.89);
  }

  &-gradient {
    background: linear-gradient(180deg, $color-black-1000, rgba($color-black-1000, 0));
  }

  &-no-repeat {
    background-repeat: no-repeat;
  }

  &-cover {
    background-size: cover;
  }
}

.moving-block {
  transition: transform 0.1ms linear;
  will-change: transform;
  transform: scale(1.55);
}

img.logo {
  width: inherit;
}

.lozad {
  opacity: 0;
  transition: opacity 0.3s;

  &--loaded {
    opacity: 1;

    img {
      opacity: 1;
    }
  }
}

picture.lozad {
  opacity: 0;
  min-height: 1px;

  img {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &--loading {
    opacity: 1;
  }

  &--loaded {
    img {
      opacity: 1;
    }
  }
}

.dealership-logo {
  height: 2.5rem;

  &--large {
    height: 3.5rem;
  }
}

.showroom-image {
  background-size: contain;
  background-repeat: no-repeat;
  height: 400px;
  background-position: center;
  margin-bottom: 1rem;
  position: relative;

  &--card {
    height: 300px;
  }
}

.showroom-model {
  padding-top: 50%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-image__container {
  position: relative;

  img.lozad {
    min-height: 320px;
    background-color: $color-grey--lighter;

    &.lozad--loaded {
      background-color: transparent;
      min-height: unset;

      & + .hero-image__label {
        opacity: 1;
      }
    }
  }

  .hero-image__label {
    & h1, h2, h3, h4, h5 {
      color: $color-white;
    }

    color: $color-white;
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    transform: translateY(-100%);
    padding: 4rem 1rem 1rem;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.85));
  }
}

.gallery {
  background-color: black;

  .swiper-slide {
    padding-top: 56.25%;
    height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

a > img {
  border: none;
}

.dealer-image {
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 2rem);
  margin-right: 1rem;
  margin-top: -2.5rem;
  height: calc(100% + 10rem);
}

.cover-image {
  width: 100%;
  height: 100%;
}

.cover-image--mobile {
  display: none;
}

.hero-image {
  box-shadow: 0 17px 40px 0 rgba(37, 16, 7, 0.16);
}

.img__slant {
  &-right {
    -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
  }

  &-left {
    -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
  }
}

@media (max-width: $screen-x-large) {
  .dealer-image {
    height: calc(38vw - 4rem);
  }

  .hero-image {
    height: 100%;
  }
}

@media (max-width: $screen-large) {
  .dealer-image {
    margin-top: 0;
  }

  .showroom-image {
    height: 30vw;
  }

  .hero-image {
    height: 100%;
  }

  .dealership-map__spacer {
    padding: 0 1.5rem;
    width: calc(100% - 3rem);
  }
}

@media (max-width: $screen-medium) {
  .dealer-image {
    height: 58vw;
    margin-right: 0;
    position: relative;
    width: 100%;
  }

  .hero-image {
    height: 100%;
  }

  .hero-image__container {
    .hero-image__label {
      padding: 2rem 0.75rem 0.75rem;

      h3 {
        font-size: $fs-m;
      }

      p {
        font-size: $fs-s;
      }
    }
  }

  .showroom-image {
    height: 43vw;
  }

  #dealership-map.full-map {
    display: none;
    min-height: 0;
  }

  #dealership-map.map {
    width: 100%;
    margin: 0;
  }

  .rabbitohs_logo {
    padding: 0 10rem;
  }

  .page__banner {
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}

@media (max-width: $screen-small) {
  .cover-image--mobile {
    display: block;
    width: 100vw;
    position: relative;
    left: -1.5rem;
  }

  .dealer-image {
    top: -1rem;
    left: -1.5rem;
    width: 100vw;
    margin-top: -5rem;
  }

  .hero-image {
    height: 100%;
  }

  .hero-image__container {
    .hero-image__label {
      padding: 0.75rem;
      position: relative;
      transform: translateY(0);
      margin-top: -10%;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 2rem);
    }
  }

  .cover-image {
    display: none;
  }

  .rabbitohs_logo {
    padding: 0;
  }
}