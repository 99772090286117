//====================================================================================
//  Core Theme Colours
//  These are constant colours to be used throughout the different sites' themes
//====================================================================================
// Colors

// Standardised colours
$color-radio-active: #DBDDDE !default;
$color-radio-border: #72797D !default;
$color-black--darker: #5a5a5a !default;

// Blacks & Greys
$color-black-50: #e5e5e5 !default;
$color-black-100: #bebfbf !default;
$color-black-200: #929495 !default;
$color-black-300: #67696a !default;
$color-black-400: #46484a !default;
$color-black-500: #25282a !default;
$color-black-600: #222425 !default;
$color-black-700: #1c1e1f !default;
$color-black-800: #161819 !default;
$color-black-900: #0c0f0f !default;
$color-black-1000: #000000 !default;

// Theme colours
$color-theme: $color-black-400 !default;
$color-theme-50: #e5e5e5 !default;
$color-theme-100: #bebfbf !default;
$color-theme-200: #929495 !default;
$color-theme-300: #67696a !default;
$color-theme-400: #46484a !default;
$color-theme-500: #25282a !default;
$color-theme-600: #222425 !default;
$color-theme-700: #1c1e1f !default;
$color-theme-800: #161819 !default;
$color-theme-900: #0c0f0f !default;
$color-theme--darker: $color-black-500 !default;

// Black
$color-black: #1e1e1e !default;

// Grey
$color-grey: #c8c8c8 !default;
$color-grey--darker: #5a5a5a !default;
$color-grey--dark: #7c7d7e !default;
$color-grey--light: #f5f5f5 !default;
$color-grey--lighter: #fafafa !default;

// Bley
$color-bley--light: #f8fafc !default;
$color-bley: #90999f !default;
$color-bley--dark: #7a848b !default;

// Blue
$color-blue: #0397f5 !default;

// White
$color-white: white !default;

// Green
$color-green--light: #c6efcd !default;
$color-green: #34c64d !default;

// Gold
$color-gold: #ffad3b !default;

// Yellow
$color-yellow: #f5d377 !default;

// Orange
$color-orange--light: #ffddad !default;
$color-orange: #eb9013 !default;
$color-error: #e9590b !default;

// Pink
$color-pink: #e50c5a !default;

// Certified
$color-certified-blue: #2196f3 !default;
$color-certified-blue--dark: #045ef3 !default;

// Secondary Button
$color-button-secondary: #6C7680 !default;
$color-button-secondary--hover: #4B5360 !default;
$color-button-secondary-text: $color-white !default;

// Black Button
$color-button-black: $color-black !default;
$color-button-black--hover: lighten($color-black, 5%) !default;
$color-button-black-text: $color-white !default;

// Ribbon
$color-ribbon: $color-pink !default;
$color-ribbon-secondary: $color-pink !default;
$color-ribbon-text: $color-white !default;
$color-ribbon-secondary-text: $color-white !default;

//===================================================

// Text links
$link-color: $color-blue !default;

// Main nav themes
$nav-color: $color-white !default;
$nav-mobile-color: $color-grey !default;
$nav-title-color: $color-white !default;
$nav-bg-color: $color-grey !default;
$nav-br-color: $color-grey !default;
$nav-mobile-bg-color: "" !default;
$nav-toggle-color: $color-white !default;
$nav-active-br-color: $color-black-400 !default;
$nav-toggle-bg-color: $color-black-400 !default;
$nav-hover-color: $color-grey !default;
$nav-hover-bg-color: $color-white !default;
$nav-logo: "" !default;
$nav-logo-mobile: "" !default;

// Contact Nav themes
$contact-nav-bg-color: $color-grey--light !default;
$contact-nav-color: $color-grey--darker !default;
$contact-nav-br-color: $color-black-50 !default;

// Sub Nav themes
$sub-nav-bg-color: $color-grey !default;
$sub-nav-br-color: $color-black-400 !default;
$sub-nav-hover-color: $color-white !default;
$sub-nav-hover-bg-color: $color-grey !default;
$sub-nav-desc-color: $color-grey !default;
$sub-nav-title-color: $color-white !default;
$sub-nav-item-title-color: $color-black !default;
$sub-nav-carousel-theme-item-color: $color-white !default;
$sub-nav-carousel-hover-item-color: $color-black-1000 !default;
$sub-nav-carousel-theme-hover-item-color: $color-black-1000 !default;
$sub-nav-mobile-active-title-color: $color-grey !default;
$sub-nav-mobile-hover-title-color: $color-blue !default;

// Footer
$footer-bg-color: $color-black-800 !default;
$footer-heading-color: $color-white !default;
$footer-link-color: $color-grey !default;
$footer-link-hover-color: $color-grey !default;
$footer-link-underline-color: $color-grey !default;
$footer-mobile-hover-title-color: $color-blue !default;

// Colour for headers across the site (.header--theme-color)
$header-color: $color-grey !default;

// Colours for cards
$card-header-color: $header-color !default;
$card-price-color: $color-black !default;
$card-icon-color: $header-color !default;
$card-link-color: $link-color !default;
$card-hover-border-color: $color-blue !default;
$card-hover-shadow-base: $color-black-1000 !default;
$card-hover-shadow-tint: $color-blue !default;

// Colours for the Main header on content pages
$header-title-color: $color-grey !default;
$header-sub-title-color: $color-grey !default;
$header-gradient-color-left: $color-black-50 !default;
$header-gradient-color-right: $color-black-100 !default;

// Colours for the VDP page header
$header-VDP-title-color: $color-grey !default;
$header-VDP-gradient-color-left: $color-black-50 !default;
$header-VDP-gradient-color-right: $color-black-100 !default;

// Form/filter
$form-link-color: $color-blue !default;
$form-input-border-color: $color-blue !default;
$form-svg-color: $color-blue !default;
$filter-item-click-color: $color-theme !default;
$filter-title-color: $color-blue !default;
$filter-title-bg-color: $color-grey--light !default;
$filter-title-hover-svg-color: $color-black-1000 !default;
$filter-title-hover-bg-color: darken($filter-title-bg-color, 5%) !default;

// Main Button
$color-button: $color-grey !default;
$color-button--hover: $color-grey--dark !default;
$color-button-text: $color-white !default;
$color-button-text--hover: $color-white !default;

// SVG icon colours (non-card)
$icon-bg-color: linear-gradient(227deg, #004e72, #00263e) !default;
$icon-fill-color: $color-white !default;

// Colour for the sticky footer elements
$sticky-footer-background-color: $color-grey !default;
$form-header-color: $color-black-600 !default;

// Panels
$panel-background-color: $color-black-700 !default;
