.container__panel {
  position: relative;
  margin: auto;
}

.feature-panel {
  overflow: hidden;
  height: 100%;
  position: relative;
  float: left;

  [onclick] {
    cursor: pointer;
  }

  .feature-panel__image {
    &.image--cover {
      height: 245px;
      background-size: cover;
      background-position: center;
    }

    &.image--block {
      display: none;
    }
  }

  &.grid, &.grid > a {
    & > {
      padding: 0;
    }

    .feature-panel__content {
      padding: 1.5rem 0;
    }

    .feature-panel__image {
      padding: 0;
    }
  }

}

.panel {
  padding-bottom: 5rem;
  padding-top: 5rem;
  text-align: left;
  width: 80%;

  .panel__content {
    min-height:312px;
  }

  .panel__title {
    @include make-f-heading(3);

    color: inherit;
    letter-spacing: 0;
    text-transform: none;
  }

  &--right {
    margin-left: 20%;
    padding-left: calc(#{50% * 2 / 3} + 1rem);
    padding-right: 5rem;

    .image {
      left: 0;

      .image__clip, .image--background {
        -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
        clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
      }
    }
  }

  &--left {
    margin-right: 20%;
    padding-left: 5rem;
    padding-right: calc(#{50% * 2 / 3} + 1rem);

    .image {
      right: 0;

      .image__clip, .image--background {
        -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
        clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
      }
    }
  }

  &--grey {
    background-color: $color-grey--light;
    color: $color-theme;

    p {
      color: $color-black;
    }

    .image--background {
      background-color: $color-grey;
    }
  }

  &--theme-color {
    background-color: $panel-background-color;
    color: $color-white;

    p {
      color: inherit;
    }

    .image--background {
      background-color: $color-grey--light;
    }
  }

  &--rounded {
    border-radius: 8px;
  }
}

@media (max-width: $screen-small) {
  .panel {
    &--rounded {
      border-radius: 0;
    }
  }
}

@media (max-width: $screen-medium) {
  .panel {
    &--rounded {
      border-radius: 0;
    }
  }
}

.quote {
  .quote__avatar {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    margin-right: 0.5rem;
    vertical-align: top;
    width: 40px;
  }

  .quote__image-placeholder {
    // Default background
    background: linear-gradient(126deg, #77bde1, #477bc5);
    // Some shadow on the text for contrast on light yellows and blues
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    color: white;
    display: inline-block;
    @include make-font($ff-heading-med);
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    margin-right: 0.5rem;
    text-align: center;
    vertical-align: top;
    width: 40px;
  }

  .quote__details {
    width: calc(100% - 56px);
  }

  .quote__name {
    @include make-f-label($color-black-500, $fs-s);

    &--large {
      @include make-f-label($color-black-500, $fs-m);
    }
  }

  .quote__rating {
    .icon-star {
      display: inline-block;
    }
  }

  .quote__text {
    @include make-f-body--small($color-black-500);
    margin-top: 0.5rem;

    &--large {
      @include make-f-body--regular($color-black-500);
    }
  }
}

.image {
  height: calc(100% - 6rem);
  position: absolute;
  top: 2.5rem;
  width: 50%;
}

.image--background {
  width: 100%;
  height: 100%;
}

.image__clip {
  background-position: center;
  background-size: cover;
  height: 100%;
  transform: translateZ(0);
  z-index: 2;
}

.carousel--panels {
  .swiper-container {
    background-color: transparent;
  }

  .swiper-slide {
    @include make-flexbox;
    @include make-flex-direction(column);

    background: none;
    height: auto;
    max-height: 100%;
    max-width: 1000px;
    width: calc(100% - 160px - 2rem);
  }

  .swiper-button-prev {
    left: 2rem;
  }

  .swiper-button-next {
    right: 2rem;
  }

  .swiper-button-next, .swiper-button-prev {
    transition: all 0.25s;
    background: none;
    fill: #9b9b9b;
    padding: 0;

    &.swiper-button-disabled {
      opacity: 0;
      transition: all 0.25s;
    }
  }

  .swiper__content {
    @include make-flexbox;
    @include make-flex-direction(column);
    @include make-flex-grow(1);
    width: 100%;
  }

  .panel {
    &--grey {
      background-color: $color-theme-50;
    }
  }

  .swiper-lazy-loading {
    opacity: 0;
  }

  .swiper-lazy-loaded {
    opacity: 1;
    transition: opacity 0.3s;
  }
}

@media (max-width: $screen-x-large) {
  .feature-panel {
    .feature-panel__image {
      &.image--cover {
        height: 18vw;
      }
    }
  }

  .carousel--panels {
    .swiper-button-prev {
      left: calc(50vw - 600px + 2rem);
    }

    .swiper-button-next {
      right: calc(50vw - 600px + 2rem);
    }
  }
}

@media (max-width: $screen-large) {
  .panel {
    &--right {
      .image {
        height: 300px;
      }
    }

    &--left {
      .image {
        height: 300px;
      }
    }
  }
}

@media (max-width: $screen-medium) {
  .feature-panel {
    .feature-panel__image {
      &.image--cover {
        display: none;
      }

      &.image--block {
        display: block;
      }
    }

    &.grid, &.grid > a {
      .feature-panel__content {
        padding: 1rem 0 1.5rem 0;
      }
    }
  }

  .panel {
    width: 100%;
    margin: 0;

    &--right {
      margin-left: auto;
      padding: 3rem 2rem 2rem;

      .image {
        width: 100%;
        top: 0;
        left: 0;
        height: 64vw;
      }
    }

    &--left {
      margin-right: auto;
      padding: 3rem 2rem 2rem;

      .image {
        width: 100%;
        top: 0;
        right: 0;
        height: 64vw;
      }
    }
  }

  .container__panel {
    padding-top: calc(64vw - 2rem);
  }

  .carousel--panels {
    .swiper-slide {
      max-width: 500px;
      width: calc(100% - 2rem);
    }

    .swiper__content {
      margin: auto;
      width: 100%;
      padding: calc(60vw - 3rem) 0 2.5rem;

      .panel {
        margin: 0;
        padding: 5rem 2rem;
        width: 100%;
        height: 100%;

        .panel__content {
          min-height:0;
        }
      }

      .image {
        height: 60vw;
        padding: 0.5rem;
        width: 100%;
        top: 0;
      }
    }
  }
}

@media (max-width: $screen-small) {
  .carousel--panels {
    .swiper__content {
      padding: 70vw 0 2.5rem;

      .panel {
        padding: 4rem 1.5rem;
        margin: 0;
        width: 100%;
      }

      .image {
        height: 80vw;
        padding: 0.5rem;
      }
    }
  }
}