html, body {
  //Forces the web to render backup fonts until the font styles load
  font-family: Arial, sans-serif;

  @include make-f-body--regular;

  height: 100%;
  position: relative;
  background: $color-white;
  margin: 0;
  -webkit-font-smoothing: antialiased;

  /* Disable tap overlay color on links - set alpha to %0 = invisible */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  box-sizing: border-box;
}

// Html overrides
label {
  display: block;
}

hr {
  margin: 0;
  padding: 0;
  border-top: 1px solid $color-black-100;
  clear: both;
}

hr.light {
  border-color: $color-black-50;
}

hr.dark {
  border-color: $color-grey--darker;
}

hr.gap {
  padding: 0.75rem 0;
  margin: 0;
  content: '';
  border: none;
}

hr.gap-medium {
  padding: 0.5rem 0;
  margin: 0;
  content: '';
  border: none;
}

hr.gap-small {
  padding: 0.25rem 0;
  margin: 0;
  content: '';
  border: none;
}

.vertical__hr {
  border-left: 1px solid $color-black-50;
  height: 100%;
}

b, strong {
  @include make-font($ff-main);
  font-weight: 700;
}

p {
  margin: 0;
  padding: 0;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.relative-container {
  position: relative;
}

.overflow--hidden {
  overflow: hidden;
}