.cms-component {
  position: relative;
  min-height: 52px;

  & .cms-button {
    background: #f9f8fa;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.065);
    box-sizing: border-box;
    color: #1a1a1a;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .3s,background-color .3s,transition .3s;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    display: inline-flex;
    align-items: center;
    height: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 8px 8px 8px;

    &:hover {
      background: #ffffff; text-decoration: none;
    }

    &--edit {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    & .cms-button-icon--edit {
      display: inline-block;
      margin-right: .5rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACPTkDJAAACY0lEQVRYCb2XP0gcQRjF3663JAbFC5fWQsUioARE8AxcCAaCIIq1TRDSpBO0y6GLiGlSpkmndRoxufIschhOsBFFi5DYmsI/gRCEU9fv7Xr4b/budmfuPpjbm9mZ93s7MzuzY+FOjM54HaUzvJHijKROSSnPw0O5OpKiRMmycCoNDiX9llRwElj++tHavylilTOu69kbx8h6wKykpnK5yavAziXNDzzGgutaF9T2DRBePMGqPOmISWCYlvRMLp3EGE3YrOQ/eYPg5PFByeR/yx/zEn7Wq9sJUQWHw3HQbXPCNRpOQ2SSzSHgbDceMs5oflBVNpOQKnzVjEb/U2BmAki2Aj+2gcUl4OxciehkD6SUt2IWDvYA7tsATonnvcDrgVCxlH21yITWiHIjLfD3k0DiziqSbFGrkM0eiLrCKdUIzyrg/2UtzG8qm7DQoQHtqATPfgb+HIUjtA1Ug+/uh8N5R8uALlzLgAl4bAOm4LEMmIRHNmAaHtlAqu3+IsP3nK9atdlOmCpqegtaHgVNc+vApy/XMrpwKtVk4MM74FV/AP52ZcIEnIrcDStGTxfQ3Q5My+7G4LJKE4Ut4O+/oEznlz1QqiQw/iK4a0tNmnjZF+RNwMlO8NNZdiXlhvQkCQzKdso4kI9rPvnmXpA38Us2h0Ck0aoSzDwDdn4BK9+B4o7/MamqplN2aA1PeXnpgSEdlbhtpQfWOAcKcQUMtCvYPC7xE9mAWCQJMsm2eVaTzHyk1gYqk0k2hwA8q8l45Azo1iRBFpms7BvgGY1nNcnM1XM4qE1G+VxIA1J2Oxp9PL8ECAnVauDLM90AAAAASUVORK5CYII=) 0 50% no-repeat;
      background-size: 16px;
      width: 16px;
      height: 16px;
    }
  }

  & .cms-button {
    display: none;
  }

  &:hover .cms-button {
    display: inline-flex;
  }

  &:hover:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(79,79,229,1) 0%, rgba(0,255,252,1) 51%, rgba(79,79,229,1) 100%);
    clip-path: polygon(0% 0%, 0% 100%, calc(0% + 4px) 100%, calc(0% + 4px) calc(0% + 4px), calc(100% - 4px) calc(0% + 4px), calc(100% - 4px) calc(100% - 4px), 0% calc(100% - 4px), 0% 100%, 100% 100%, 100% 0%);
    animation: animatedgradient 4.5s cubic-bezier(.68,.9,.48,.21) alternate infinite;
    background-size: 400% 400%;
    pointer-events: none;
    z-index: 5;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}