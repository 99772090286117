.rolyart-calendar {
  max-width: 100%;
  background-color: white;
  border-radius: 6px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.calendar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #DDDDDD;
}

.calendar-header h3 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
}

.calendar-header .calendar-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.calendar-header .calendar-control button {
  width: 34px;
  height: 34px;
  padding: 0;
  cursor: pointer;
  border: 1px solid #DDDDDD;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  font-size: 18px;
  border-radius: 3px;
  color: $color-theme;
}

.calendar-header .calendar-control button.control-current-month {
  margin: 0 0.5rem;
}

.calendar-header .calendar-control button.control-current-month .current-month {
  width: 12px;
  height: 12px;
  background-color: $color-theme;
  content: '';
  display: inline-block;
  border-radius: 50%;
}

.week-days {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0.5rem 0;
  border-bottom: 1px solid #DDDDDD;
}

.week-days div {
  width: calc(100% / 7);
  text-align: center;
}

.calendar-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.calendar-body .day {
  width: calc(100% / 7);
  text-align: center;
  padding: 0.25rem 0;
  cursor: pointer;
}

.calendar-body .day span {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.calendar-body .day {

  &.selected {
    font-weight: bold;
    background-color: $color-theme;
    display: inline-block;
    color: white;
  }

  &.disabled {
    color: $color-grey;
    pointer-events: none;
  }
}

.calendar-body .day.not-current {
  color: #999999;
}