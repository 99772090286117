.livechat--badge {
  width: 14px;
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  background-color: red;
  position: absolute;
  color: #fff;
  z-index: 10;
  margin-left: calc(50% - 14px);
  margin-top: 11px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: opacity .25s;
  pointer-events: none;

  &.active {
    opacity: 1;
  }
}

.livechat__container {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  z-index: 1000000;

  .livechat--badge {
    top: 2px;
    right: 0;
  }

  & > .button--livechat {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    padding: 0;
  }

  &.show > * {
    display: block;
  }

  & > * {
    display: none;
  }
}

#chat-widget-container {
  opacity: 1;
  transition: opacity 1s;
}

@media (max-width: $screen-medium) {
  #chat-widget-container {
    pointer-events: none !important;
    opacity: 0 !important;
    transition-delay: 0.2s;
    z-index: -1 !important;
  }

  #chat-widget-container.active {
    pointer-events: all !important;
    opacity: 1 !important;
    z-index: 30000 !important;
  }
}
