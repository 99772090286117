@charset "UTF-8";
.emj {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin: 0 .05em 0 .1em;
  vertical-align: -0.1em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1em 1em;
}

.emj--lg {
  height: 1.33em;
  width: 1.33em;
  margin: 0 0.0665em 0 0.133em;
  vertical-align: -0.133em;
  background-size: 1.33em 1.33em;
}

.emj--2x {
  height: 2em;
  width: 2em;
  margin: 0 0.1em 0 0.2em;
  vertical-align: -0.2em;
  background-size: 2em 2em;
}

.emj--3x {
  height: 3em;
  width: 3em;
  margin: 0 0.15em 0 0.3em;
  vertical-align: -0.3em;
  background-size: 3em 3em;
}

.emj--4x {
  height: 4em;
  width: 4em;
  margin: 0 0.2em 0 0.4em;
  vertical-align: -0.4em;
  background-size: 4em 4em;
}

.emj--5x {
  height: 5em;
  width: 5em;
  margin: 0 0.25em 0 0.5em;
  vertical-align: -0.5em;
  background-size: 5em 5em;
}

.emj--monkey-face {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f435.svg");
}

.emj--red-heart {
  background-image: url("https://twemoji.maxcdn.com/2/svg/2764.svg");
}

.emj--hot-dog {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f32d.svg");
}

.emj--balloon {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f388.svg");
}

.emj--wrapped-gift {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f381.svg");
}

.emj--joystick {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f579.svg");
}

.emj--artist-palette {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a8.svg");
}

.emj--ferris-wheel {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a1.svg");
}

.emj--automobile {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f698.svg");
}

.emj--soap {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9fc.svg");
}

.emj--crayon {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f58d.svg");
}

.emj--money-bag {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b0.svg");
}

.emj--money-with-wings {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b8.svg");
}

.emj--wrench {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f527.svg");
}

.emj--castle {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f0.svg");
}

.emj--tropical-drink {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f379.svg");
}