.toast__container {
  width: 100%;
  position: relative;
  height: 0;
  z-index: 0;
  pointer-events: none;

  .toast {
    transform: translate3d(0, -100%, 0);
    visibility: hidden;
    box-shadow: 0 1px 2px 0 rgba(248, 105, 28, 0.25);

    &.active {
      visibility: visible;
      animation: toastSlide 3s;
    }
  }
}

.toast {
  background-color: $color-error;
  width: 66.66%;
  margin: 0 auto;
  color: $color-white;
  padding: 0.75rem;
  border-radius: 0 0 4px 4px;
  @include make-font($ff-label);
}

@media (max-width: $screen-large) {
  .toast {
    width: 100%;
    border-radius: 0;
  }
}

@keyframes toastSlide {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  20% {
    transform: translate3d(0, 0, 0);
  }
  80% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}