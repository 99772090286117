.slider {
  margin-top: 0.5rem;
  padding: 1rem 0;
  position: relative;
}

.slider__bar {
  background: $color-black-100;
  background-size: 200%;
  background-position-x: 80%;
  height: 4px;
  border-radius: 100px;
  width: 100%;
  position: relative;
}

.slider__input {
  border: none !important;
  height: 32px !important;
  margin: 0 !important;
  padding: 0 !important;
  width: calc(100% + 32px) !important;
  position: absolute !important;
  left: -16px !important;
  top: -14px !important;
  opacity: 0 !important;
  outline: none !important;
  box-shadow: none !important;

  &:hover {
    cursor: pointer;
  }
}

input[type=range] {
  &::-ms-tooltip {
    display: none;
  }

  &::-webkit-slider-thumb {
    height: 48px;
    width: 48px;
  }
}

.slider__point {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  transform: translate(-50%, -50%);
  background-color: $color-grey;
  border-radius: 100%;
  position: absolute;

  &.active {
    background-color: $color-theme
  }

  &:nth-child(1) {
    left: 20%
  }

  &:nth-child(2) {
    left: 40%
  }

  &:nth-child(3) {
    left: 60%
  }

  &:nth-child(4) {
    left: 80%
  }

  &:nth-child(5) {
    left: 100%
  }
}

.slider__drag {
  width: 22px;
  height: 22px;
  margin-top: 2px;
  left: 20%;
  transform: translate(-50%, -50%);
  background-color: $color-theme;
  box-shadow: 0 -2px 8px 0 scale-color($color-theme, $lightness: 10%) inset, 0 2px 8px -4px darken($color-theme, 10%);
  border-radius: 100%;
  position: absolute;

  .slider__drag-icon {
    width: 100%;
    height: 100%;
    fill: $color-white;
    padding: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}