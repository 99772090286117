//====================================================================================================
// Base Variables
// These values should just be the base values. Any variations should be done through equations
// if possible
//====================================================================================================

// Page Widths
// Variables are required for each width of the page at specific break points
$screen-x-small: 320px;
$screen-small: 544px;
$screen-medium: 812px;
$screen-large: 992px;
$screen-x-large: 1200px;
$screen-xx-large: 2560px;

// Fonts
$font-base: 16px;
$font-ratio: 1.2;

// Line Heights (fh)
$fh-heading: 1.2;
$fh-main: 1.45;

// Letter Spacing
$letter-spacing: 1px !default;

// Font Families (ff)
$ff-Montserrat: 'Montserrat', Arial, sans-serif;
$ff-Open-Sans: 'Open Sans', Arial, sans-serif;

$ff-heading: 700, $ff-Montserrat !default;
$ff-heading-extbold: 800, $ff-Montserrat !default;
$ff-heading-med: 600, $ff-Montserrat !default;
$ff-label: 600, $ff-Open-Sans !default;
$ff-main: 400, $ff-Open-Sans !default;
$ff-main-bold: 800, $ff-Open-Sans !default;

// Navigation Height
$navigation-height: 64px !default;
$logos-bar-height: 115px !default;
$contact-bar-height: 37px !default;

//===================================================

// Miscellaneous
$animation-speed: 0.3s;
$box-shadow: 0 1px 4px 0 rgba(93, 93, 93, 0.15);

//====================================================================================================
// Constructed variables (These variables should use the variables defined above in expressions)
//====================================================================================================

// Fonts
// Font-Colors (fc)
$fc-black: $color-black;
$fc-grey: $color-black-400;
$fc-white: $color-white;

// Font Sizes (fs)
$fs-l-5: 4.074rem;
$fs-l-4: 2.074rem;
$fs-l-3: 1.728rem;
$fs-l-2: 1.44rem;
$fs-l: 1.2rem;
$fs-m: 1rem;
$fs-s: 0.833rem;
$fs-s-2: 0.694rem;
$fs-s-3: 0.579rem;
