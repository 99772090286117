//====================================================================================================
// Mixins
// (Idea: Mixins will be prefixed with make/mixin to differentiate themselves from variables)
// (Usually mixins are used to make and include a body of styles so that's why make is the prefix)
//====================================================================================================

// Utilities
@mixin make-clearfix {
  content: "";
  display: table;
  clear: both;
}

//Makes a container scrollable, and includes '-webkit-overflow-scrolling' for native scroll behaviour on iOS devices
@mixin make-scrollable($direction: "", $value: auto) {
  @if ($direction == "") {
    overflow: $value;
  } @else {
    overflow-#{$direction}: $value;
  }
  -webkit-overflow-scrolling: touch;
}

// Fonts
@mixin make-font($font) {
  font-family: nth($font, 2);
  font-weight: nth($font, 1);
}

// Body
@mixin make-f-body--large($_color:"") {
  @if $_color != "" {
    color: $_color;
  } @else {
    color: $fc-black;
  }

  @include make-font($ff-main);
  line-height: $fh-main;
  font-size: $fs-l;
}

@mixin make-f-body--regular($_color:"", $_bold:false) {
  @if $_color != "" {
    color: $_color;
  } @else {
    color: $fc-black;
  }

  @if $_bold == true {
    @include make-font($ff-main-bold);
  } @else {
    @include make-font($ff-main);
  }

  font-size: $fs-m;
  line-height: $fh-main;
}

@mixin make-f-body--small($_color:"") {
  @if $_color != "" {
    color: $_color;
  } @else {
    color: $fc-black;
  }

  @include make-font($ff-main);
  font-size: $fs-s;
  line-height: $fh-main;
}

@mixin make-f-body--x-small($_color:"") {
  @if $_color != "" {
    color: $_color;
  } @else {
    color: $fc-black;
  }

  @include make-font($ff-main);
  font-size: $fs-s-2;
  line-height: $fh-main;
}

// Headings
@mixin make-f-heading($_type) {
  @if ($_type == 1) {
    @include make-font($ff-heading);
    font-size: $fs-l-4;
    letter-spacing: $letter-spacing;
    line-height: $fh-heading;
  } @else if ($_type == 2) {
    @include make-font($ff-heading);
    font-size: $fs-l-3;
    letter-spacing: $letter-spacing;
    line-height: $fh-heading;
  } @else if ($_type == 3) {
    @include make-font($ff-heading);
    font-size: $fs-l-2;
    letter-spacing: $letter-spacing;
    line-height: $fh-heading;
  } @else if ($_type == 4) {
    @include make-font($ff-heading);
    font-size: $fs-l;
    letter-spacing: $letter-spacing;
    line-height: $fh-heading;
  } @else if ($_type == 5) {
    @include make-font($ff-heading);
    font-size: $fs-m;
    letter-spacing: $letter-spacing;
    line-height: $fh-heading;
  } @else if ($_type == 6) {
    @include make-font($ff-heading);
    font-size: $fs-s;
    letter-spacing: $letter-spacing;
    line-height: $fh-heading;
  }
}

// Links
@mixin make-f-link($_color:"",$_hover_color:"",$_underline_color:"") {
  @if $_color != "" {
    color: $_color;
    &:hover {
      @if $_hover_color != "" {
        color: $_hover_color;
      }
      @if $_underline_color == "none" {
        text-decoration: none !important;
      } @else if $_underline_color != "" {
        text-decoration: underline;
        text-decoration-color: $_underline_color;
      } @else if $_hover_color != "" {
        text-decoration: underline;
        text-decoration-color: $_hover_color;
      } @else {
        text-decoration: underline;
        text-decoration-color: $_color;
      }
    }
  } @else {
    color: $link-color;
    &:hover {
      text-decoration: underline;
      text-decoration-color: $link-color;
    }
  }

  @include make-font($ff-main);
  font-size: $fs-m;
  line-height: $fh-main;
  transition: text-decoration-color 0.15s;
}

// Labels
@mixin make-f-label($_color:"", $_size:"") {
  @if $_color != "" {
    color: $_color;
  } @else {
    color: $color-black;
  }

  @if $_size != "" {
    font-size: $_size;
  } @else {
    font-size: $fs-m;
  }

  @include make-font($ff-label);
  line-height: $fh-main;
}

@mixin make-f-label--large($_color:"") {
  @if $_color != "" {
    color: $_color;
  } @else {
    color: $color-black;
  }

  @include make-font($ff-label);
  font-size: $fs-l;
  line-height: $fh-main;
}

// Fineprint
@mixin make-f-fineprint($_color:"") {
  @if $_color != "" {
    color: $_color;
  } @else {
    color: $color-black;
  }
  font-size: $fs-s-2;
  line-height: $fh-main;
}

// Components
@mixin make-nav-item {
  border-radius: 2px;
  color: $color-white;
  display: table-cell;
  float: left;
  @include make-font($ff-label);
  font-size: $fs-s;
  margin: 1rem 0;
  padding: 0.25rem 0.75rem;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}

// Filters
@mixin make-placeholder-color($_color) {
  /* do not group these rules */
  &::-webkit-input-placeholder {
    color: $_color;
  }
  &:-moz-placeholder {
    color: $_color;
  }
  &::-moz-placeholder {
    color: $_color;
  }
  &:-ms-input-placeholder {
    color: $_color;
  }
}

// Filters
@mixin make-fullscreen {
  /* do not group these rules */
  &:fullscreen {
    @content;
  }
  &:-webkit-full-screen {
    @content;
  }
  &:-moz-full-screen {
    @content;
  }
  &:-ms-fullscreen {
    @content;
  }
}

@mixin make-filter-input-icon-position() {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 16px;
  margin: auto;
  pointer-events: none;
}

@mixin make-filter-input($_border-rad:2px) {
  font: inherit;
  text-overflow: '';
  padding: 0 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 2.75rem;
  border-radius: $_border-rad;
  background-color: $color-white;
  border: solid 1px $color-black-100;
  @include make-placeholder-color($color-grey--darker);
  transition: border-color 0.15s;

  & + .icon {
    transition: 0.15s;
  }

  body:not(.not-hover) &:hover:not(:disabled) {
    border-color: $form-input-border-color;

    & + .icon {
      fill: $form-svg-color;
    }
  }

  &:disabled {
    color: $color-grey;
    background: $color-white;
    border-color: $color-black-50;
    opacity: 1;
    cursor: default;

    @include make-placeholder-color($color-grey);
  }

  &[hidden] {
    display: none;

    & + .icon {
      display: none;
    }
  }

  &:focus {
    border-color: $form-input-border-color;

    & + .icon {
      fill: $form-svg-color;
    }
  }
}

@mixin make-filter-select($_border-rad:2px) {
  @include make-filter-input($_border-rad);

  option[disabled] {
    display: none;
  }
}

// Flex

// Flexbox Containers
//
// The 'flex' value causes an element to generate a block-level flex
// container box.
//
// The 'inline-flex' value causes an element to generate a inline-level
// flex container box.
//
// display: flex | inline-flex
//
// http://w3.org/tr/css3-flexbox/#flex-containers
//
// (Placeholder selectors for each type, for those who rather @extend)
@mixin make-flexbox($imp:"") {
  display: -webkit-box unquote($imp);
  display: -webkit-flex unquote($imp);
  display: -moz-flex unquote($imp);
  display: -ms-flexbox unquote($imp);
  display: flex unquote($imp);
}

@mixin make-inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// Flexbox Direction
//
// The 'flex-direction' property specifies how flex items are placed in
// the flex container, by setting the direction of the flex container's
// main axis. This determines the direction that flex items are laid out in.
//
// Values: row | row-reverse | column | column-reverse
// Default: row
//
// http://w3.org/tr/css3-flexbox/#flex-direction-property
// TODO: Remove autoprefixer redundancy
@mixin make-flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

// Flexbox Wrap
//
// The 'flex-wrap' property controls whether the flex container is single-line
// or multi-line, and the direction of the cross-axis, which determines
// the direction new lines are stacked in.
//
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-wrap-property
@mixin make-flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

// Flexbox Grow
//
// The 'flex-grow' property sets the flex grow factor. Negative numbers
// are invalid.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#flex-grow-property

@mixin make-flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

// Flexbox Shrink
//
// The 'flex-shrink' property sets the flex shrink factor. Negative numbers
// are invalid.
//
// Default: 1
//
// http://w3.org/tr/css3-flexbox/#flex-shrink-property

@mixin make-flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// http://w3.org/tr/css3-flexbox/#flex-property
@mixin make-flex($fg: 1, $fs: null, $fb: null) {

  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

// Flexbox Justify Content
//
// The 'justify-content' property aligns flex items along the main axis
// of the current line of the flex container. This is done after any flexible
// lengths and any auto margins have been resolved. Typically it helps distribute
// extra free space leftover when either all the flex items on a line are
// inflexible, or are flexible but have reached their maximum size. It also
// exerts some control over the alignment of items when they overflow the line.
//
// Note: 'space-*' values not supported in older syntaxes.
//
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
//
// http://w3.org/tr/css3-flexbox/#justify-content-property
@mixin make-justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

// Flexbox Align Content
//
//The align-content property aligns a flex container's lines within the flex container
// when there is extra space in the cross-axis, similar to how justify-content aligns
// individual items within the main axis. Note that this property has no effect when the
// flexbox has only a single line.
//
//Values: flex-start | flex-end | center | space-between | space-around | stretch (default)
// Default: stretch
//
//https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
@mixin make-align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

// Flexbox Align Items
//
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.)
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property
@mixin make-align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin make-flex-order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Safari border-radius + overflow: hidden + CSS transform fix
// https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
@mixin make-overflow() {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}


@mixin autocx-fill-colour($color) {
  fill: autocx-fill-colour($color);
}