.popup :not(div hr) hr[id*="react_"] {
  height: 100px;
  width: 100px;
  background-color: red;
}


.carousel {
  position: relative;

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    pointer-events: auto;
  }
}

body:not(.not-hover) .carousel:hover {
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 1;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
  }
}

// Adds the container padding to the carousel so that the slides stick with the rest of the page's content
.container__inline-carousel {
  // 100% instead of 100vw as vw get the width including the scrollbar
  width: 100%;

  .swiper-container {
    padding: 0.25rem 2rem;

    .swiper-wrapper > .swiper-slide > :first-child {
      height: 100%;
    }
  }

  .empty__state {
    padding: 4.5rem 2.5rem;
    margin: 1rem 1rem 3rem;

    p {
      color: $color-grey--darker;
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
}

.carousel__overlay {
  @include make-justify-content(center);
  @include make-align-items(center);
  display: none;
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  padding: 0 1.5rem;

  * {
    &:not(.button) {
      color: $color-white;
    }

    text-align: center;
  }

  a {
    pointer-events: auto;
  }
}

.carousel__title {
  @include make-f-heading(1);
  width: 100%;
  font-size: 2.625rem;
}

.carousel__description {
  margin: auto;
  font-size: $fs-l;
  max-width: 34.5rem;
  color: $color-white;
}

.swiper-slide .picture[toggle-modal]:hover, .swiper-slide .picture > img[toggle-modal]:hover {
  cursor: pointer;
}

.compat-object-fit {
  height: 100%;
  background-size: cover;
  display: block;
  background-position: center center;

  .banner__image { // hide image if object fit is not supported - opacity to 0 for the link area
    opacity: 0;
  }
}

.carousel__image {
  height: 100%;

  &.swiper-slide {
    background-size: cover;
    background-position: center;

    .picture {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;

      a {
        cursor: pointer;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}

.carousel__video {
  cursor: pointer;
}

.banner__image {
  object-fit: cover;
  object-position: center;
  height: 100%;

  &--contain {
    object-fit: contain;
  }
}

#swiper-overflow-visible, .swiper-overflow-visible {
  overflow: visible;
}

.carousel__button-container {
  padding: 1rem 0;
}

.carousel__full-screen {
  position: absolute;
  @include make-flexbox();
  @include make-justify-content(center);
  bottom: 0;
  height: calc(50% - 20px);
  z-index: 2;
  width: 100%;
  pointer-events: none;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#bf000000', GradientType=0); /* IE6-9 */
}

.carousel__full-screen__action {
  color: $color-white;
  position: absolute;
  bottom: 1rem;
  padding: 0.5rem;
  text-align: center;
  pointer-events: auto;
  cursor: pointer;
}

.carousel--vehicle {
  .swiper-slide {
    height: 33.333vw;
    width: calc(50% - 2px);
  }

  @media (max-width: $screen-small) {
    .swiper-slide {
      height: 66.66vw;
      width: 100%;
    }
  }
}

//====================================================================================================
// Swiper Overrides
//====================================================================================================
.swiper-container {
  height: auto;
  width: 100%;
  display: block;

  &[type^=banner] {
    background-color: $color-grey--lighter;
    // height = ((clientWidth - xLargeScreenWidth) / (xxLargeScreenWidth - xLargeScreenWidth)) * (largeScreenWidth - minHeight) + minHeight;
    height: calc((100vw - 1200px) / (2560 - 1200) * (992 - 650) + 645px);

    &.standard-offer {
      height: 36.36vw;
    }

    &.legacy {
      height: 100%;
      min-height: 1px;
    }
  }

}

// Slides
.swiper-wrapper {
  @include make-flexbox();
  @include make-flex-direction(row);
}

.swiper-wrapper.disabled {
  transform: translate3d(0px, 0, 0) !important;
}

.swiper-slide {
  display: block;
  width: 60%; // Only applies this width if the swiper 'slidesPerView' is 'auto'
  height: auto;
  transition: opacity 0.3s !important;

  .card--inventory {
    max-width: 100%;
    height: 100%;
    margin: 0;
  }

  &-contact-cards {
    height: inherit;
  }
}

.banner-slide {
  opacity: 0;
}

//
.review-slide {
  width: 29%;

  &:not(:last-child) {
    margin-right: 2rem;
  }
}

img.swiper-slide {
  bottom: 1px; // Some images are 1px smaller than the rest. This prevents an ugly gap under the nav bar.
}

[type="tri-panel"] {
  cursor: default !important;
  opacity: 0;
  transition: opacity 0.3s;
}

[type="inventory-cards"] {
  overflow: visible;
  opacity: 0;
  transition: opacity 0.3s;

  .swiper-slide {
    width: 23%;
    margin-right: 32px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  background: rgba(37, 40, 42, 0.85);
  width: 40px;
  height: 40px;
  border-radius: 2px;
  padding: 0.5rem;
  fill: $color-white;
  opacity: 0;
  transition: all 0.3s;

  &--inverted {
    background: rgba(108, 118, 128, 0.75);
    fill: $color-white;
  }
}

.swiper-button-prev {
  left: 3rem;
}

.swiper-button-next {
  right: 3rem;
}

// Slide bullets
.carousel .swiper-pagination-bullets {
  left: 0;
  right: 0;
  margin: auto;
  max-width: 400px;

  > .swiper-pagination-bullet {
    margin: 0 3px;
    border-radius: 4px;
    height: 4px;
    opacity: 0.8;
    background: $color-grey;
  }

  > .swiper-pagination-bullet-active {
    background: $color-theme;
  }

  .carousel iframe {
    pointer-events: none;
  }
}

.swiper-pagination {
  z-index: 4;
}

#carousel_nav {
  z-index: 5;
}

// Carousel nav
.nav-carousel-container {
  position: absolute;
  height: 3rem;
  width: 100%;
  border-bottom: solid 1px $color-black-50;
  background-color: $color-white;
  z-index: 4;
  transform: translate3d(0, 0, 0);
  overflow: hidden;

  &.transition {
    transition: 0.5s;
  }

  &--black {
    background-color: $color-black-1000;
  }

  &--grey {
    background-color: $color-grey--light;
  }

  &--theme {
    background-color: darken($color-theme, 2.5%);

    .nav-swiper, .nav-swiper.active {
      color: $sub-nav-carousel-theme-item-color;
    }

    &--darker {
      background-color: darken($color-theme, 5%);
    }
  }

  &--yellow {
    background-color: $color-yellow;

    .nav-swiper, .nav-swiper.active {
      color: $color-black;
    }
  }

  &--large {
    height: 4rem;
  }
}

.nav-carousel-space {
  padding: 24px 0;

  &--large {
    padding: 32px 0;
  }
}

.nav-swiper {
  width: auto;

  &.active {
    color: $color-black;
    font-weight: 600;
  }
}

.nav-carousel {

  span, a {
    vertical-align: middle;
    line-height: 3rem;
    padding: 0 1rem;
    cursor: pointer;
    color: $color-grey--darker;

    &:hover {
      color: $sub-nav-carousel-hover-item-color;
    }

    &.swiper-slide.active {
      color: $color-black;
    }
  }

  &-container--theme {
    span, a {
      &:hover, &.nav-swiper.swiper-slide-active {
        color: $sub-nav-carousel-theme-hover-item-color;
      }
    }
  }

  .swiper-container {
    padding: 0 1rem;
    overflow: visible;
    cursor: default !important;
  }

  &--tabs {
    &--center {
      .swiper-wrapper {
        @include make-justify-content(center);
      }
    }

    span, a {
      line-height: 1.5rem;
      padding: 0.25rem 1rem;
      min-height: 3rem;
    }

    .nav-icon {
      line-height: 0;
      padding: 0.25rem 1rem;
    }

    .swiper-wrapper {
      vertical-align: middle;
    }

    .nav-swiper {
      border-radius: 4px;

      &.active {
        color: $color-theme;
        background-color: $color-white;
      }
    }

    &--white {
      .nav-swiper.active {
        background-color: $color-grey--light;
      }
    }
  }
}

.nav-carousel-container--black .nav-carousel {
  span {
    color: $color-grey;

    &.swiper-slide.active, &:hover {
      color: $color-white;
      background-color: none;
    }
  }
}

//====================================================================================================
// Media Queries
//====================================================================================================
@media (max-width: $screen-x-large) {
  .swiper-container {
    &[type^=banner] {
      height: 650px;

      &.standard-offer {
        height: 36.36vw;
      }

      &.legacy {
        height: 100%;
        min-height: 1px;
      }
    }
  }

  .container__inline-carousel {
    margin-left: 0;

    .swiper-container {
      padding: 0.25rem 2rem;
    }
  }
}

@media (max-width: $screen-large) {
  .carousel .swiper-pagination-bullets {
    max-width: 300px;
  }
  .carousel__nav-container {
    &.transition {
      transition: 0.5s;
    }

    &.slideDown {
      transform: translate3d(0, 0, 0);
    }

    &.slideUp {
      transform: translate3d(0, -81px, 0);
    }
  }

  .review-slide {
    width: 39%;
  }
  [type="inventory-cards"] {
    .swiper-slide {
      width: 36%;
    }
  }
}

@media (max-width: $screen-medium) {
  .swiper-container {
    &[type^=banner] {
      &.standard-offer {
        height: 100vw;
      }
    }
  }

  .carousel__overlay {
    * {
      text-align: left;
      margin: initial;
    }
  }
  .carousel .swiper-pagination-bullets {
    max-width: 200px;
  }
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }

  .carousel__image.swiper-slide {

    .picture--desktop--large {
      display: none;
    }

    .picture--desktop {
      display: none;
    }

    .picture--mobile {
      display: block;
    }
  }

  .review-slide {
    width: 69%;
  }

  [type="tri-panel"] {
    cursor: -webkit-grab !important;
  }

  .nav-carousel {
    .swiper-container {
      overflow: visible;
    }
  }

  .nav-carousel--tabs {
    .swiper-container {
      padding: 0 1rem;
    }
  }

  .nav-swiper {
    .swiper-slide:first-child {
      padding-left: 0;
    }
  }

  [type="inventory-cards"] {
    .swiper-slide {
      width: 58%;
    }
  }
}

@media (max-width: 650px) {
  .swiper-container {
    &[type^=banner] {
      height: 100vw;

      &.standard-offer {
        height: 100vw;
      }

      &.legacy {
        height: 100%;
        min-height: 1px;
      }
    }
  }
}

@media (max-width: $screen-small) {
  .container__inline-carousel {
    .swiper-container {
      padding: 0.25rem 1rem;
    }
  }

  .review-slide {
    width: calc(100% - 3rem);
  }

  .nav-carousel {
    padding: 0 0.5rem;
  }

  [type="inventory-cards"] {
    .swiper-slide {
      width: 88%;
    }
  }
}