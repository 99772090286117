//====================================================================================================
// Accordion
//====================================================================================================
.accordion {
  transform: translate3d(0, 0, 0);
  text-decoration: none;
  width: 100%;
}

.accordion__control {
  position: absolute;
  visibility: hidden;
  z-index: -1;
}

.accordion__switch {
  user-select: none;
  cursor: pointer;
  display: table;
  padding: 2rem 1rem;
  position: relative;
  table-layout: fixed;
  width: 100%;

  &--small {
    padding: 1.5rem 1rem;
  }

  &--xsmall {
    padding: 1rem;
  }

  &:active > .title,
  &:active > .indicator > svg,
  &:focus > .title,
  &:focus > .indicator > svg {
    color: $link-color;
    fill: $link-color;
  }

  body:not(.not-hover) &:hover > .title,
  body:not(.not-hover) &:hover > .indicator > svg {
    color: $link-color;
    fill: $link-color;
  }

  .title {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    transition: color 0.15s;
  }

  .indicator {
    display: table-cell;
    vertical-align: top;
    height: 24px;
    width: 24px;

    svg {
      transition: transform 0.3s, fill 0.15s;
      display: block;
      height: 20px;
      width: 20px;
    }

    svg:not(.icon) {
      fill: $color-theme;
    }

    &--background {
      vertical-align: middle;

      svg {
        border-radius: 4px;
        background-color: $color-theme-50;
        margin: auto;
        height: 24px;
        width: 24px;
        padding: 0 0.1rem;
      }
    }

    &--card {
      display: inline-block;

      svg {
        height: 12px;
        width: 12px;
        vertical-align: middle;
        margin-left: 0.5rem;
        margin-top: 0.4rem;
      }
    }

    &--small {
      height: 0.833rem;
      width: 1rem;
      vertical-align: middle;

      svg {
        transition: transform 0.3s, fill 0.15s;
        display: block;
        height: 0.833rem;
        width: 0.833rem;
      }
    }
  }

  &--card {
    padding: 0 0 1.5rem 0;
    transition: padding 0.15s linear;
    will-change: padding;
  }
}

.sub-label {
  position: absolute;

  &.fade-out {
    will-change: opacity;
    opacity: 0;
    transition: opacity 0.15s;
  }

  &.fade-in {
    will-change: opacity;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.accordion--white .title,
.accordion--white .indicator svg:not(.icon) {
  color: white;
  fill: white;
}

.accordion__content {
  transition: height 0.3s;
  height: 0;
  overflow: hidden;
}

// This particular layer is needed to attain the appropriate spacings (margin/padding) for the accordion
// Must be included as a direct child element of ".accordion__content"
.accordion__content-container {
  padding: 0 1rem;

  // This particular element is required to provide spacing at the bottom and register it as it seems some browsers
  // omit this bottom padding from the height
  &::after {
    content: '';
    display: block;
    height: 2.5rem;
  }

  &--small::after {
    height: 2rem;
  }
}

// Switch states
// Expanded
.accordion__control:checked + .accordion__switch > .indicator > svg:not(.icon-arrow) {
  transform: rotateZ(90deg);
  transition-timing-function: ease-out;
}

.accordion__control:checked + .accordion__switch > .indicator > .icon-arrow {
  transform: rotateZ(360deg);
  transition-timing-function: ease-out;
}

// Expand elements
.expand--list {
  .expand__control {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    width: 0;
    height: 0;
  }

  .expand__content {
    overflow: hidden;
  }

  .expand__label {
    height: 22px;

    &:hover {
      cursor: pointer;
    }
  }

  .expand__control:not(:checked) {
    & ~ .expand__content [hide="after"] ~ * {
      visibility: hidden;
      position: absolute;
      width: auto;
    }
  }

  .expand__control:checked {
    & ~ .expand__label {
      visibility: hidden;
      opacity: 0;
      height: 0;
      margin-top: 0;
      transition: all 0.3s;
    }

    & ~ .expand__content {
      transition: all 0.3s;
    }
  }
}