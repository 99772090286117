.mobile-contact-bar {
  background: $color-black-50;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  bottom: 0;
  width: 100%;
  display: none;
  padding: 0;
  position: fixed;
  z-index: 4;
  transform: translate3d(0px, 0px, 0px);
  transition: all 0.5s, opacity 0.3s !important;
  height: 70px !important;

  .button:not(:last-child) {
    border-right: 1px solid #bebfbf;
  }

  .button--footer {
    border-top: 1px solid $color-black-100;
    border-bottom: 1px solid $color-black-100;
    background-color: $color-black-50;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    height: 70px;
    line-height: 51px;
    letter-spacing: normal;
    // Fixes a safari transition bug
    z-index: 4;
    transform: translateZ(1px);
    transform-style: preserve-3d;

    & > * > .label--small {
      position: relative;
      top: -1rem;
      font-size: 0.8rem;
    }

    &:active {
      text-decoration: none;
      background-color: $color-white;
    }
  }
}

.call-options {
  position: absolute;
  z-index: 3;
  display: none;
  background-color: $color-white;
  width: 100%;
  padding: 1.5rem 0;
  border-radius: 0.5rem 0.5rem 0 0;
  transform: translate3d(0, -25%, 0) scaleY(0.25);
  pointer-events: none;
  visibility: hidden;
  // Give the opacity a much slower curve to allow the fade-out animation to play
  transition: transform 0.3s, visibility 0s linear 0.3s;
  will-change: opacity, transform, visibility;
}

.call-options__backdrop {
  display: none;
  position: fixed;
  z-index: 2;
  background-color: rgba($color-black, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s linear 0.3s;
  will-change: opacity, visibility;
}

@media (max-width: $screen-medium) {
  .call-options__backdrop, .call-options {
    display: block;
  }

  .call-options__backdrop.active {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    transition-delay: 0s;
  }

  .call-options.active {
    transform: translate3d(0, -100%, 0) scaleY(1);
    pointer-events: all;
    visibility: visible;
    transition-delay: 0s;
  }

  .mobile-contact-bar {
    @include make-flexbox();
    @include make-justify-content(space-between);
  }
}
@media (max-width: $screen-small) {
  .call-options .filter__dropdown {
    margin-bottom: 1rem;
  }
}

@media (max-width: $screen-x-small) {
  .mobile-contact-bar {
    .button--footer {
      & > * > .label--small {
        font-size: 0.6rem;
      }
    }
  }
}