.home-cards {
  .card__content {
    background: $color-white;
    border-radius: 4px;
    padding: 2.5rem 1.5rem;
    height: 100%;
  }
}

.image__container {
  height: 100%;
  margin-bottom: 1rem;

  & .GARVAN__charity__icon {
    width: 180%;
  }
}

@media (max-width: $screen-x-large) {
  .home-cards {
    .pat-element-pair > * {
      clear: both;
      width: 100%;
    }
    .pat-element-pair > *:last-child {
      margin: 1rem 0 0 0;
    }
  }
}

@media (max-width: $screen-medium) {
  .vertical__hr {
    border-left: none;
  }

  .u-align--center {
    &--not-mobile {
      text-align: left;
    }
  }
}
