.dealership__spinner {
  margin-left: 0.5rem;
  display: none;
}

.dealership-filters {
  padding: 0 1rem;
  @include make-clearfix;
  width: 100%;
}

.dealership-filter__section {
  @include make-inline-flex();
  float: left;

  /*&:last-child {
    float: right;
  }*/
}

.dealership-filter__item {
  @include make-flex(1 1 auto);
  @include make-inline-flex();
  @include make-align-items(center);

  &:not(:first-child) {
    margin-left: 1rem;
  }

  > .filter__title {
    margin-right: .5rem;
  }

  > .filter__label {
    margin-right: 1rem;
  }

  > .filter__input {
    width: 14.5rem;
  }
}

.dealership-card__detail {
  &:after {
    @include make-clearfix;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  &.no-margin {
    margin: 0;
  }
}

.dealership-card__icon {
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
}

.dealership-card__label {
  display: inline-block;
  padding-left: 0.25rem;
  width: calc(100% - 24px);

  .link {
    color: $card-link-color;
  }

  .table--borderless--no-padding {
    .table__row {
      color: $color-grey--dark;

      &.active {
        color: $color-black;
        @include make-font($ff-label);
      }
    }
  }
}

.dealership-time {
  span {
    float: left;
  }

  span + span {
    float: right;
  }
}

img + #dealership-pano {
  margin-top: 1rem;
}

.dealership-contact {
  .table__cell:first-child {
    @include make-f-label($fc-grey);
    width: calc(60px + 1rem);
  }

  .table__cell:last-child {
    width: 100%;
  }
}

#mapContainer {
  border-bottom: 1px solid $color-black-50;
}

#dealership-map {
  background-color: $color-grey--light;
  height: 200px;
  width: 100%;
  display: block;
}

#dealership-pano {
  margin-top: 1rem;
  width: 100%;
  background-color: $color-grey--lighter;
}

.enquiry-form {
  background-color: $color-white;
  border-radius: 2px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  display: block;
  padding: 1.5rem;

  .form__content {
    padding: 1.5rem;
  }
}

.mobile-contact-bar-space {
  display: none;
  padding: 35px 0;
}

.dealership-panel-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 1;
  padding: 1.5rem 2.5rem;
  min-height: 600px;

  .dealership-panel {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    background-color: $color-white;
    position: relative;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
    width: 334px;
    pointer-events: all;

    &-title {
      padding: 1rem;
      background-color: $form-header-color;
    }

    &-body {
      max-height: 662px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    &-section {
      padding: 1rem;
    }

    &-logo {
      width: calc(100% / 9);

    }

    &-logo--empty {
      padding-top: 71%;
    }
  }
}

@media (max-width: $screen-medium) {
  .dealership-filter__section {
    display: block;
    width: 100%;

    & + & {
      padding-top: 1rem;
    }
  }

  .dealership-filter__item {
    display: block;

    > .filter__label {
      display: inline-block;
      margin: 0 0 0.5rem 0;

      &.no-margin {
        margin: 0;
      }
    }

    > .filter__input {
      width: auto;
    }
  }

  .dealership-contact {
    .table__row:not(:first-child) {
      margin-top: 0.5rem;
    }

    .table__cell:first-child {
      display: block;
      width: 100%;
    }

    .table__cell:last-child {
      display: block;
      width: 100%;
    }
  }

  .dealership-form--desktop {
    display: none;
  }

  .mobile-contact-bar-space {
    display: block;
  }

  .dealership-panel-container {
    padding: 1rem;
    min-height: 0;

    .dealership-panel {
      margin: 0;
      width: 100%;

      &-body {
        max-height: 100%;
      }
    }
  }
}
