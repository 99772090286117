.stick-element {
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 4;
  transform: translateZ(0);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);


  &.transition {
    transition: transform 0.5s;
  }

  &-space {
    padding-top: 85px;
  }
}

@media (max-width: $screen-medium) {
  .stick-element {
    padding: 1rem 2rem;

    svg {
      right: 48px;
    }

    &-space {
      padding-top: 77px;
    }
  }
}

@media (max-width: $screen-small) {
  .stick-element {
    padding: 1rem 1.5rem;

    svg {
      right: 40px;
    }
  }
}