//====================================================================================================
// Sass Functions
//====================================================================================================

// To number function

//
@function util-pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

@function autocx-fill-colour($bg) {
  @if(lightness($bg) > 50) {
    @return #333333;
  } @else {
    @return #FFFFFF;
  }
}

