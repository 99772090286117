.VDP-enquiry-form--certified .VDP-enquiry-form {
  top: -176px;
}

.VDP-enquiry-form {
  position: absolute;
  top: -216px;
  max-height: calc(100vh - #{$navigation-height} - #{$logos-bar-height});
  @include make-scrollable();
  margin: auto;
  width: 100%;
  border-radius: 2px;
  background-color: $color-white;
  z-index: 2;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  display: block;

  .form__header {
    height: 128px;
    display: table;
    position: sticky;
    top: 0;
    z-index: 99;
    width: 100%;

    .form__header-container {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }

  .form__divider {
    margin: 0.5rem 0;
  }

}

// Makes the parent container relative for proper positioning of the VDP form
.VDP-enquiry-form__container {
  position: relative;
}

.VDP__title {
  width: calc(100% - 360px);

  &--color, &--color.icon-arrow {
    color: $header-VDP-title-color;
    fill: $header-VDP-title-color;
  }
}

.POA {
  text-transform: inherit;
}

.contact-section {
  padding: 1.5rem;

  &#callDealership {
    padding: 1rem 0;
  }
}

.contact-section__details:not(:first-child) {
  margin-top: 1rem;
}

.contact-section__title {
  padding-bottom: 1.5rem;
}

.contact-section__name {
  @include make-f-label($color-black-500);
}

.contact-section__address {
  margin-top: 1rem;
}

.contact-section__number {
  display: block;
  margin-top: 1rem;
}

.VDP__icon-rating {
  display: table;
  width: 100%;

  .icon-star {
    margin-right: 5px;
  }

  .icon {
    position: relative;
    height: 16px;
    right: 0;
  }
}

.contact-section__link {
  margin-top: 1rem;
}

.contact-section__map {
  width: 100%;
  margin-top: 1rem;

}

.vdp--sticky-space {
  padding-bottom: 2rem;
}

.finance-section {
  padding: 1.5rem;
}

@media (max-width: $screen-medium) {
  .subheader.u-only-mobile > .grid--fixed > [class*="col--"] {
    &:nth-child(1) {
      padding-right: 0;
    }

    &:nth-child(2) {
      padding-left: 0;
    }
  }

  .VDP__title {
    width: 100%;
  }
  .VDP-enquiry-form, .link--back--VDP {
    display: none;
  }
}

@media (max-width: $screen-small) {

  .subheader.u-only-mobile > .grid--fixed {
    padding: 0 8px;
  }
}

@media (max-width: $screen-x-small) {
  .subheader.u-only-mobile > .grid--fixed {
    padding: 0;
  }

  .subheader.u-only-mobile > .grid--fixed > .col--5 {
    padding: 0 12px 0 0;
  }
  .subheader.u-only-mobile > .grid--fixed > .col--5 > .label--small,
  .subheader.u-only-mobile > .grid--fixed > .col--7 > .label--small {
    font-size: 0.7rem;
  }
}

@media (max-width: 364px) {
  .button--livechat + .button {
    width: 64px;
    padding: 0;
  }
}

.newClass {
  display: block;
}