//====================================================================================================
// Fonts
//====================================================================================================
// Links
.link, [href^=tel] {
  @include make-f-link;
  user-select: none;
  text-decoration: none;
}

[href^=tel] {
  white-space: nowrap;
}

.link--spacing {
  margin-left: 0.5rem;
}

// States
.link:hover:not(.disabled):not(.footer__link):not(.link--no-underline), [href^=tel]:hover:not(.disabled, .button) {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: inherit;
}

// Variations
.link--small {
  font-size: $fs-s;
}

.link--no-underline:hover {
  text-decoration: none;
}

.link--icon {
  svg {
    margin-right: 0.5rem;
    margin-left: 0;
    vertical-align: middle;

    &.icon-arrow {
      margin-top: -2px;
      width: 14px;
      height: 14px;
    }

    &.icon--learn {
      margin-top: -2px;
      margin-right: 0.25rem;
    }

    &.icon--wrench {
      margin-top: -3px;
      margin-right: 0.25rem;
    }

    &.icon-location {
      margin-top: -3px;
      margin-right: 0;
    }
  }

}

.text--bold {
  @include make-f-body--regular("", true);
}

.text--semi-bold {
  font-weight: 600;
}

.link--icon:hover:not(.disabled):not(.footer__link) {
  text-decoration: none;

  span {
    text-decoration: underline;
  }
}

.link--white {
  color: $color-white;
}

.link--back {
  @include make-f-heading(6);
  @include make-inline-flex();
  @include make-align-items(center);
  color: $color-grey--darker;
  position: relative;
  left: -3px;

  .icon-arrow--back {
    margin-right: 0.25rem;
    fill: $color-grey--darker;
  }
}

.link--external {
  @include make-f-heading(6);
  @include make-inline-flex();
  @include make-align-items(center);
  color: $color-blue;
  position: relative;

  .icon-arrow--forward {
    margin-left: 0.25rem;
    fill: $color-blue;
  }
}

// Label
.label {
  @include make-f-label;

  &--hidden {
    margin: 0;
    height: 0;
    opacity: 0;
  }

  &__white {
    background-color: $color-white;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

// Line Heights
.leading-tight {
  line-height: 1.2 !important;
}

.leading-snug {
  line-height: 1.375 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

// Fineprint
.fineprint {
  @include make-f-fineprint($color-black-500);

  a {
    font-size: 0.694rem;
    line-height: 1.45;
  }

  &.link {
    color: #0397f5;
  }
}

// Fineprint
.fineprint--grey {
  @include make-f-fineprint($color-grey--darker);
}

b.fineprint--grey {
  @include make-f-fineprint($color-grey--darker);
}

.label--small {
  font-size: $fs-s;
}

.label--bold {
  @include make-f-heading(6);
  color: $color-grey--darker;
}

.label--medium {
  font-size: $fs-m;
}

.label--large {
  font-size: 1.1875rem;
}

.label--warning {
  color: $color-error;
}

h1 sub {
  font-size: $fs-m;
}

h3 sup {
  font-size: $fs-s;
}

.label--grey {
  color: $color-grey--darker !important;
}

.label--grey-light {
  color: $color-grey !important;
}

.label--grey-darker {
  color: $color-black-500 !important;
}

.label--green {
  color: $color-green !important;
}

.label--theme {
  color: $header-color !important;
}

.label--highlight-color {
  color: $color-theme !important;
}

.label--blue {
  color: $color-blue !important;
}

.label--orange {
  color: $color-orange !important;
}

.label--white {
  color: $color-white !important;
}

.label--brand {
  color: $color-theme !important;
}

// Headings
@for $i from 1 through 6 {
  h#{$i}, .h#{$i} {
    @include make-f-heading($i);
    color: $fc-black;
    text-transform: uppercase;
  }
}

// Text
.text--white {
  color: $color-white !important;
}

.text--black {
  color: $color-black !important;
}

.text--blue {
  color: $color-blue;
}

.text--shadow {
  text-shadow: 0 2px 4px $color-black-1000;
}

html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

sup {
  line-height: 0 !important;

  &.no-bold {
    font-weight: normal !important;
  }

  &.sup--small {
    font-size: 0.8rem;
  }

  &.sup--xs {
    font-size: 0.6rem;
  }
}

// Font Utilities
// Text Alignment
.f-align--left {
  text-align: left;
}

.f-align--right {
  text-align: right;
}