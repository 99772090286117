/*
 | \ | |                / ____|    (_)   | |
 |  \| | _____      __ | |  __ _ __ _  __| |
 | . ` |/ _ \ \ /\ / / | | |_ | '__| |/ _` |
 | |\  |  __/\ V  V /  | |__| | |  | | (_| |
 |_| \_|\___| \_/\_/    \_____|_|  |_|\__,_|

  Simplifies the overall grid system and adds more consistent
  spacing and positioning with flex! To be phased in slowly,
  replacing the old system.
*/
//=============================================================
/*
  Makes the margin for a grid based on a gutter size

  Only apply margin to the left and top of the grid.

  This is due to a property of negative margins where
  a bottom negative margin will drag adjacent elements
  upwards in the flow of the page.

  We can then space the gutters of the the grid
  appropriately using the left and right margins,
  while keeping it flush with the container it sits in.
 */
@mixin make-margins($g) {
  margin-top: -#{$g};
  margin-left: -#{$g};

  // Apply gutters
  & > [class^="col-"] {
    margin-top: $g;
    margin-left: $g;
  }
}

/*
  Makes column width based on the total number of columns,
  the gutter size, and the specified column size
 */
@mixin make-col-width($i, $g: 0, $c: 12) {
  @if $g != 0 {
    width: calc(#{(100% / $c) * $i} - #{$g});
  } @else {
    width: #{(100% / $c) * $i};
  }
}

/*
  Makes a grid with the specified gutters and columns

  Usage: @include make-grid(16px, 12px, 12);

  Parameters:
    $gutter-size         (default: 0)            - The size of the gutter
    $gutter-size-small   (default: $gutter-size) - The size of the gutter at a medium sized screen
    $cols                (default: 12)           - The number of columns in which to divide up the grid
 */
@mixin make-grid($gutter-size: 0, $gutter-size-small: $gutter-size, $cols: 12) {
  @include make-flexbox();
  @include make-flex-direction(row);
  @include make-flex-wrap(wrap);

  // Make sure the Grid always sits flush within its parent container
  position: relative;

  @if $gutter-size != 0 {
    @include make-margins($gutter-size);
  }

  // Set column width
  @for $i from 1 through $cols {
    & > .col-#{$i} {
      @include make-col-width($i, $gutter-size);
    }
  }

  // Default column width breakpoint
  @media (max-width: $screen-medium) {
    // Make all columns 100% width on small screens by default
    @for $i from 1 through $cols {
      & > .col-#{$i} {
        @include make-col-width(12, $gutter-size);
      }
    }
  }

  // Update the gutters if a different mobile size is provided
  @media (max-width: $screen-small) {
    // Make all columns 100% width on small screens by default
    @for $i from 1 through $cols {
      & > .col-#{$i} {
        @include make-col-width(12, $gutter-size-small);
      }
    }

    @include make-margins($gutter-size-small);
  }

  // Sets column width at the extra large screen breakpoint
  @media (max-width: $screen-x-large) {
    @for $i from 1 through $cols {
      & > .col-#{$i}-xl {
        @include make-col-width($i, $gutter-size);
        // Updates the gutter size on small screen breakpoints
        @media (max-width: $screen-small) {
          @include make-col-width($i, $gutter-size-small);
        }
      }
    }
  }

  // Sets column width at the large screen breakpoint
  @media (max-width: $screen-large) {
    @for $i from 1 through $cols {
      & > .col-#{$i}-l {
        @include make-col-width($i, $gutter-size);
        // Updates the gutter size on small screen breakpoints
        @media (max-width: $screen-small) {
          @include make-col-width($i, $gutter-size-small);
        }
      }
    }
  }

  // Sets column width at the medium screen breakpoint
  @media (max-width: $screen-medium) {
    @for $i from 1 through $cols {
      & > .col-#{$i}-m {
        @include make-col-width($i, $gutter-size);
        // Updates the gutter size on small screen breakpoints
        @media (max-width: $screen-small) {
          @include make-col-width($i, $gutter-size-small);
        }
      }
    }
  }

  // Sets column width at the small screen breakpoint
  @media (max-width: $screen-small) {
    @for $i from 1 through $cols {
      & > .col-#{$i}-s {
        @include make-col-width($i, $gutter-size-small);
      }
    }
  }
}

// Standard grid
.mortar-grid {
  @include make-grid(2rem, 1.5rem);
}

// Medium sized grid - usually used for content inside of the standard grid
.mortar-grid-medium {
  @include make-grid(1.5rem, 0.75rem);
}

// Extra small grid - usually used in forms
.mortar-grid-small {
  @include make-grid(0.75rem);
}

// Grid with no gutters
.mortar-grid-compact {
  @include make-grid();
}

// Grid
// Used as a wrapper container for when the grid is a sub element of the container
.grid {
  padding: 0 1rem;
  width: 100%;

  &::after {
    @include make-clearfix;
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--relative {
    position: relative;
  }
}

.grid__title {
  margin: 0.25rem 0 0.25rem;
}

.grid--cards {
  @include make-flexbox();
  @include make-flex-direction();
  @include make-flex-wrap(wrap);

  .grid__title {
    flex: 0 0 100%;
  }
}

.grid--flex {
  @include make-flexbox();
  @include make-flex-direction(row);
  @include make-flex-wrap(wrap);

  margin-top: -2rem;

  @for $i from 1 through 12 {
    & > .col--#{$i} {
      margin-top: 2rem;
    }
  }

  &:not(:first-child) {
    padding-top: 2rem;
  }
}

.grid--flex-reverse {
  @include make-flexbox();
  @include make-flex-direction(row-reverse);
  @include make-align-items(flex-start);
  @include make-flex-wrap(wrap);

  margin-top: -2rem;

  @for $i from 1 through 12 {
    & > .col--#{$i} {
      margin-top: 2rem;
    }
  }

  &:not(:first-child) {
    padding-top: 2rem;
  }
}

.grid--masonry {
  @include make-flexbox();
  @include make-flex-direction(column);
  @include make-flex-wrap(wrap);

  @for $i from 1 through 12 {
    & > .col--#{$i} {
      padding: 1rem;
    }
  }
}

.grid--form {
  @include make-flexbox();
  @include make-flex-direction(row);
  @include make-flex-wrap(wrap);

  padding: 0 1rem;
  margin-top: -0.5rem;

  @for $i from 1 through 12 {
    &.grid > .col--#{$i} {
      padding: 0 0.5rem;
      margin-top: 0.5rem;

      &.col--no-padding {
        padding: 0;
      }
    }
  }
}

.grid--accordion {
  padding: 0;
  margin-left: -1rem;
  width: calc(100% + 2rem);
}

.grid--dealer-card {
  .card--dealership-summary {
    margin-top: 5rem;
  }
}

// Loop for constructing the columns for the 12 column grid layout
@for $i from 1 through 12 {
  .grid > .col--#{$i} {
    display: block;
    float: left;
    padding: 0 1rem;

    &.col--no-padding {
      padding: 0;
    }

    &.col--grey {
      background-color: $color-grey--light;
    }

    &.col--center {
      float: none;
      margin: auto;
    }
  }
}

@for $i from 1 through 12 {
  .col--#{$i} {
    // Give 99.99% width to fix half pixel errors on IE and some webkit browsers
    width: calc((99.99% / 12) * #{$i});
  }
}

.col--fixed {
  position: absolute;
  right: 1rem;
}

.col--4.col--fixed {
  width: calc((100% / 12) * 4 - 11px);
}

.col--absolute {
  position: absolute;

  &--right {
    right: 2.5rem;
    top: 15%;

  }

  &--right-header {
    right: 0.5rem;
    top: 0;

    > img {
      height: 100%;
    }
  }
}

.col--relative {
  position: relative;
}


/* Media Queries */
@media (max-width: $screen-medium) {
  .grid, .grid--medium, .grid--form {
    padding: 0 1rem;
  }

  // Adds extra margin to the images to simulate a change from grid to separate blocks
  .grid__multi-content {
    .image__container {
      margin-top: 2rem;
    }
  }

  // This loop constructs the rule that columns in the grid will stretch to 100% on screens at
  // this width, thus stacking the columns (Becomes Rows)
  @for $i from 1 through 12 {
    .grid:not(.grid--fixed):not(.grid--medium):not(.grid--form) > .col--#{$i} {
      width: 100%;

      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }

  //Change col with on mobile
  @for $i from 1 through 12 {
    .grid > .m-col--#{$i} {
      // Give 99.99% width to fix half pixel errors on IE and some webkit browsers
      width: calc((99.99% / 12) * #{$i});
    }
  }

  .col--fixed {
    position: relative;
    right: 0;
  }

  .grid--accordion {
    padding: 0;
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
}

@media (max-width: $screen-small) {
  .grid {
    padding: 0 0.5rem;
  }

  @for $i from 1 through 12 {
    .grid:not(.grid--fixed):not(.grid--medium):not(.grid--form):not(.grid--small-half) > .col--#{$i} {
      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }

    .grid.grid--medium > .col--#{$i} {
      width: 100%;
    }

    .grid.grid--small-half > .col--#{$i} {
      width: 50%;
    }
  }

  .grid--form {
    padding: 0 1rem;

    @for $i from 1 through 12 {
      &.grid > .col--#{$i} {
        padding: 0 0.5rem;
        margin-top: 0.5rem;

        &.col--no-padding {
          padding: 0;
        }
      }
    }
  }

  .grid--flex {
    margin-top: -1.5rem;

    @for $i from 1 through 12 {
      & > .col--#{$i} {
        margin-top: 1.5rem;
      }
    }

    &:not(:first-child) {
      padding-top: 1.5rem;
    }
  }

  .grid__multi-content {
    .image__container {
      margin-top: 1.5rem;
    }
  }

  .grid.grid--accordion {
    padding: 0;
    width: 100%;
  }

  .grid--fixed {
    @for $i from 1 through 12 {
      & > .col--#{$i} {
        padding: 0 0.5rem;
      }
    }
  }
}