@import '../animations/filters';

.filters {
  margin: 0 auto;
  overflow-x: hidden;

  &--inline {
    width: auto;
    display: inline-block;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  &--modal {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &--modal-with-button {
    height: calc(100% - 76px);
    padding-bottom: 10rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.filter__heading {
  @include make-f-heading(3);
  color: $color-theme;
  text-align: center;
}

.filter__heading--horizontal {
  display: inline-block;
  margin: 0 1rem 0 0;
}

.filter__page-count {
  padding: 1rem 1rem 0;
}

.filter__offers {
  width: 12.5rem;
}

.filter__actions {
  @include make-clearfix;
  width: 100%;
  padding: 0 1.5rem 0.75rem;
}

.filter__link {
  color: $form-link-color;
  cursor: pointer;

  &[disabled] {
    color: $color-grey;
    cursor: pointer;
    pointer-events: none;
  }
}

.filter__actions-left {
  display: inline-block;
  float: left;
}

.filter__actions-right {
  display: inline-block;
  float: right;
}

.hidden__field {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 999px;
    opacity: 1;
  }
}

.filter__section {
  background-color: $color-white;
  padding: 1.5rem;
  border-bottom: 1px solid $color-black-50;
  position: relative;

  &:not(:first-child) {
    border-top: 1px solid $color-black-50;
  }

  & + & {
    margin-top: .5rem;
  }

  & + .filter__menu-item {
    border-top: none;
  }

  h4 {
    padding-bottom: 1.5rem;
  }

  &.no--border {
    border: none;
  }

  &.no-top--border {
    border-top: none;
    padding: 1.5rem 0;
  }

  &--inverted {
    background-color: $color-theme;
    color: $color-white;

    .filter__heading {
      color: inherit;
    }
  }

  &--no-padding {
    padding: 0;
  }

}

.filter__section--make {
  border-bottom: none;

  .filter__close {
    margin: 0 0 .5rem;
  }

  &.open {
    padding: 0 0 1rem;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .filter__dropdown {
    clear: right;
  }

  .filter__dropdown + .filter__link {
    margin-top: 1rem;
  }

  &:last-child:not(:first-child) {
    padding-top: 1rem;
  }

  &:not(:last-child):not(:first-child) {
    .filter__label {
      display: none;
    }
  }
}

.filter__subsection {
  padding-bottom: 1rem;
  overflow: auto;

  .filter__label {
    margin-bottom: 0.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.filter__close {
  float: right;
  margin-top: 4px;
}

.filter__remove {
  float: right;
  position: absolute;
  top: 14px;
  bottom: 0;
  right: 0;
}

.filter__label {
  @include make-f-label($color-grey--darker, inherit);

  .filter__section & {
    margin-bottom: .5rem;
  }

  &--centered {
    text-align: center;
  }

  &.no-margin {
    margin: 0;
  }
}

.filter__title {

  &--centered {
    text-align: center;
  }

  .filter__section & {
    margin-bottom: .5rem;
  }
}

.filter__buttons {
  user-select: none;
  display: inline-flex;
  width: 100%;

  &--transparent {
    background-color: transparent;
  }

  &--grey-darker {
    background-color: $color-grey--darker;

    label {
      background-color: $color-white;
    }
  }

  &--half-size {
    width: 50%;
  }

  .filter__button:first-of-type {
    border-radius: 4px 0 0 4px;
    margin-left: 0;
  }

  .filter__button {
    float: left;
    border-right-width: 0;
    transition: border-color 0.15s;
  }

  .filter__button:last-of-type {
    border-radius: 0 4px 4px 0;
    border-right-width: 1px;
  }

  &::after {
    @include make-clearfix;
  }
}

@mixin make-hover-radio() {
  cursor: pointer;
  border-color: $form-input-border-color;
  z-index: 2;
}

@mixin make-hover-radio-swatch() {
  cursor: pointer;
  box-shadow: 0 0 0 2px #0397f5;
  border-color: $color-white;
  z-index: 2;
}

@mixin make-active-radio() {
  border-color: $color-radio-border;
  background-color: $color-radio-active;
  z-index: 1;
}

@mixin make-active-radio-swatch() {
  box-shadow: 0 0 0 2px #0397f5;
  border-color: $color-white;
  z-index: 1;
}

.filter__radio {
  @include make-flexbox();

  input[type="radio"],
  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: absolute;
    visibility: hidden;
    padding: 0;
    margin: 0;

    &:checked + label {
      @include make-active-radio();

      &.swatch {
        @include make-active-radio-swatch();
      }
    }
  }

  &-center {
    @include make-justify-content(center);
  }

  label {
    border: 1px solid $color-black-100;
    padding: .6rem .5rem;
    position: relative;
    text-align: center;
    z-index: 0;

    &.white {
      background-color: $color-white;
    }

    &:hover {
      @include make-hover-radio();
    }

    .filter__title {
      @include make-f-body--regular();
      margin-bottom: 0;
    }

    &:first-of-type:not(.swatch) {
      border-radius: 2px 0 0 2px;
      margin-left: 0;
    }

    &:not(:first-of-type):not(.swatch) {
      margin-left: -1px;
    }

    &:last-of-type:not(.swatch) {
      border-radius: 0 2px 2px 0;
      border-right-width: 1px;
    }

    &.large {
      padding: .6rem 1.5rem;
    }
  }

  .swatch {
    width: 50px;
    height: 50px;
    border-radius: 2px;
    border: 4px solid $color-white;
    margin: 0.5rem;
    background-size: cover;
    box-shadow: 0 0 12px -4px rgba(black, 0.25);

    &:hover {
      @include make-hover-radio-swatch();
    }

    &--circle {
      border-radius: 100%;
    }
  }
}

.half__page {
  width: calc(50% - 0.5rem);
  float: left;

  & + & {
    float: right;

    &.filter__dropdown {
      margin: 0;
    }
  }
}

.filter__spinner {
  right: 0.75rem;
  position: absolute;
  display: none;

  .spinner {
    vertical-align: middle;
  }
}

.filter__button {
  text-align: center;
  border: 1px solid $color-black-100;
  background-color: $color-white;
  padding: .5rem 1rem;
  cursor: pointer;

  body:not(.not-hover) &:hover {
    border-color: $color-radio-border;

    & + .filter__button {
      border-left-color: $color-radio-border;
    }
  }

  &:active {
    border-color: $color-radio-border !important;
    background-color: darken($color-white, 3%) !important;

    & + .filter__button {
      border-left-color: $color-radio-border !important;
    }
  }

  &--active {
    border-color: $color-radio-border;
    background-color: $color-radio-active;

    & + .filter__button {
      border-left-color: $color-radio-border;
    }

    &:active {
      border-color: $color-radio-border !important;
      background-color: darken($color-radio-active, 2%) !important;

      & + .filter__button {
        border-left-color: $color-radio-border !important;
      }
    }
  }

  .filter__title {
    margin-bottom: 0;
  }
}

.filter__list, .filter__list--select-one {
  background-color: $color-white;
  user-select: none;
  width: 100%;
  padding-left: 0;
  border-radius: 4px;

  .filter__list-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 0;
  }

  .filter__list-item {
    border-bottom-width: 0;
  }

  .filter__list-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-width: 1px;
  }

  &--expand {
    text-align: center;
    margin-top: 1rem;

    svg {
      margin: 0 0.25rem;
      fill: $form-svg-color;
      vertical-align: middle;
    }
  }
}

.filter__label + .filter__list--select-one, .filter__label + textarea {
  margin-top: 0.5rem;
}

.filter__list--select-one {
  height: auto;

  .filter__list-item {
    @include make-flexbox();
    @include make-align-items(center);
  }
}

//give the last visible element in an expandable list the same style as the last one while still closed
.expand__control:not(:checked) {
  & ~ .expand__content .filter__list-item[hide="after"] {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-width: 1px;
  }
}

//Override default display property for flex items
.expand__control:checked {
  & ~ .expand__content [hide="after"] ~ *.filter__list-item {
    @include make-flexbox();
    @include make-align-items(center);
  }
}

.filter__badge {
  color: white;
  @include make-font($ff-label);
  line-height: 1.35;
  background: rgba(128, 147, 159, 0.6);
  border-radius: 16px;
  display: block;
  padding: 0.25rem 0.6rem;
  position: absolute;
  right: 0.75rem;
  -webkit-backdrop-filter: blur(10px);
  font-size: $fs-s;

  &--red {
    background-image: linear-gradient(257deg, #e0746a, #cf7669);
  }

  &--blue {
    background-image: linear-gradient(77deg, #73a6da, #71b2e4);
  }
}

.filter__list-item {
  position: relative;
  color: $color-grey--darker;
  padding: .5rem 1rem;
  min-height: 48px;
  display: table;
  width: 100%;
  border: 1px solid $color-black-100;
  cursor: pointer;

  body:not(.not-hover) &:hover {
    border-color: $form-input-border-color;

    & + .filter__list-item {
      border-top-color: $form-input-border-color;
    }
  }

  &:active {
    color: $color-black;
    border-color: $form-input-border-color !important;
    background-color: darken($color-white, 3%) !important;

    & + .filter__list-item {
      border-top-color: $form-input-border-color !important;
    }
  }

  &--active {
    color: $color-black;
    border-color: $color-radio-border;
    background-color: $color-radio-active;

    & + .filter__list-item {
      border-top-color: $color-radio-border;
    }

    &:active {
      color: $color-black;
      border-color: $filter-item-click-color !important;
      background-color: darken($color-radio-active, 2%) !important;

      & + .filter__list-item {
        border-top-color: $filter-item-click-color !important;
      }
    }
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }

  .filter__title {
    display: table-cell;
    margin-bottom: 0;
    width: 100%;
    vertical-align: middle;
  }

  .filter__count {
    display: table-cell;
    vertical-align: middle;
  }
}

.filter__color {
  border-radius: 2px;
  height: 32px;
  min-width: 32px;
  width: 32px;
  max-width: 32px;
  vertical-align: middle;

  &--white {
    background-color: white;
    border: 2px solid #c8c8c8;
  }

  &--black {
    background-color: #1e1e1e;
  }

  &--red {
    background-color: #f33e33;
  }

  &--blue {
    background-color: #89bcf8;
  }

  &--silver {
    background-image: linear-gradient(225deg, #dcdcdc, #e6e6e6 54%, #c8c8c8);
  }

  &--grey {
    background-color: #c8c8c8;
  }

  &--yellow {
    background-color: #f8e81c;
  }

  &--green {
    background-color: #b8e986;
  }

  &--orange {
    background-color: #ff9359;
  }

  &--purple {
    background-color: #9A7ED9;
  }

  & + .filter__title {
    padding: 0 1rem;
  }
}

.filter-mobile {
  &::after {
    content: ' ';
    display: inline-block;
    text-align: center;
    width: calc(100% - 8px);
    margin: 0 auto;
    height: 8px;
    opacity: 0.5;
    border-radius: 4px;
    -webkit-filter: blur(6px);
    filter: blur(6px);
    background-color: $color-button-black;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.filter__form-button {
  @include make-f-heading(6);
  user-select: none;
  transition: opacity 0.3s;
  background-color: $color-button-black;
  border-radius: 2px;
  color: $color-white;
  cursor: pointer;
  margin: 0 auto;
  opacity: 1;
  padding: 1rem;
  position: relative;
  text-align: center;

  &:active {
    background-color: $color-button-black--hover;

    &::after {
      display: none;
    }
  }

  &--grey {
    background-color: $color-black-200;

    &:active {
      background-color: darken($color-black-200, 2%);
    }

    &::after {
      background-color: $color-black-200;
    }
  }

  .icon {
    right: auto;

    & + * {
      margin-left: 2rem;
    }
  }
}

.filter__overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;

  &--grey {
    background-color: $color-grey--light;
  }

  &--bottom {
    top: auto;
    bottom: 0;
    z-index: 2;
  }
}

.filter__count {
  font-size: smaller;
}

.filter__input {
  input {
    @include make-filter-input();
  }
}

.filter__search {
  position: relative;

  input,
  select {
    @include make-filter-select();
    padding: 0 2.5rem 0 1rem;
  }
}

.filter__dropdown {
  position: relative;

  &[hidden] {
    display: none;
  }

  &--inline {
    display: inline-block;
  }

  & + & {
    margin-top: .5rem;
  }

  select {
    @include make-filter-select();
    padding: 0 3rem 0 0.75rem;

    option[disabled] {
      display: none;
    }

    &[disabled] + .icon--with-input {
      fill: $color-grey;
    }
  }

  &.no-margin {
    margin: 0;
  }

  &--width-100 {
    width: 100%;
  }
}

.filter__dropdown--preview {
  @include make-flexbox();
  @include make-flex-direction(row);
  @include make-flex-wrap(wrap);
  font-size: $fs-m;

  &--label {
    margin: 0.5rem 0;

    color: $color-grey--darker;
    width: 100%;
  }

  &--body {
    width: calc(100% - 70px);
    float: right;
  }

  &--block {
    margin-top: 0.5rem;
    background-color: $color-grey--light;
    border-radius: 4px;
    padding: 0.75rem 1rem;

    & b {
      font-weight: 600;
    }
  }

  [dealership-logo] {
    display: block;
    max-width: 200px;
  }
}

.filter__warning {
  color: $color-error;
}

.filter__details {
  position: relative;
  border-radius: 2px;
  background: $filter-title-bg-color;
  cursor: pointer;

  .filter__title {
    color: $filter-title-color;
    @if $filter-title-bg-color == default {
      width: calc(100% - 3rem);
    } @else {
      width: calc(100% - 2.5rem);
    }
    padding: 1rem;
  }

  .filter__label {
    margin-bottom: 1rem;
  }

  .icon.icon-circle-minus {
    @if $filter-title-bg-color == default {
      right: 0;
    } @else {
      right: 1rem;
    }
    pointer-events: auto;
  }

  @if $filter-title-bg-color != default {
    &:hover {
      background: $filter-title-hover-bg-color;

      .icon.icon-circle-minus {
        fill: $filter-title-hover-svg-color;
      }
    }
  }
}

.filter__selection {
  position: relative;
  width: calc(100% - 48px);
  border-radius: 2px;
  background-color: $color-grey--light;
  border: none;
  padding: 0 1rem;
  line-height: 3rem;
}

.filter__menu-item {
  user-select: none;
  @include make-f-body--regular();
  position: relative;
  background-color: $color-white;
  padding: 1.5rem;
  border-top: 1px solid $color-black-50;
  border-bottom: 1px solid $color-black-50;

  & + & {
    border-top: none;
  }

  .icon {
    @include make-filter-input-icon-position();
  }
}

.filter__rating {
  width: 100%;

  .icon-star {
    cursor: pointer;
    height: 64px;
    width: 18%;
    padding: 0;
    position: relative;
    margin: 0;
  }
}

.filter__stepper {
  display: table;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;

  .filter__stepper-button {
    position: relative;
    background-color: $color-black-200;
    width: 30%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;

    &[disabled] {
      opacity: .5;
    }

    &:not([disabled]) {
      cursor: pointer;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    .icon.icon-plus,
    .icon.icon-minus {
      left: 0;
      right: 0;
      position: relative;
    }

    &--finance {
      width: 44px;
      user-select: none;
      background-color: $color-grey--light;
      border: solid 1px $color-black-100;
      border-left: none;

      &:active {
        background-color: $color-grey;
      }

      svg {
        position: relative;
        bottom: inherit;
        top: inherit;
        right: inherit;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        fill: $color-black-400;
      }
    }
  }

  select {
    display: table-cell;
    @include make-filter-input(0);
    @include make-f-body--large($color-grey--darker);
    width: 100%;
  }

  &--right {
    input {
      border-radius: 2px 0 0 2px;
    }
  }
}

.filter__pagination {
  padding-top: 1.5rem;
  margin: 0 auto 5rem;
  width: calc(100% - 4rem);
  border-top: 1px solid $color-black-50;
  @include make-clearfix;

  .filter__stepper {
    position: relative;
    float: right;
    width: auto;

    .filter__stepper-button {
      border-radius: 2px;
      min-width: 48px;
      width: auto;
      vertical-align: middle;
    }

    .filter__stepper-center {
      display: table-cell;
      padding: 0 1rem;
      text-align: center;
    }

    span {
      vertical-align: middle;
      white-space: nowrap;
      font-size: inherit;

      &.prefix {
        text-align: right;
        padding-right: .5rem;
      }

      &.suffix {
        text-align: left;
        padding-left: .5rem;
      }
    }

    input {
      @include make-filter-input(4px);

      vertical-align: middle;
      display: inline-block;
      margin: 0;
      min-width: 54px;
      width: 54px;
      max-width: 54px;
      padding: .5rem;
      text-align: center;
      border-width: 2px;
    }
  }

  .icon-arrow {
    width: 18px;
    height: 18px;
    left: 0;
    right: 0;
    position: relative;
    top: 3px;
  }
}

.text__area {
  width: 100%;
  @include make-f-body--regular();
  border-radius: 2px;
  background-color: $color-white;
  border: solid 1px $color-black-100;
  padding: 0.75rem;
  box-shadow: none;
  -webkit-appearance: none;
  resize: none;
  display: block;
  white-space: pre-line;

  &:focus {
    border: solid 1px $form-input-border-color;
  }

  &-display--none {
    display: none;
  }

  &-resize {
    resize: vertical !important;
  }
}

.empty__state {
  border-radius: 4px;
}

[show_text_area] {
  cursor: pointer;
  width: 100%;

  &:hover {
    .icon-plus {
      fill: $color-theme;
    }

    h5 {
      color: $color-theme;
    }
  }

  &:active {
    opacity: 0.5;
  }
}

.filter__inventory-tool {
  @include make-flexbox();

  select, input, button {
    border: 1px solid transparent;
    border-radius: 0;
  }

  & > * {
    border-radius: 0;
    margin-right: 1px;

    &:first-child {
      border-radius: 100px 0 0 100px;

      select, input, button {
        border-radius: 100px 0 0 100px;
        padding-left: 20px;
      }
    }

    &:last-child {
      border-radius: 0 100px 100px 0;

      select, input, button {
        border-radius: 0 100px 100px 0;
        padding-right: 20px;
      }
    }
  }

}

@media (max-width: $screen-medium) {
  .filter__inventory-tool {
    display: block;

    & > * {
      border-radius: 0;
      width: 100%;
      margin: 0 0 1px 0;

      &:first-child {
        border-radius: 8px 8px 0 0;

        select, input, button {
          border-radius: 8px 8px 0 0;
        }
      }

      &:last-child {
        border-radius: 0 0 8px 8px;

        select, input, button {
          border-radius: 0 0 8px 8px;
        }
      }
    }

  }
}

@media (max-width: 624px) {
  .filters--inline {
    display: block;

    select {
      width: 100%;
      @include make-filter-input();
    }

    &:not(:first-of-type) {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  .half__page {
    width: 100%;
    float: none;
    margin-bottom: 1rem;

    & + &.filter__dropdown {
      margin-top: 0.5rem;
    }

    &:nth-child(2n) {
      float: none;
      margin-bottom: 0;
    }
  }

  .filters.pat-vertical-center {
    display: block;

    & + & {
      margin-top: 1rem;
    }

    .filter__dropdown.filter__offers,
    .filter__input.filter__offers {
      width: 100%;
    }

    .filter__heading--horizontal {
      margin: 0 0 0.5rem 0;
    }
  }
}

@media (max-width: $screen-small) {
  .filter__radio--swatches {
    display: inline-block;
    text-align: center;

    .swatch {
      display: inline-block;
    }
  }
}
