.inventory {
  @include make-flexbox();

  .spinner {
    display: none;
  }

  &.loading {
    .filter__pagination {
      display: none;
    }

    #inventory_cards {
      visibility: hidden;
    }

    .spinner {
      display: block;
    }
  }

  #inventory_cards {
    padding: 1rem 0;
  }

  .inventory__content {
    width: 100%;

    .link {
      margin-left: 1rem;
    }

    .filter__form-button {
      margin: 0 1rem 0 0;
      width: auto;
      display: inline-block;
      padding: 13px 1rem 12px;
    }

    .empty__state {
      padding: 4.5rem 2.5rem;
      margin: 1rem 1rem 3rem;

      p {
        color: $color-grey--darker;
        font-size: 1rem;
        margin-top: 1rem;
      }
    }
  }

  .filter__label {
    display: inline-block;
    margin-right: 1rem;
  }

  &__header {
    display: table;
    width: 100%;
    padding: 2.5rem 2rem 0;

    .count {
      color: $color-black-500;
    }

    .count-description {
      color: $color-grey--darker;
    }
  }

  &__header-left,
  &__header-right {
    display: table-cell;
    white-space: nowrap;
  }

  &__header-right {
    text-align: right;
  }

  &__button {
    &-stick,
    &-fixed {
      width: calc(100% - 4rem);
      margin-bottom: 2rem;
    }

    &-stick {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      bottom: auto;
      // Top is calculated in JS
    }

    &-fixed {
      position: fixed;
      left: 0;
      right: 0;
      z-index: 2;
      top: auto;
      bottom: calc(2.375rem + 1rem);
    }
  }
}

.filter-mobile {
  width: max-content !important;
  border-radius: 50px;
  margin-right: 1rem;

  span .icon {
    width: 20px;
    height: 20px;
    margin-left: 0.25rem;
  }
}

.inventory {
  &.filters--open {
    .accordion__switch {
      padding: 1.5rem;

      .icon {
        right: 0;
      }

      .icon-cross {
        position: relative;
      }

      .icon-arrow {
        width: 16px;
        height: 16px;
        position: relative;
      }
    }

    .filter__form-button {
      display: none;
    }

    .inventory__filters {
      padding: 0.5rem;
      width: 25%;
      background-image: linear-gradient(to left, $color-grey--light, $color-white);

      hr {
        margin: 0 1rem;
      }

      & > .accordion > .accordion__switch > .indicator,
      & > #close_filters > .accordion__switch > .indicator {
        position: relative;
      }
    }

    .inventory__content {
      width: 75%;
    }

    .card {
      @include make-flex(1 1 calc(33.3333% - 32px));
      max-width: calc(33.3333% - 32px);
    }

    .filters {
      padding-bottom: 0;
    }

    .filter__section {
      background-color: inherit;
      border: none;
      margin: 0;
      padding-top: .75rem;
      padding-bottom: .75rem;

      &:first-child {
        padding-top: 0;
      }
    }

    .filter__menu-item {
      display: none;
    }

    .filter__overlay {
      position: static;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      z-index: auto;
      background-color: inherit;
      padding-bottom: 1rem;
    }
  }
}

//====================================================================================================
// Media Queries
//====================================================================================================
@media (max-width: $screen-x-large) {
  .inventory {
    &.filters--open {
      .inventory__filters {
        width: 33.333%;
      }

      .inventory__content {
        width: 66.6667%;
      }

      .card {
        @include make-flex(1 1 calc(50% - 32px));
        max-width: calc(50% - 32px);
      }
    }

    .card {
      @include make-flex(1 1 calc(33.3333% - 32px));
      max-width: calc(33.3333% - 32px);
    }
  }
}

@media (max-width: $screen-large) {
  .inventory {
    .link {
      display: none;
    }
  }
}

@media (max-width: $screen-medium) {
  .inventory {
    .card {
      @include make-flex(1 1 calc(50% - 32px));
      max-width: calc(50% - 32px);
    }

    .inventory__content .filter__form-button,
    .link {
      display: none;
    }

    &__header-left {
      width: auto;
      white-space: nowrap;

      > * {
        margin-left: 0 !important;
      }
    }

    .inventory__content {
      .empty__state {
        padding: 2.5rem;
        margin: 1rem 0 2.5rem;
      }
    }

    &__header-right {
      width: 100%;
    }

    .filter__rating {
      max-width: 340px;
    }

    .filter__actions {
      padding: 0.75rem 1.5rem;
    }

    .filter__pagination {
      margin-bottom: 10.5rem;

      .filter__stepper {
        float: none;
        margin: 0 auto;
      }

      .filter__stepper-center {
        padding: 0 2.5rem;
      }
    }
  }
}

@media (max-width: $screen-small) {
  .inventory {
    display: block;

    .card {
      @include make-flex(1 1 calc(100%));
      max-width: 100%;
    }

    .empty__state {
      padding: 4.5rem 2.5rem;
      margin: 1rem 1rem 0;

      p {
        font-size: 1rem;
        margin-top: 1rem;
      }
    }

    .filter__label {
      display: block;
      margin-bottom: .5rem;
      width: 100%;
    }

    .filter__dropdown {
      width: 100%;
    }

    &__button {
      &-stick,
      &-fixed {
        width: calc(100% - 4rem);
      }
    }

    &__header {
      padding: 1.5rem 1.5rem 0;
    }

    &__header-left {
      padding-bottom: 1.5rem;
      font-size: larger;
    }

    &__header-left,
    &__header-right {
      display: block;
      width: 100%;
    }

    &__header-right {
      border-top: 1px solid #e0e5e8;
      text-align: left;
      padding-top: 1.5rem;

      > * {
        margin-left: 0 !important;
      }
    }

    .filter__pagination {
      width: calc(100% - 3rem);

      .filter__stepper {
        span {
          font-size: smaller;
        }
      }

      .filter__stepper-center {
        padding: 0 .5rem;
      }
    }
  }
}