.time-slots {
  @include make-flexbox();
  @include make-flex-wrap(wrap);

  .time-slot {
    border-radius: 4px;
    background-color: $color-white;
    border: 1px solid $color-black-100;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem 0.5rem 0.5rem 0;

    &.active {
      color: $color-black;
      border-color: $color-radio-border;
      background-color: $color-radio-active;
    }
  }
}