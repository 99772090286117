//====================================================================================================
// Normalise
//====================================================================================================

h1, h2, h3, h4, h5, h6 {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: normal;
  margin: 0;
}

a:-webkit-any-link {
  text-decoration: none;
}

@supports (text-decoration-color: transparent) {
  a:-webkit-any-link {
    text-decoration: none;
    text-decoration-color: transparent;
  }
}


ol, ul {
  list-style-type: none;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  margin: 0;
}

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

hr {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  border-style: solid;
  border-width: 0;
}

:focus {
  outline: none;
}

button {
  border: none;
}

svg {
  margin: 0;
}

textarea {
  resize: none;
}