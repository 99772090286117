.video-player {
  @include make-flexbox();
  @include make-align-items(center);
  @include make-justify-content(center);
  background-color: $color-black-800;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    z-index: 2
  }

  // Prevent the play button overlay and thumbnail stopping interaction with the default video player
  &.dirty {
    .video-player__overlay,
    .video-player__thumbnail{
      z-index: 1;
    }
  }
}

.video-player__thumbnail {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.video-player__overlay {
  @include make-flexbox();
  @include make-align-items(center);
  @include make-justify-content(center);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;

  & > .video-player__play-button {
    transition: transform 0.3s;
  }

  &:hover > .video-player__play-button {
    transform: scale(1.1);
  }
}

.video-player__play-button {
  background-color: rgba(37, 40, 42, 0.85);
  border-radius: 1000px;
  padding: 1.5rem;
  width: 6rem;
  height: 6rem;

  > .icon {
    transform: translateX(0.25rem);
  }
}