//====================================================================================================
// Stock Card
//====================================================================================================

.card {
  @include make-f-body--regular();
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid $color-black-50;
  border-radius: 5px;
  overflow: hidden;
  @include make-flexbox();
  @include make-flex-direction(column);
  margin: 1rem;
  text-decoration: none;
  width: 100%;
  background-color: $color-white;

  &--map {
    box-shadow: none;
    border: none;
    margin: 0;
    background-color: $color-white;

    [class*="col--"] > .card__image {
      border-radius: 0;
    }
  }

  &--basic {
    box-shadow: none;
    border: none;
    min-height: 200px;

    &--grey {
      background-color: $color-grey--light;
    }

    &--primary {
      background-color: $color-theme;

      svg, h2 {
        color: $color-white;
        fill: $color-white;
      }
    }
  }
}

.card--clickable {
  cursor: pointer;
  transition: border 0.2s, box-shadow 0.2s;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid $color-black-50;

  body:not(.not-hover) &:hover,
  &:active {
    border: 1px solid $card-hover-border-color;
    box-shadow: 0 0 6px 0 rgba($card-hover-shadow-base, 0.15), 0 2px 8px 0 lighten($card-hover-shadow-tint, 35%);
  }
}

.card--dealership-summary {
  margin: 0;
}

.card__section {
  &--gradient {
    /* Old browsers */
    background: rgb(255, 255, 255);
    /* FF3.6-15 */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(249, 249, 249, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(249, 249, 249, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(249, 249, 249, 1) 100%);
    /* IE6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f9f9f9', GradientType=1);
  }

  &--image {
    border-bottom: 2px solid #eee;
  }
}

.card__image {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 3px 3px 0 0;
  background-color: #ffffff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & > img {
    width: calc(100% + 1px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}

.card__badge {
  bottom: 0;
  position: absolute;
  margin: 0 0 .6rem 0.6rem;
}

.card__content {
  @include make-flexbox();
  @include make-flex-direction(column);
  @include make-flex-grow(1);
  padding: 1rem;
}

.card__footer {
  display: block;
  padding: 1rem;
}

.card__title {
  @include make-f-heading(4);
  letter-spacing: 0;
  color: $card-header-color;
}

.card__subtitle {
  margin-top: 0.25rem;
  @include make-f-label($color-grey--darker, $fs-s);
}

.card__description {
  margin-top: 0.5rem;
}

.card__price {
  color: $card-price-color;
  @include make-f-heading(4);
}

.card__price-type {
  @include make-f-body--small($color-grey--darker);
}

.card__body {
  @include make-f-body--regular($color-black);
}

.card__details {
  @include make-clearfix();
  margin-top: 1rem;
  width: 100%;
}

.card__detail-list {
  @include make-f-body--small();
  padding: 0 0.5rem 0 0;
  width: 50%;
  display: inline-block;
  float: left;

  .icon__detail-item {
    float: left;
  }

  .card__detail-list__text {
    float: left;
    width: calc(100% - 21px);
  }
}

.card__detail-item {
  @include make-clearfix();
  width: 100%;

  .icon-star {
    float: left;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }
}

//====================================================================================================
// Inventory Card
//====================================================================================================
.card--inventory {
  @include make-flex(0 0 calc(25% - 32px));
  max-width: calc(25% - 32px);

  .card__image {
    // 1.45:1 aspect ratio
    padding-top: 69%;
  }
}

//====================================================================================================
// PDF Card
//====================================================================================================
.card--pdf {
  @include make-f-body--regular();
  @include make-flex(0 0 calc(20% - 32px));
  max-width: calc(20% - 32px);

  .card__image {
    // 7:10 aspect ratio
    padding-top: 140%;
  }
}

//====================================================================================================
// Dealership Card
//====================================================================================================
.card--dealership {
  @include make-flex(0 0 calc((100% / 3) - 32px));
  float: left;
  max-width: calc((100% / 3) - 32px);

  .card__image {
    // 3:2 aspect ratio
    padding-top: 66.66%;
  }

  .dealership-card__logo {
    width: calc(100% / 9);

    &--empty {
      padding-top: 71%;
    }
  }
}

//====================================================================================================
// Offer Card
//====================================================================================================
.card--offer {
  @include make-f-body--large();
  @include make-flex(0 0 calc(33.33% - 32px));
  max-width: calc(33.33% - 32px);
  display: none;

  &.card--display {
    display: inline-block;
  }

  .card__image {
    // 5:4 aspect ratio
    padding-top: 80%;
  }

  &.card--carousel {
    max-width: none;
    margin: 0;
  }

  .card__content {
    border-top: 1px solid $color-black-50;
    padding: 1.5rem;

    .card__body {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 68px;
    }

    sup {
      vertical-align: baseline;
      position: relative;
      top: -0.4em;
    }
  }
}

//====================================================================================================
// Card Pair
//====================================================================================================
.card--pair {
  @include make-flexbox();
}

.card--pair > * {
  float: left;
  width: calc(50% - 1rem);
}

.card--pair > *:first-child {

}

.card--pair > *:last-child {
  margin-left: 2rem;
}

.card__canvas {
  overflow: hidden;
  padding: 1.5rem;
  position: relative;
  border-radius: 5px;
  background-color: $color-grey--lighter;

  .card__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .card__content {
    position: relative;
    background: $color-white;
    border-radius: 4px;
    padding: 2.5rem 1.5rem;
    height: 100%;
    z-index: 2;
  }
}

//====================================================================================================
// Resource Card
//====================================================================================================

.card--resource {
  @include make-flex(0 0 calc((100% / 3) - 32px));
  float: left;
  max-width: calc((100% / 3) - 32px);
  background: $color-white;

  .card__content {
    @include make-flex-direction(row);
    @include make-align-items(center);
  }
}

.card__icon {
  border-radius: 2px;
  color: $color-theme;
  background-color: $color-radio-active;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 40px;
  vertical-align: middle;
  text-transform: lowercase;
  font-size: 13px;
  margin-right: 1rem;
  display: inline-block;
  @include make-flex-grow(0);
  @include make-flex-shrink(0);
}

//====================================================================================================
// Media Queries
//====================================================================================================

@media (max-width: $screen-x-large) {
  .card--dealership {
    @include make-flex(0 0 calc(50% - 2rem));
    max-width: calc(50% - 2rem);
  }

  .card--offer {
    @include make-flex(1 1 calc(33.33% - 2rem));
    max-width: calc(33.33% - 2rem);
  }

  .card--pdf {
    @include make-flex(0 0 calc(25% - 2rem));
    max-width: calc(25% - 2rem);
  }

  .card--resource {
    @include make-flex(1 1 calc(33.33% - 2rem));
    max-width: calc(33.33% - 2rem);
  }
}

@media (max-width: $screen-large) {
  .card--inventory {
    @include make-flex(0 0 calc(50% - 2rem));
    max-width: calc(50% - 2rem);
  }
}

@media (max-width: $screen-medium) {
  .card--basic {
    max-width: 100%;
  }

  .card--offer, .card--resource, .card--article {
    @include make-flex(0 0 calc(50% - 2rem));
    max-width: calc(50% - 2rem);
  }

  .card--dealership {
    @include make-flex(0 0 calc(100% - 2rem));
    max-width: calc(100% - 2rem);
    margin: 1rem;
  }

  .card--pdf {
    @include make-flex(0 0 calc(33.33% - 2rem));
    max-width: 100%;
  }

  .card__filter {
    h3 {
      font-size: 1.1875rem;
      padding-bottom: 0.75rem;
      text-align: left;
    }
  }

  .card--pair {
    display: block;

    & > * {
      width: 100%;
    }

    & > *:last-child {
      margin: 2rem 0 0 0;
    }
  }
}

@media (max-width: $screen-small) {
  .card, .card--dealership, .card--offer, .card--pdf, .card--resource {
    @include make-flex(0 0 calc(100% - 2rem));
    max-width: calc(100% - 2rem);
    margin: 0.75rem 1rem;
  }

  .card {
    .card__price-type {
      @include make-f-body--regular($color-grey--darker);
    }

    .card__detail-list {
      @include make-f-body--regular();
    }

    &--map {
      margin: 0;
      max-width: 100%;
    }

    &--basic {
      max-width: 100%;
    }
  }

  .card--inventory {
    max-width: 100%;

    &:first-of-type {
      margin-top: 0.5rem;
    }
  }

  .card--offer {
    display: table !important;
  }

  .card--pdf {
    @include make-flex(0 0 calc(50% - 1.75rem));
    max-width: 100%;
    margin: 0.75rem 1rem 0.75rem 0.75rem;

    &:nth-child(2n) {
      margin: 0.75rem 0.75rem 0.75rem 1rem;
    }
  }
}