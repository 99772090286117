.u-pull--left {
  clear: both;
  float: left;
}

.u-pull--right {
  clear: both;
  float: right;
}

.u-stack--left {
  float: left;
}

.u-stack--right {
  float: right;
}

.u-align--center {
  text-align: center;
}

.u-align--left {
  text-align: left;
}

.u-align--right {
  text-align: right;
}

.u-white-space--nowrap {
  white-space: nowrap;
}

.u-align-y--top {
  vertical-align: top;
}

.u-align-y--middle {
  vertical-align: middle;
}

.u-align-y--bottom {
  vertical-align: bottom;
}

.u-no-bottom--padding {
  padding-bottom: 0 !important;
}

.u-position--relative {
  position: relative !important;
}

.u-position--absolute {
  position: absolute !important;
}

.u-height-100 {
  height: 100% !important;
}

.u-align-self--center {
  align-self: center;
}

.u-justify-content--left {
  @include make-justify-content(flex-start);
}

.u-justify-content--center {
  @include make-justify-content(center);
}

.u-justify-content--right {
  @include make-justify-content(flex-end);
}

.u-justify-content--space-between {
  @include make-justify-content(space-between);
}

.u-display-flex {
  @include make-flexbox(!important);
}

.u-display-inline {
  display: inline;
}

.u-flex-order-first {
  @include make-flex-order(-1);
}

.u-flex-direction-column {
  @include make-flex-direction(column);
}

.u-flex-wrap-wrap {
  @include make-flex-wrap(wrap);
}

.u-flex-shrink-0 {
  @include make-flex-shrink(0);
}

.u-flex-grow {
  @include make-flex-grow(1);
}

.u-align-y--flex-middle {
  @include make-flexbox(!important);
  @include make-align-items(center);
}

.u-align-items--flex-end {
  @include make-align-items(flex-end);
}

.u-align-items--center {
  @include make-align-items(center);
}

.u-clearfix:not(.u-justify-content--space-between):after {
  @include make-clearfix;
}

.u-hide-element {
  height: 1px;
  width: 1px;
  z-index: -100;
  position: fixed;
  opacity: 0;
}

.u-only-mobile {
  display: none !important;
}

.u-display-none {
  display: none !important;
}

.u-display-block {
  display: block !important;
}

.u-visibility-hidden {
  visibility: hidden;
}

.u-white-space--pre-line {
  white-space: pre-line !important;
}

.u-overflow-x {
  @include make-scrollable(x);
}

.u-overflow-y {
  @include make-scrollable(y);
}

.u-t-0 {
  top: 0;
}

.u-l-0 {
  left: 0;
}

.u-b-0 {
  bottom: 0;
}

.u-r-0 {
  right: 0;
}

.u-side-lines {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:before, &:after {
    content: '';
    border-top: 1px solid $color-grey--light;
    flex: 1 0 1.5rem;
  }

  &:before {
    margin: 0 1.5rem 0 0;
  }

  &:after {
    margin: 0 0 0 1.5rem;
  }
}

.u-clickable {
  cursor: pointer;
}

.u-w-full {
  width: 100% !important;
}

@media (max-width: $screen-small) {
  .u-mobile-full {
    width: 100vw !important;
    position: relative !important;
    left: 50% !important;
    right: 50% !important;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
  }
}

@media (max-width: $screen-medium) {
  .u-center-text--mobile {
    text-align: center;
  }

  .u-only-mobile {
    display: inherit !important;
  }

  .u-only-desktop {
    display: none !important;
  }
}

.u-pointer-events-none {
  pointer-events: none;
}