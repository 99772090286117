.button {
  user-select: none;

  letter-spacing: 1px;
  @include make-font($ff-heading);
  font-size: $fs-s;
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: $color-white;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;

  &:hover {
    cursor: pointer;
  }

  &--small {
    height: 32px;
    line-height: 32px;
  }

  &--tag {
    border-radius: 100px;
    @include make-font($ff-label);
    height: 28px;
    line-height: 28px;
    text-transform: initial;
    padding: 0 0.75rem;
    letter-spacing: inherit;
  }

  &--full {
    width: 100%;
  }

  &--theme {
    background-color: $color-button;
    color: $color-button-text;

    &:active {
      background-color: $color-button--hover;
      color: $color-button-text--hover;
    }

    body:not(.not-hover) &:hover {
      background-color: $color-button--hover;
      color: $color-button-text--hover;
    }

  }

  &--secondary {
    background-color: $color-button-secondary;
    color: $color-button-secondary-text;

    &:active {
      background-color: $color-button-secondary--hover;
    }

    body:not(.not-hover) &:hover {
      background-color: $color-button-secondary--hover;
    }
  }

  &--black {
    background-color: $color-button-black;
    color: $color-button-black-text;

    &:active {
      background-color: $color-button-black--hover;
    }

    body:not(.not-hover) &:hover {
      background-color: $color-button-black--hover;
    }
  }

  &--pink {
    background-color: $color-pink;
    color: $color-white;

    &:active {
      background-color: darken($color-pink, 5%);
    }

    body:not(.not-hover) &:hover {
      background-color: darken($color-pink, 5%);
    }
  }

  &--icon {
    width: auto;
    display: inline-block;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--right {
    float: right;
  }

  &--float {
    position: fixed;
    top: calc(100% - 5rem);
    z-index: 21;
    transform: translateX(-50%);
    left: 50%;
    min-width: 200px;
  }

  &--white {
    background-color: $color-white;
    color: $color-black-500;

    &:active {
      background-color: $color-black-50;
    }

    body:not(.not-hover) &:hover {
      background-color: $color-black-50;
    }
  }

  &--file {
    background-color: $color-white;
    color: $color-black-500;
    border: 1px solid $color-black-50;
    box-shadow: none;

    &:active {
      background-color: $color-black-50;
    }

    body:not(.not-hover) &:hover {
      background-color: $color-black-50;
    }
  }


  &--test-drive {
    background-color: #ffad3b;
    color: #333333;
  }
}

#secondary--btn > .form > form > fieldset > .form__submit > .button--theme {
  @extend .button--secondary;
}

#open_filters_desktop {
  @extend .button--theme;

  & svg {
    fill: $color-button-text;
  }
}

.button__container {
  padding: 0;

  &.no-top-padding {
    padding-top: 0;
  }
}

.button--mobile {
  display: none;
}

.button[scrolldowntohide] {
  transform: translate3d(0, -3rem, 0);

  &.slideOutDown {
    transform: translate3d(0, 0, 0) !important;
  }

  &.slideInUp {
    transform: translate3d(0, -3rem, 0) !important;
  }
}

@media (max-width: $screen-medium) {
  .button__mobile--full {
    width: 100%;
  }

  .inventory__button-fixed.no-footer-spacing {
    bottom: 0;
  }

  .button {
    &--tag {
      height: 32px;
      line-height: 32px;
    }
  }
}

@media (max-width: $screen-small) {
  .button {
    height: auto;
    white-space: inherit;
  }

  .button--mobile {
    display: block;
    position: fixed;
    bottom: 2rem;
    width: calc(100% - 4rem);
    margin-left: 2rem;
    transition: all 0.5s;
    z-index: 1;
  }

  .fade-in--up {
    animation: fadeInUp 0.5s forwards;
  }
  .fade-out--down {
    animation: fadeOutDown 0.5s forwards;
  }

  .button__container {
    padding-bottom: 4rem;
  }
}

@media (max-width: $screen-x-small) {
  .button {
    font-size: 0.7rem;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
