.icon {
  display: inline-block;
  fill: $color-grey--darker;

  &--inline {
    margin-left: 0.4rem;
    width: 17px;
    height: 17px;
  }

  &--middle {
    vertical-align: middle;
  }

  &--x-small {
    height: 1rem;
    width: 1rem;
  }

  &--small {
    height: 2rem;
    width: 2rem;
  }

  &--medium {
    height: 4rem;
    width: 4rem;
  }

  &--large {
    height: 6rem;
    margin: auto;
    display: block;

    & + label {
      margin-top: 1rem;
      text-align: center;
    }
  }

  &--with-input {
    @include make-filter-input-icon-position();
  }

  &--center {
    margin: 0 auto;
  }

  &.icon--social {
    fill: #d8d8d8;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    vertical-align: middle;

    &--white {
      fill: $color-white;
    }

    &.icon--social--mobile {
      width: 1.5625rem;
      height: 1.5625rem;
      position: relative;
      top: -1px;
    }
  }

  &--circle {
    width: 5rem;
    height: 5rem;
    padding: 1.25rem;
    object-fit: contain;
    border-radius: 100%;
    background-color: $color-grey--light;

    &-small {
      padding: 0.55rem;
      width: 32px;
      height: 32px;
      overflow: visible;

      & > h1 {
        font-size: 1rem;
        line-height: 0.8rem;
      }
    }

    &-medium {
      padding: 1rem;
      width: 64px;
      height: 64px;
      overflow: visible;

      & > h1 {
        font-size: 2rem;
        line-height: 2rem;
      }
    }

    &-large {
      padding: 2.5rem;
      width: 200px;
      height: 200px;
      overflow: visible;

      & > h1 {
        font-size: 7rem;
        line-height: 7.5rem;
      }
    }

    &-gradient {
      background: linear-gradient(227deg, #004e72, #00263e);
    }

    &-white {
      background: $color-white;
    }

    &-certified {
      background: $color-certified-blue;
    }

    &-theme {
      background: $icon-bg-color !important;

      path {
        fill: $icon-fill-color;
      }
    }
  }

  &--thumbs-up {
    width: 56px;
    height: 56px;
    transform: rotate(50deg);
    opacity: 0;
  }

  &--tick {
    width: 56px;
    height: 56px;
  }

  &--calculator {
    height: 24px;
    margin-right: 0.75rem;
  }

  &--info {
    height: 16px;
    width: 16px;
  }

  &--gear {
    width: 20px;
    height: 20px;
  }

  &--badge {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  &--card {
    height: 26px;
    fill: #aab7c4;
  }

  &--grey {
    fill: $fc-grey;
  }

  &--grey--darker {
    fill: $color-grey--dark;
  }

  &--white {
    fill: $color-white;
  }

  &--blue {
    fill: $color-blue;
  }

  &--bley {
    fill: $color-bley;
  }

  &--theme-color {
    fill: $card-icon-color;
  }

  &--theme {
    fill: $color-theme;
  }

  &--btn-color {
    fill: $color-button;
  }

  &--gold {
    fill: $color-gold;
  }

  &--black {
    fill: $color-black-500;
  }

  &--blue-certified {
    fill: $color-certified-blue;
  }

  &__text {
    @include make-flexbox();
    @include make-align-items(center);

    & > .header-sub {
      @include make-flex(1);
    }
  }

  &--play {
    width: 5rem;
    height: 5rem;
  }

  &--button--container {
    @include make-flexbox();
    margin: 1rem -0.5rem;

    .icon--button {
      width: 90px;
      background-color: $color-grey--light;
      border-radius: 4px;
      position: relative;
      display: inline-block;
      margin: 0 0.5rem;
      transition: background-color 0.15s;
      will-change: background-color;

      &--center {
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .icon {
          position: relative;
          top: 4px;
        }

        .icon--email {
          width: 18px;
          height: 18px;
          top: 2px;
        }
      }

      &:before {
        content: "";
        display: block;
        padding-top: 70%;
      }

      &:hover, &:active {
        background-color: darken($color-grey--light, 3%);
      }
    }
  }

  &--certified {
    height: 5rem;
    display: block;
  }
}

.icon-title--small {
  line-height: 32px;
}

.icon--footer {
  &.icon--mobile {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
  }

  &.icon--email {
    width: 17px;
    height: 15px;
  }

  &.icon--chat {
    width: 18px;
    height: 16px;
  }

  & + .label {
    text-transform: initial;
  }
}

svg {
  transform: translateZ(0);
}

// A fixed width on the ancap container to prevent stars from wrapping
.ancap--item {
  width: 96px;
}

.icon-funnel {
  width: 24px;
  height: 24px;
}

.icon-star {
  fill: transparent;
  stroke: $color-gold;
  stroke-width: 5px;
  height: 16px;
  width: 16px;
  margin-left: -1px;

  &--active {
    fill: $color-gold;
  }
}

.icon--wrench {
  width: 21px;
  height: 21px;
}

.icon--email {
  width: 14px;
  height: 14px;
}

.icon--person {
  width: 14px;
  height: 14px;
}

.icon--file {
  width: 1.5rem;
  height: 1.5rem;
}

.icon--hash {
  width: 15px !important;
  height: 15px !important;
}

.table__cell > .icon--thumbs-up, .table__cell > .icon--tick {
  margin-right: 1rem;
}

.icon-leaf {
  fill: $color-grey;
  height: 16px;
  width: 16px;

  &--active {
    fill: #7ed321;
  }
}

.icon-plus {
  height: 24px;
  width: 24px;

  &.vertical-align--top {
    vertical-align: top;
  }

  .form__textarea-expand & {
    float: left;
    margin-right: 0.75rem;
  }
}

.icon-cross {
  height: 19px;
  width: 19px;

  transform: rotate(45deg);

  &--right {
    float: right;
    display: inline;

  }
}

.icon-minus {
  height: 24px;
  width: 24px;
}

.icon-circle-minus {
  height: 24px;
  width: 24px;
}

.icon-search {
  width: 16px;
  height: 16px;
}

.icon-arrow {
  height: 16px;
  width: 16px;

  &--forward {
    transform: rotate(270deg);
  }

  &--back {
    transform: rotate(90deg);
  }
}

.icon-phone {
  width: 17px;
}

.icon-location {
  width: 22px;
  height: 22px;
  margin-bottom: 1px;
}

.icon--directions {
  width: 20px;
}

.icon--clock {
  width: 18px;
  margin-bottom: 3px;
}

.icon--globe {
  width: 18px;
  margin-bottom: 1px;
}

.icon-vehicle {
  width: 15px;
}

.icon-check--circle {
  width: 108px;
  height: 108px;
  padding: 2rem;
  border-radius: 100%;
  background: $color-green--light;

  svg {
    fill: $color-green;
    height: auto;
  }

  &.failed {
    background: $color-orange--light;

    svg {
      fill: $color-orange;
      height: auto;
    }
  }
}

.icon--chat {
  width: 24px;
  height: 24px;
  margin-top: -4px;
}

.icon-mobile {
  width: 24px;
  height: 24px;
  margin-top: -1px;
}

.icon--footer {
  width: 14px;
  height: 14px;
}

.icon__detail-item {
  height: 13px;
  width: 13px;
  margin: 2px 0.35rem 0 0;
  float: left;
  fill: $card-icon-color;
}

.stop {
  &--transparent {
    stop-color: transparent;
  }

  &--white {
    stop-color: $color-white;
  }

  &--grey {
    stop-color: $color-grey;
  }

  &--gold {
    stop-color: $color-gold;
  }
}

.FHF__icon {
  width: 60%;

  .FHF-1 {
    fill: #a1c4bc;
  }

  .FHF-2 {
    fill: #f47a20;
  }

  .FHF-3 {
    fill: #29387e;
  }
}

.HDF__charity__icon {
  .HDF-1, .HDF-6 {
    fill: #491d6d;
  }

  .HDF-1 {
    fill-rule: evenodd;
  }

  .HDF-2 {
    fill: #ffe000;
  }

  .HDF-3 {
    fill: #fff;
  }

  .HDF-4 {
    fill: #e40520;
  }

  .HDF-5 {
    fill: #f49800;
  }
}

.GARVAN__charity__icon {
  .Garvan-1 {
    fill: #003361;
  }

  .Garvan-2 {
    fill: #702281;
  }
}

.Isuzu-trucks-1 {
  fill: #e30813;
}

.Isuzu-trucks-2 {
  fill: #737173;
}

.Isuzu-utes-1 {
  fill: none;
}

.Isuzu-utes-2 {
  clip-path: url(#clip-path);
}

.Isuzu-utes-3 {
  fill: #e30613;
}

.Isuzu-utes-4 {
  fill: #747173;
}

.logo__container {
  text-align: center;
  display: block;
}

@media (max-width: $screen-medium) {
  .GARVAN__charity__icon {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
  .HDF__charity__icon {
    width: 100px;
    display: block;
    margin: 0 auto;
  }
  .FHF__icon {
    width: 85%;
  }

  .icon {
    &--thumbs-up {
      width: 48px;
      height: 48px;
    }

    &--tick {
      width: 48px;
      height: 48px;
    }
  }
  .icon--play {
    width: 2rem;
    height: 2rem;
  }
}
