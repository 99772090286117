//====================================================================================================
// Footer
//====================================================================================================
.footer {
  background: $footer-bg-color;
  display: table;
  overflow: hidden;
  position: relative;
  z-index: 5;
  width: 100%;
  margin-top: auto;

  hr {
    border-top: 1px solid $color-black-300;
  }

  &__block {
    width: 100%;
    display: inline-block;
  }

  &__suttons {
    @include make-flexbox();
    @include make-flex-direction(column);
    @include make-align-items(center);
    @include make-justify-content(center);
    padding: 2rem 0 5rem;
    text-align: center;

    .nav__title {
      display: block;
      width: 100%;

      .logo {
        margin: auto;
      }
    }

    .footer__copyright {
      color: $color-grey;
      font-size: $fs-s-2;
      vertical-align: middle;
      display: inline;

      & > a {
        color: $color-grey;
        font-size: $fs-s-2;
        vertical-align: middle;
      }
    }
  }

  .bullet-point {
    margin: 0 0.25rem;
    color: $color-grey;
    font-size: 0.694rem;
  }

  &__title {
    color: $footer-heading-color;
  }

  &__link {
    @include make-f-link($footer-link-color,$footer-link-hover-color,$footer-link-underline-color);
    font-size: $fs-s;
    width: 100%;
    display: block;
    padding: 8px 0 0;

    .footer__link-hover:hover:not(.disabled), [href^=tel]:hover:not(.disabled), .icon--social:hover + .footer__link-hover:not(.disabled) {
      cursor: pointer;
      text-decoration: if($footer-link-underline-color == 'none', none, underline);
    }

    &--social {
      width: auto;
    }
  }

  .footer__link--no-padding {
    padding: 0;
    width: inherit;
    display: inline;
  }

  .accordion__content-container {
    padding: 0 2.5rem;
  }

  .accordion__content-container::after {
    height: 3rem;
  }

  .accordion__content-container.social__container::after {
    height: 2.5rem;
  }

  .accordion .title {
    font-size: 1rem;
  }

  .accordion .indicator {
    height: 19px;
    width: 19px;
  }

  .accordion__switch {
    padding: 2rem 1.5rem;
  }

  &--mobile {
    display: none;

    .accordion__switch:hover {

      .title, svg {
        color: $footer-mobile-hover-title-color !important;
        fill: $footer-mobile-hover-title-color !important;
      }
    }
  }

  &__social {
    font-size: 1rem;
    padding: 2rem 2rem 2rem 1rem;
  }

  .autocx-logo {
    @include autocx-fill-colour($footer-bg-color);
  }
}

//====================================================================================================
// Media Queries
//====================================================================================================
@media (max-width: $screen-medium) {
  .footer {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: block;
    }

    & .suttons-logo {
      margin-top: 8px;
    }

    &__suttons {
      padding: 1.5rem 0 3rem;

      .footer__copyright {
        padding: 0 2rem;
        display: block;
      }
    }

    .bullet-point {
      display: none;
    }
  }
}

@media (max-width: $screen-small) {
  .footer {
    &__link {
      color: $color-grey;
      width: 100%;
      display: block;
      padding: 0.5rem 0;

      &:first-of-type {
        margin-top: -0.5rem;
      }
    }

    & .suttons-logo {
      margin-top: 8px;
    }

    &__suttons {
      padding: 1.5rem 0 0 0;

      .footer__copyright {
        padding: 0 2rem;
      }
    }
  }
}