.time-line {
  padding-left: 0.5rem;
}

.time-line ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0
}

.time-line ul li {
  margin: 0.5rem;
  position: relative;
  padding: 0 1rem;
}

.time-line ul li > span {
  content: "";
  display: block;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  svg {
    position: absolute;
    z-index: 1;
    left: -0.8rem;
    top: 0.2rem;
  }

}

.time-line ul li > span:before {
  content: "";
  display: block;
  height: 100%;
  border: 2px solid lighten($color-theme, 25%);
  position: absolute;
  left: -0.25rem;
  top: 0.75rem;
}

.time-line ul li > span:after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: $color-white;
  border: 2px solid lighten($color-theme, 25%);
  position: absolute;
  left: -0.75rem;
  top: 0;
}

.time-line ul li:first-child, .time-line ul li:last-child {
  & > .stop {
    @include make-font($ff-main-bold);
  }
}

.time-line:not(.steps) ul li:first-child > span:after {
  border: 6px solid $color-theme;
  left: -1rem;
}

.time-line ul li:last-child > span:before {
  display: none;
}

.time-line:not(.steps) ul li:last-child > span:after {
  background: $color-theme;
  border: 6px solid $color-theme;
  left: -1rem;
}

.time-line.time-line--white {
  & ul li > span:before {
    border: 2px solid lighten($color-white, 25%);
  }

  & ul li > span:after {
    border: 2px solid lighten($color-white, 25%);
  }

  & ul li > .stop {
    color: $color-white;
  }

  & ul li:first-child, & ul li:last-child {
    & > .stop {
      @include make-font($ff-main-bold);
    }
  }

  &:not(.steps) ul li:first-child > span:after {
    border: 6px solid $color-white;
  }

  &:not(.steps) ul li:last-child > span:after {
    background: $color-white;
    border: 6px solid $color-white;
  }
}

.section--black .time-line.time-line--white ul li > span:after {
  background: $color-black;
}

.section--theme-color .time-line.time-line--white ul li > span:after {
  background: $color-theme;
}

// Steps timeline

.time-line.steps {
  margin-top: 2rem;
}

.time-line.steps ul li {
  padding: 0.3rem 1rem 1.2rem 1.75rem;
}

.time-line.steps ul li > span:after {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: $color-theme;
  border: 2px solid $color-theme;
  position: absolute;
  left: -1.15rem;
  top: 0;
}

.time-line.steps ul li > span {
  content: "";
  display: block;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  div {
    position: absolute;
    z-index: 1;
    top: 0.23rem;
    left: -0.40rem;
    color: #fff;
    @include make-font($ff-main-bold);
  }

  svg {
    position: absolute;
    z-index: 1;
    left: -0.63rem;
    top: 0.5rem;
  }
}

.time-line.steps ul li > div {
  @include make-font($ff-heading-med);
}

.time-line.steps ul li > span:before {
  border-color: rgba($color-theme, 0.5);
}