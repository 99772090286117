.feature-card {
  border-radius: 4px;
  overflow: hidden;
  height: calc(100% - 0.25rem);
  position: relative;
  float: left;
  z-index: 1;

  [onclick] {
    cursor: pointer;
  }

  .feature-card-map {
    padding: 0.1rem;
    height: 100%;

    #dealership-map {
      height: 100%;
    }
  }

  &.feature-card__poster {
    @include make-flexbox();
    @include make-align-items(center);

    .feature-card__content {
      width: 100%;
      height: 100%;
    }
  }

  &.grid, &.grid > a {
    & > {
      padding: 0;
    }

    .feature-card__content {
      padding: 1.5rem;
    }

    .feature-card__video {
      padding: 0;
    }
  }

  &--box-shadow {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  }
}

.feature-card__image {
  @include make-flex-order(1);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.feature-card__content {
  z-index: 2;
  @include make-flex(1);
  @include make-flex-order(2);

  p {
    margin-top: 1rem;
    white-space: pre-line;
  }

  .link--uppercase {
    letter-spacing: 1px;
    font-family: Montserrat,Arial,sans-serif !important;
    font-weight: 700 !important;
    font-size: .833rem !important;
    text-transform: uppercase;
    text-decoration: none !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// Styles for horizontal cards
.feature-card--horizontal {
  @include make-flexbox();

  .feature-card__link {
    padding: 0;
    margin-top: 1rem;

    a:not(.button) {
      position: relative;
      padding: 1rem 0;
      bottom: inherit;
      letter-spacing: 1px;
      color: $color-blue;
      @include make-font($ff-heading);
      font-size: $fs-s;
      text-transform: uppercase;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .feature-card__image {
    min-height: 342px;
    background-size: 100% auto;

    &--cover {
      background-size: cover;
      min-height: 415px;
    }

    &--right {
      @include make-flex-order(3);
    }
  }

  &.feature-card--homepage .feature-card__image {
    min-height: 342px;
  }

  .feature-card__video {
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .feature-card__image--large {
    min-height: 425px;
  }

  .feature-card__image--medium {
    min-height: 342px;
  }

  .feature-card__image--small {
    min-height: 258px;
  }

  .feature-card__image--no-min-height {
    min-height: 0px;
  }
}

.feature-card--vertical {
  .feature-card__image {
    padding-top: 65%;
  }

  &.feature-card--homepage .feature-card__image {
    padding-top: 52%
  }
}

.feature-card__float {
  overflow: visible;
  width: calc(100% - 4rem);
  min-height: 256px;

  .feature-card__image {
    background-size: cover;
    background-position: center;
    height: 224px;
    position: absolute;
    top: 2rem;
    width: 50%;
    z-index: 3;

    &--clip {
      top: 1rem;
    }
  }

  .feature-card__content {
    float: right;
    text-align: left;
    width: 100%;
    position: relative;
    height: 100%;

    .feature-card__title {}

    .feature-card__description {

      p:first-of-type {
        margin-top: 0;
      }
    }
  }

  &--right {
    margin-right: 4rem;

    .feature-card__content {
      padding: 4rem calc(50% - 2rem) 4rem 2rem;
    }

    .feature-card__image {
      right: -4rem;
    }

    .image__clip {
      -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
      clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
    }
  }

  &--left {
    margin-left: 4rem;

    .feature-card__content {
      padding: 4rem 2rem 4rem calc(50% - 2rem);
    }

    .feature-card__image {
      left: -4rem;
    }

    .image__clip {
      -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
      clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
    }
  }

  &--top {
    margin-top: 4rem;
    width: 100%;

    .feature-card__content {
      padding: 50% 2rem 2rem 2rem;
    }

    .feature-card__image {
      top: -4rem;
      width: calc(100% - 10rem);
      margin-left: 2rem;
      height: 280px;
      max-width: 240px;
    }

    .image__clip {
      -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
      clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
    }
  }
}

section:nth-of-type(even):not(.section--grey-darker):not(.section--theme-color--darker):not(.section--theme-color):not(.section--grey-gradient):not(.section--certified):not(.section--bley-gradient):not(.section--white) {
  .feature-card__float {
    .feature-card__content {
      background: $color-white;
    }
  }
}

.feature-card__video {
  &.video--display {
    display: block;
    height: 100%;

    .video {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

.feature-card__overlay {
  background-position: center;
  background-size: cover;

  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.75;
    top: 0;
    z-index: 1;
    background-color: rgba($color-black-500, 0.8);
  }

  &.feature-card__background {
    &--theme::after {
      background-color: $color-theme;
    }
  }
}

.feature-card__link {
  padding: 1.5rem 0;

  a:not(.button) {
    position: absolute;
    bottom: 1rem;
    padding: 0.5rem 0;
    letter-spacing: 1px;
    color: $color-blue;
    @include make-font($ff-heading);
    font-size: $fs-s;
    text-transform: uppercase;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.feature-card__background {
  &--white {
    background-color: $color-white;
  }

  &--grey {
    background-color: $color-grey--light;
  }

  &--theme-50 {
    background-color: $color-theme-50;
  }

  &--grey-darker {
    background-color: $color-black-500;

    .view-more.active:after {
      background: linear-gradient(rgba(255, 255, 255, 0) 80%, $color-black-500);
    }
  }

  &--theme {
    background-color: $color-theme;

    .view-more.active:after {
      background: linear-gradient(rgba(255, 255, 255, 0) 80%, $color-theme) !important;
    }
  }
}

//====================================================================================================
// Media Queries
//====================================================================================================

@media (max-width: $screen-x-large) {
  .feature-card--vertical {
    .feature-card__image {
      &.image--cover {
        height: 18vw;
      }
    }
  }

  .feature-card__float {
    min-height: calc(18vw + 2rem);

    .feature-card__image {
      height: 18vw;
    }

    &--top {
      .feature-card__image {
        height: 23vw;
        width: calc(100% - 8rem);
      }
    }
  }
}

@media (max-width: $screen-medium) {
  .feature-card {
    & > {
      padding: 0;
    }

    .feature-card__image + .feature-card__content, .feature-card__video + .feature-card__content {
      padding: 0 2rem 2rem;

    }

    .feature-card-map {
      height: 280px;
    }
  }

  .feature-card__link {
    padding: 0;
    margin-top: 1rem;

    a:not(.button) {
      position: relative;
      padding: 1rem 0;
      bottom: inherit;
    }

    &--carousel {
      margin-top: 2rem;

      a:not(.button) {
        padding: 0;
        position: absolute;
        bottom: 2rem;
      }
    }

  }

  .feature-card--vertical {
    .feature-card__image {
      &.image--cover {
        height: 38vw;
      }
    }
  }

  .feature-card--horizontal {
    display: block;

    .feature-card__image {
      padding-top: 52%;
      height: 0;
      min-height: 0;
    }

    .feature-card__video {
      height: unset;

      video {
        width: 100%;
        height: unset;
      }
    }

    &.feature-card--homepage .feature-card__image {
      padding-top: 52%;
      min-height: auto;
    }
  }

  .feature-card__float {
    margin-left: 0;
    width: 100%;
    min-height: 100px;
    padding-top: calc(42vw - 6rem);

    .feature-card__image {
      background-size: cover;
      background-position: center;
      height: calc(42vw - 4rem);
      left: 2rem;
      top: 0;
      width: calc(100% - 4rem);
    }

    .feature-card__content {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 3rem 2rem 2rem;
    }

    &--top {
      margin-top: 0;

      .feature-card__image {
        left: -1rem;
        width: calc(100% - 2rem);
      }

    }
  }
}

@media (max-width: $screen-small) {
  .feature-card--vertical {
    .feature-card__image {
      &.image--cover {
        height: 60vw;
      }
    }
  }

  .feature-card {
    &.grid {
      .feature-card__content {
        padding: 1.5rem;
      }

      .feature-card__image + .feature-card__content, .feature-card__video + .feature-card__content {
        padding: 0 1.5rem 1.5rem;

      }
    }

    .feature-card-map {
      #dealership-map {
        height: 280px;
      }
    }
  }

  .feature-card__float {
    margin-left: 0;
    width: 100%;
    padding-top: calc(30vw - 2rem);

    .feature-card__image {
      background-size: cover;
      background-position: center;
      height: 50vw;
      top: 0;
      width: 53vw;
      left: 1.5rem;
    }

    .feature-card__content {
      width: 100%;
      height: 100%;
      position: relative;
      padding: calc(20vw + 3rem) 1.5rem 1.5rem;
    }

    &--top {
      margin-top: 1rem;

      .feature-card__image {
        width: calc(100% - 20vw);
        left: 0;
      }
    }

  }

  .feature-card__link {

    &--carousel {
      a:not(.button) {
        bottom: 1.5rem;
      }
    }
  }
}