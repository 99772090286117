.tooltip {
  position: relative;

  &:hover {
    .tooltiptext {
      visibility: visible;
    }

    &:before {
      content: "";
      position: absolute;
      top: -8px;
      left: 0;
      border: 0.5rem solid;
      border-color: $color-black-500 transparent transparent transparent;
    }
  }

  .tooltiptext {
    visibility: hidden;
    background-color: $color-black-500;
    font-size: 0.875rem;
    padding: 1rem;
    position: absolute;
    z-index: 2;
    bottom: 100%;
    text-align: center;
    width: 100%;
    min-width: 256px;
    left: 50%;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    text-transform: none;
    font-family: Arial, sans-serif;
    @include make-f-body--regular;
    color: $color-white;
  }
}

@media (max-width: $screen-small) {
  .tooltip {
    position: relative;

    .tooltiptext {
      left: -50%;
      min-width: 60vw;

      &:after {
        margin-left: 1.125rem;
      }

    }
  }
}