//====================================================================================================
// Table
//====================================================================================================
table {
  border-collapse: collapse;
  width: 100%;
}

.pure-table {
  thead {
    tr {
      background-color: $color-black;
      color: $color-white;
    }
  }

  tr {
    background-color: $color-white;

    &:nth-child(2n) {
      background-color: $color-grey--light;
    }
  }

  th {
    padding: 0.5rem;
    text-align: left;
    @include make-f-body--small();
  }

  td {
    padding: 0.5rem 1rem;
  }
}

.table {
  display: block;
  width: 100%;

  &--white {
    background: #fff;
  }

  &--grey {
    background: #f5f5f5;
  }

  tr.no-border {
    td, th {
      border: none;
    }
  }

  th.no-border, tr.no-border, td.no-border {
    border: none;
  }
}

.table__header {
  display: table;
  @include make-font($ff-label);
  table-layout: fixed;
  width: 100%;
}

.table__row {
  display: table;
  table-layout: fixed;
  width: 100%;

  &:not(:first-of-type) {
    border-top: 1px solid $color-black-50;
  }

  &:last-of-type {
    border-bottom: 1px solid $color-black-50;
  }
}

.table__cell {
  display: table-cell;
  float: none;
  padding: 1rem;
  vertical-align: top;

  &:not(:first-of-type) {
    border-left: 1px solid $color-black-50;
  }

  &--no-left-border, &--no-left-border:not(:first-of-type) {
    border-left: none;
  }

  &--no-padding {
    padding: 0;
  }

  // Background Colours
  &--grey {
    background-color: $color-grey--light;
    color: $color-grey--darker;
  }

  &--theme-color {
    background-color: $color-black-100;
    color: $color-black-600;
  }

  &--theme-bg-color {
    background-color: $color-theme;
    color: $color-white;
  }

  // Content Alignment
  &--h-center {
    text-align: center;
  }

  &--v-center {
    vertical-align: middle;
  }

  &--v-top {
    vertical-align: top;
  }

  &--small {
    width: 20%;
  }

  &--large {
    width: 100%;
  }
}

.table--overview {
  .table__row {
    border: none;
  }

  .table__cell {
    border: none;
  }
}

.table--borderless {
  .table__row {
    border: none;

    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }

  .table__cell {
    border: none;
  }

  .table__cell > svg {
    margin-right: 1rem;
  }
}

.table--borderless--no-padding {
  .table__row {
    border: none;

    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }

  .table__cell {
    border: none;
    padding: 0;

    &.empty {
      color: $color-grey--dark;
    }
  }
}

.table--h-lines {
  .table__cell {
    border: none;
    border-bottom: 1px solid $color-grey--dark;
  }

  td.table__cell {
    border-bottom: 1px solid $color-radio-active;
  }
}

.table--borders {
  .table__cell {
    border: 1px solid $color-radio-active;
  }

  &-darker .table__cell {
    border: 1px solid $color-grey--dark;
  }
}

.table-display {
  display: table;
}

@media (max-width: $screen-medium) {
  .table-responsive {
    width: calc(100vw);
    padding-left: 1.5rem;
    margin-left: -1.5rem;
  }

  .table-col-sticky {
    position: sticky;
    background: $color-white;
    text-align: center;

    &--right {
      right: 0;
      box-shadow: -1px 0px 0px 0px $color-grey;

      &:nth-child(2n) {
        background-color: inherit;
      }
    }
  }

  .table--fixed {
    .table__header {
      margin: 0;
    }
  }

  // Style Variation when in mobile view
  .table__header {
    margin-bottom: 1rem;

    .table__cell {
      border: 1px solid transparent;
    }

    .table__cell.touch {
      border-color: $color-blue;
    }

    .table__cell.active {
      border-color: $color-blue;
    }
  }

  .table__row {
    &:last-of-type {
      border-bottom: 1px solid $color-black-50;
    }

    .table__cell {
      border-left: none;
      width: 100%;

      &--mobile {
        display: block;
      }
    }

    .table__cell--small {
      width: 20%;
    }
  }

  .table--borderless--no-padding .table__row--mobile:not(:first-of-type) {
    margin-top: 1rem;
  }

  // Styles to control the show hiding of columns
  .table:not(.table--feature):not(.table--borderless--no-padding):not(.table--fixed) {
    .table__row {
      .table__cell.active {
        display: block;
      }

      .table__cell:not(.active) {
        display: none;
      }
    }
  }

  .table--feature {
    .table__header > *:first-child {
      display: none;
    }

    .table__row > *:first-child {
      display: block;
    }

    .table__row > *:not(:first-child).active {
      display: block;
      padding-top: 0;
    }

    .table__row > *:not(:first-child):not(.active) {
      display: none;
    }
  }

  .table--borderless, .table--borderless--no-padding {
    .table__row {
      border: none;
    }
  }
}

@media (max-width: $screen-medium) {
  .grid > [class*="col--"].table__medium {

    &:not(:first-child) {
      margin-top: 3.5rem;
    }
  }
}
