.ribbon {
  @include make-font($ff-heading);
  background-color: $color-ribbon;
  border-bottom: 1px solid darken($color-ribbon, 7.5%);
  color: $color-ribbon-text;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.3s;
  letter-spacing: 1px;
  line-height: 1.75;

  &--secondary {
    background-color: $color-ribbon-secondary;
    border-top: 1px solid darken($color-ribbon-secondary, 7.5%);
    border-bottom: 1px solid darken($color-ribbon-secondary, 7.5%);
    color: $color-ribbon-secondary-text;

    .ribbon__numbers {
      color: $color-ribbon-secondary;
      box-shadow: 1px 1px 1px darken($color-ribbon-secondary, 15%);
    }
  }

  & .link:hover {
    text-decoration: none !important;
  }

  & .icon-arrow {
    height: 17px;
    width: 17px;
    transition: fill 0.3s;
    vertical-align: middle;
    margin-left: -2px;
    margin-top: 1px;
  }
}

.ribbon__container {
  padding: 0.7rem 2rem;
}

.ribbon__countdown-container {
  display: inline;
}

.ribbon__title {
  position: relative;
  vertical-align: middle;
  margin-right: 0.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: color 0.3s;
}

.ribbon__sub-title {
  text-transform: none;
}

.ribbon__countdown {
  white-space: nowrap;
  line-height: 1.75rem;
}

.ribbon__numbers {
  @include make-f-heading(5);
  letter-spacing: 0;
  background: white;
  color: $color-ribbon;
  border-radius: 4px;
  padding: 4px;
  margin-right: 8px;
  white-space: nowrap;
  box-shadow: 1px 1px 1px darken($color-ribbon, 15%);
  transition: color 0.3s, background 0.3s, box-shadow 0.3s;
  line-height: 1.75rem;
  vertical-align: middle;

  &:before {
    content: '';
    white-space: pre;
    display: inline-block;
  }
}

.ribbon__button {
  margin: -0.25rem 0.25rem;
}

a.ribbon__link:hover:not(.disabled):not(.footer__link) {
  text-decoration: none;
}

@media (max-width: $screen-medium) {
  .ribbon__container {
    padding: 0.8rem 1.5rem 1.05rem 1.5rem;
    margin-top: 0.5rem;
  }

  .ribbon__title {
    font-size: 0.885rem;
    margin-right: 0;
    display: block;
  }
}

@media (max-width: $screen-small) {
  .ribbon__countdown-container {
    display: block;
  }
}