.container--404 {
  padding: 10rem 2rem 2rem;
  color: $color-white;
}

@media (max-width: $screen-medium) {
  .container--404 {
    padding-top: 6rem;
  }
}

@media (max-width: $screen-small) {
  .container--404 {
    padding-top: 4rem;
  }
}

.header--404 {
  font-size: 5rem;
}

.text--404 {
  font-size: $fs-l;
}