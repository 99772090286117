
.spinner {
  -webkit-animation: arrowSpinnerRotate 2s infinite linear;
  animation: arrowSpinnerRotate 2s infinite linear;
  fill: #4d6778;
  width: 1.5rem;
  height: 1.5rem;

  &--grey {
    fill: $color-grey--darker;
  }
}

.spinner--full-page {
  @include make-flexbox();
  @include make-align-items(center);
  @include make-justify-content(center);
  @include make-flex-direction(column);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 9999;
  background-color: rgba($color-white, 0.85);
  overflow: hidden;

  .spinner {
    width: 3.75rem;
    height: 3.75rem;
    fill: $color-black;
    vertical-align: middle;
  }
}

.spinner--center {
  position: absolute;
  vertical-align: middle;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner--large {
  width: 2.5rem;
  height: 2.5rem;
  margin: 2.5rem auto;
  fill: $color-grey--darker;
}

@-webkit-keyframes arrowSpinnerRotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes arrowSpinnerRotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}