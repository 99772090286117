.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;

  &::after {
    content: '';
    opacity: 0.6;
    background: $color-black-500;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

.modal-backdrop.active.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.modal {
  background-color: $color-white;
  width: 608px;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 21;
  top: 7vh;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  transform: translate3d(-1rem, 0, 0);

  .modal__header {
    padding: 1.5rem 2.5rem;
    background-color: $color-theme;
    position: relative;
    z-index: 6;
  }

  .modal__close {
    bottom: 0;
    height: 19px;
    position: absolute;
    right: 0.5rem;
    top: 12px;
    padding: 1rem 1rem 2rem 1rem;

    .icon-cross {
      cursor: pointer;
    }
  }

  .modal__ribbon {
    background-color: $color-grey--light;
    border-bottom: 1px solid $color-black-50;
    text-align: center;

    * {
      text-decoration: none !important;
    }

    .container {
      padding: 1rem 0 !important;
    }

    .container .ribbon__link {
      color: $color-theme-300;
    }

    &:hover {
      background-color: $color-black-50 !important;

      .container .ribbon__link {
        color: black;
      }

    }
  }

  &__video {
    max-height: calc(100vh - 32vh);
    min-height: 30rem;
    max-width: 840px;
    width: 100%;
    position: fixed;
    z-index: 21;
    top: 30vh;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__video + svg.spinner {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 48px;
    height: 48px;
    fill: $color-theme;
    z-index: 1;
  }

  &--full-page {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    top: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.modal__content {
  padding: 1rem;
  position: relative;
  overflow-x: hidden;

  .image {
    margin: 0;
    position: static;
    height: 100%;
    width: calc(100% + 6.1rem);
    margin: -3.5rem -3rem;
    padding-bottom: 3.5rem;

    img {
      width: 100%;
      left: 0;
      top: 0;
    }

    //For mobile version
    & + .image {
      display: none;
    }
  }
}

.modal > .container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 32vh);

  &--full-page {
    max-height: inherit;
    padding-bottom: 3rem;
    overflow-y: inherit;
    -webkit-overflow-scrolling: inherit;
  }
}

.content-block__section--modal {
  padding: 0 1rem;
}

.modal__title {
  @include make-f-heading(3);
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
}

.modal__footer {
  padding: 2.5rem 0 1rem;
}

// Active state
.modal-backdrop.active {
  pointer-events: auto;
  visibility: visible;
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  z-index: 80;
}

.modal.active {
  transform: translate3d(0, 0, 0);
  animation-name: slideInUpFade;
}

.modal.active.leave {
  transform: translate3d(0, 0, 0);
  animation-name: slideOutDownFade;
}

@media (max-width: $screen-medium) {
  .modal {
    width: 100%;
    max-height: 100vh;
    top: 0;
    border-radius: 0;

    &__video {
      position: fixed;
      max-height: inherit;
      min-height: inherit;
      max-width: inherit;
      top: 25%;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 50%;
      border: none;
      margin: 0;
      padding: 0
    }

    .container {
      max-height: 100vh;

      &--full-page {
        max-height: initial;
        padding-bottom: 5rem;
      }
    }

    // Remove additional height created by the modal header
    .modal__content {
      height: calc(100vh - 75px);
    }

    //Padding helps fix the buttons at the bottom of modals on mobile devices
    .form__content {
      padding-bottom: 3rem;
    }
  }

  .modal__content {
    padding: 0.5rem;

    //For desktop version
    .image {
      display: none;

      & + .image {
        display: block;
        width: calc(100% + 3rem);
        margin: -1.25rem -1.5rem;
        padding-bottom: 1.25rem;
      }
    }
  }

  .modal__footer {
    padding: 2.5rem 0 12rem;
  }

  .modal-backdrop {
    display: none;
  }
}

@media (max-width: $screen-small) {
  .modal__ribbon {
    margin-bottom: 0.5rem !important;
  }

  .modal__content {
    padding: 0;
  }

  .modal .form__loading {
    position: fixed;
  }
}