.header {
  background-image: linear-gradient(90deg, $header-gradient-color-left, $header-gradient-color-right);
}

.header--VDP {
  background-image: linear-gradient(90deg, $header-VDP-gradient-color-left, $header-VDP-gradient-color-right);

  & > .container > .header__content {
    padding: 2rem 0;
  }
}

.header__container {
  position: relative;
}

.header__content {
  padding: 3rem 0;
  position: relative;
  z-index: 1;
}

.header__image {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-color: lighten($color-theme, 10%);
}

.header-sub {
  text-transform: none;
  letter-spacing: normal;
}

.header--category {
  margin-bottom: 0.25rem;
}

.header--sub-category {
  margin-top: 0.25rem;
}

.header__title {
  @include make-f-heading(1);
  color: $header-title-color;
  text-transform: uppercase;
}

.header__sub-title {
  @include make-f-heading(6);
  color: $header-sub-title-color;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}

.header--tint {
  .header__image:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(90deg, $color-theme, lighten($color-theme, 5%));
    opacity: 0.75;
  }

  .header__title,
  .header__sub-title {
    color: $fc-white;
  }
}

// For a large slanted header
.header__cover {
  background: none;
  position: relative;

  .header__container {
    background-image: linear-gradient(0deg, $color-theme, darken($color-theme, 5%));

    &--grey {
      background-image: linear-gradient(0deg, $color-grey--light, darken($color-grey--light, 5%));
    }

    &--certified {
      background: $color-certified-blue;
    }

    .container {
      z-index: 2;
      position: relative;
    }

    &--background-image {
      padding-bottom: 7.4vw;

      &:after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-image: linear-gradient(0deg, $color-theme, darken($color-theme, 5%));
        opacity: 0.75;
      }
    }

  }

  .header__content {
    padding: 6.4vw 0 2rem;
  }

  .header__content--modal {
    padding: 6.4vw 0 0;
  }

  .header__title {
    @include make-f-heading(1);
    color: $color-white;
    text-transform: uppercase;
  }

  .header__sub-title {
    color: $color-white;
  }

  .sub-title {
    @include make-f-body--large($color-white);
    margin-top: 1rem;

    &--long {
      max-width: 100%;
    }

    &--bold {
      font-weight: bold;
    }
  }

  .header__slant {
    height: 7.4vw;
    width: 100%;
    margin-top: -1px;

    & polygon {
      fill: $color-theme;
      border: none;
      outline: none;
    }

    &--grey polygon {
      fill: $color-grey--light;
    }

    &--certified polygon {
      fill: $color-certified-blue;
    }

    &--reverse {
      transform: scale(-1, -1);
      position: absolute;
      bottom: -1px;

      polygon {
        fill: $color-white;
      }
    }
  }

  .header__sheen {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      display: block;
      height: 100%;
      background: linear-gradient(90deg, rgba($color-theme, 0), lighten($color-theme, 2.5%));
      position: absolute;
      width: calc(40% + 10rem);
      transform: rotate(-5deg) skew(-5deg);
      right: -3%;
      top: calc(-7.4vw - 3rem);
      z-index: 1;
    }

    &--grey::after {
      background-image: radial-gradient(farthest-corner at 100% 0, lighten($color-grey--light, 5%) 0%, rgba($color-grey--light, 0) 75%, transparent 100%);
    }

    &--certified::after {
      background: $color-certified-blue;
    }

    &--lighter{
      opacity: 0.35;
    }

  }

  &--small {
    .header__container {
      overflow: hidden;
    }

    .header__content {
      padding: calc(1vw + 5rem) 0 0 0;
    }

    .header__slant {
      height: calc(1vw + 5rem);
    }

    .header__sheen {
      background-image: radial-gradient(farthest-corner at 100% 0, lighten($color-theme, 5%) 0%, rgba($color-theme, 0) 75%, transparent 100%);
      background-size: contain;
      right: 0;
      top: 0;
      z-index: 1;

      &--grey {
        background-image: radial-gradient(farthest-corner at 100% 0, lighten($color-grey--light, 5%) 0%, rgba($color-grey--light, 0) 75%, transparent 100%);
      }

      &::after {
        content: none;
      }
    }
  }

  // For a plain grey slanted header
  &--grey {
    .header__container {
      background-image: linear-gradient(0deg, $color-grey--light, $color-white);
    }

    .header__slant {
      & polygon {
        fill: $color-grey--light;
      }
    }
  }
}

.subheader {
  border-radius: 2px 2px 0 0;
  padding: 1rem 0;

  &:after {
    @include make-clearfix;
  }

  &.u-only-mobile {
    background-color: $form-header-color;
    height: 56px;
    border-radius: 0;
    position: fixed;
    z-index: 3;
    bottom: 70px;
    width: 100%;
    transform: translate3d(0, 0, 0);
    transition: 0.5s;

    & > .grid > div > * {
      vertical-align: bottom;
      line-height: 24px;
    }

    &.slideOutDown {
      transform: translate3d(0, 71px, 0);
    }

    &.slideInUp {
      transform: translate3d(0, 0, 0);
    }
  }

  &--theme-color {
    background-color: $sticky-footer-background-color;

    * {
      color: $color-white;
    }
  }
}

.header__overlay {
  position: relative;

  &:after {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, $color-black 100%);
    pointer-events: none;
    z-index: 2;
  }

  &.no-bg {
    &:after {
      background: transparent;
    }
  }

  &--full {
    &:after {
      background-color: rgba($color-black, 0.5);
    }
  }

  .title {
    z-index: 3;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .play__button {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.header__dash {
  &::after {
    content: ' ';
    display: block;
    width: 40px;
    border: 2px solid $color-theme;
    border-radius: 100px;
    margin: 0.5rem 0 0.5rem;
  }

  &-center::after {
    margin: 0.5rem auto 0.5rem;
  }

  &--large {
    &::after {
      width: 80px;
    }
  }

  &--secondary-colour {
    &::after {
      border: 2px solid $color-button;
    }
  }

  &--white {
    &::after {
      border: 2px solid $color-white;
    }
  }
}

.header--white {
  color: $color-white;
}

.header--large {
  font-size: $fs-l-5;
}

.header--grey {
  color: $fc-grey;
}

.header--blue {
  color: $color-blue;
}

.header--theme-color {
  color: $header-color;
}

.header--highlight-color {
  color: $color-theme;
}

.header-title-color {
  color: $header-title-color;
}

.header__body {
  &--padding {
    &--no-action {
      padding-bottom: 4.25rem;
    }
  }
}

//media queries
@media (max-width: $screen-large) {
  .header__cover {
    &--small {
      .header__content {
        padding: calc(2vw + 4rem) 0 0 0;
      }

      .header__slant {
        height: calc(2vw + 4rem);
      }
    }
  }
}

@media (max-width: $screen-medium) {
  .header__cover {
    .header__title {
      @include make-f-heading(2);
    }

    .header__content {
      padding: 6rem 0 5rem;
    }

    &--small {
      .header__content {
        padding: calc(2vw + 4rem) 0 0 0;
      }

      .header__slant {
        height: calc(2vw + 4rem);
      }
    }
  }

  .header__overlay {
    .title {
      z-index: 3;
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

}

//media queries
@media (max-width: $screen-small) {
  .header > .container > .grid {
    padding: 0 0.5rem;
  }

  .header__title {
    @include make-f-heading(2);
  }

  .header__cover {
    &--small {
      .header__content {
        padding: 15vw 0 0 0;
      }

      .header__slant {
        height: 15vw;
      }
    }
  }
}